import React from "react";
import { FaSpinner } from "react-icons/fa";

const MobileLoadingMessage = ({
  noAnimation = false,
  message = "Loading...",
}) => {
  return (
    <div
      style={{
        marginTop: "50%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
      }}
    >
      {!noAnimation && (
        <div
          style={{
            opacity: 0.5,
            width: "max-content",
            animation: "spin 2s ease infinite",
          }}
        >
          <FaSpinner size={60} />
        </div>
      )}
      {message}
    </div>
  );
};

export default MobileLoadingMessage;
