import CurrentIntegrations from "components/Connections/CurrentIntegrations";
import IntegrationUsage from "components/Connections/IntegrationUsage";
import NewConnection from "components/Connections/NewConnection";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { active } from "d3";
import * as api from "apis/FusionAPI";
// import { perms.testPermission } from "data/routeData";
import { usePerms } from "hooks/usePerms";
import Popup from "reactjs-popup";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext, DeviceContext } from "App";
import NewDevice from "components/Connections/NewDevice";
import NewGroup from "components/Connections/NewGroup";
import { ConnectionsContext } from "./Connections";

export default function ConnectedSystems() {
  const connectionsContext = useContext(ConnectionsContext);
  const deviceContext = useContext(DeviceContext);

  const [showNewConnection, setShowNewConnection] = useState(false);
  const [showNewDevice, setShowNewDevice] = useState(false);
  const [showNewGroup, setShowNewGroup] = useState(false);
  const [initialData, setInitialData] = useState();
  const perms = usePerms();

  const addIntegration = (integration) => {
    (async () => {
      const res = await api.fusionAddIntegration(integration);
    })()
      .then()
      .catch((err) => {});
  };

  const canMakeNewSystems = () => {
    return (
      deviceContext?.integrations?.length <
      deviceContext?.licenseCounts?.advanced_system
    );
  };

  const canMakeNewDevices = () => {
    return (
      deviceContext?.devices?.length <
      deviceContext?.licenseCounts?.connected_devices
    );
  };

  const theme = useContext(ColorContext);
  if (deviceContext.fetching) {
    return <WidgetLoadingAnimation />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {perms.testPermission([8, 9, 10, 11, 12, 13], ["ViewConnections"]) && (
        <CurrentIntegrations
          data={deviceContext.integrations}
          devices={deviceContext.devices}
          setInitialData={setInitialData}
          setShowNewConnection={setShowNewConnection}
          setShowNewDevice={setShowNewDevice}
        />
      )}
      <div
        style={{
          display: "flex",
          gap: 20,
        }}
      >
        {perms.testPermission(
          [8, 9, 10, 11, 12, 13],
          ["Create-EditConnections"]
        ) && (
          <div
            style={{
              cursor: "pointer",
              backgroundColor: !canMakeNewSystems()
                ? "grey"
                : theme.primaryHighlight,
              fontWeight: "bold",
              width: "fit-content",
              padding: "20px 40px",
              borderRadius: 10,
              display: "flex",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              if (!canMakeNewSystems()) {
                alert(
                  "Notice: License Limit Reached. \nYou have reached the maximum number of licenses available for adding new systems or devices. To expand your capacity, please contact our sales team at sales@rescalert.com for assistance."
                );
                return;
              }
              setShowNewConnection(true);

              setInitialData(null);
            }}
          >
            New System
          </div>
        )}
        {
          <div
            style={{
              cursor: "pointer",
              backgroundColor: !canMakeNewDevices()
                ? "grey"
                : theme.primaryHighlight,
              fontWeight: "bold",
              width: "fit-content",
              padding: "20px 40px",
              borderRadius: 10,
              display: "flex",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              if (!canMakeNewDevices()) {
                alert(
                  "Notice: License Limit Reached. \nYou have reached the maximum number of licenses available for adding new systems or devices. To expand your capacity, please contact our sales team at sales@rescalert.com for assistance."
                );
                return;
              }
              setShowNewDevice(true);

              setInitialData(null);
            }}
          >
            New Device
          </div>
        }
        {
          <div
            style={{
              cursor: "pointer",
              backgroundColor: theme.primaryHighlight,
              fontWeight: "bold",
              width: "fit-content",
              padding: "20px 40px",
              borderRadius: 10,
              display: "flex",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              setShowNewGroup(true);

              setInitialData(null);
            }}
          >
            New Group
          </div>
        }
      </div>

      {showNewConnection &&
        perms.testPermission(
          [8, 9, 10, 11, 12, 13],
          ["Create-EditConnections"]
        ) && (
          <div
            style={{
              position: "absolute",
              top: 200,
              left: 350,
              width: "calc(100% - 590px)",
              height: "calc(100% - 400px)",
            }}
          >
            <NewConnection
              integrations={deviceContext.integrations}
              setIntegrations={deviceContext.setIntegrations}
              setShowNewConnection={setShowNewConnection}
              initialData={initialData}
              setInitialData={setInitialData}
            />
          </div>
        )}
      {showNewDevice && (
        <div
          style={{
            position: "absolute",
            top: 200,
            left: 350,
            width: "calc(100% - 590px)",
            height: "calc(100% - 400px)",
          }}
        >
          <NewDevice
            integrations={deviceContext.integrations}
            setDevices={deviceContext.setDevices}
            setShowNewDevice={setShowNewDevice}
            initialData={initialData}
            setInitialData={setInitialData}
            deviceGroups={deviceContext.deviceGroups}
            setDeviceGroups={deviceContext.setDeviceGroups}
          />
        </div>
      )}
      {showNewGroup && (
        <div
          style={{
            position: "absolute",
            top: 200,
            left: 350,
            width: "calc(100% - 590px)",
            height: "calc(100% - 400px)",
          }}
        >
          <NewGroup
            devices={deviceContext.devices}
            integrations={deviceContext.integrations}
            setDevices={deviceContext.setDevices}
            setShowNewGroup={setShowNewGroup}
            initialData={initialData}
            setInitialData={setInitialData}
            deviceGroups={deviceContext.deviceGroups}
            setDeviceGroups={deviceContext.setDeviceGroups}
          />
        </div>
      )}
    </div>
  );
}
