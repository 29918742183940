import AlarmList from "components/AlarmList";
import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { TimeSinceDateTimeString } from "utils/stringUtils";
import { usePerms } from "hooks/usePerms";
import { useNavigate } from "react-router-dom";
import { useAPIData } from "hooks/useAPIData";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext } from "App";

export default function AlarmHistory() {
  const perms = usePerms();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [alarmData, setAlarmData] = useState(null);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["AlarmHistory"]))) {
        navigate("/404");
        return;
      }
      // setFetching(true);
      api.getPendingAlarmsPaginated(limit, offset).then((res) => {
        const newData = res.data.data.map((alarm) => {
          const time = TimeSinceDateTimeString(new Date(alarm.activity));
          return { ...alarm, status: time, info: alarm.info };
        });

        setTotalCount(res.data.totalCount);
        setAlarmData(newData);
        setFetching(false);
      });
    })();
  }, [offset, limit]);

  const theme = useContext(ColorContext);

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  if (alarmData)
    return (
      <div
        style={{
          userSelect: "none",
          height: "100%",
        }}
      >
        {/* header + search bar */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Pending Alarms</div>
          <div>
            {/* search bar */}
            <input
              style={{
                width: "100%",
                height: 30,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 5,
                marginBottom: 5,
                padding: 2,
              }}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
            />

            {/* advanced search */}
            {/* <div style={{ cursor: "pointer" }} onClick={() => setModal(true)}>
              Advanced Search
            </div> */}
          </div>
        </div>

        {/* content header */}
        <AlarmList
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
          totalCount={totalCount}
          alarmData={alarmData.filter((filter) => {
            if (search === "") return true;
            else {
              return JSON.stringify(filter)
                .toString()
                .toLowerCase()
                .replace(" ", "")
                .includes(search.toLowerCase().replace(" ", ""));
            }
          })}
        />
      </div>
    );
}

//  advanced search modal code
// {/* modal */}
// <Popup
// modal
// closeOnDocumentClick
// closeOnEscape
// open={modal}
// onClose={() => setModal(false)}
// >
// <div
//   style={{
//     display: "flex",
//     width: 500,
//     height: 500,
//     backgroundColor: theme.base,
//     padding: 15,
//     color: theme.fontColor,
//   }}
// >
//   {/* title */}
//   <div>Advanced Search</div>

//   {/* information */}
//   <div style={{ display: "flex", flexDirection: "row" }}>
//     {/* date */}
//     <div style={{ flex: 1 }}>Date</div>
//     <div style={{ flex: 1 }}>
//       {/* time */}
//       <div>Time</div>

//       {/* location */}
//       <div>Location</div>

//       {/* source */}
//       <div>Source</div>
//     </div>
//   </div>

//   {/* device */}
//   <div>Device</div>
// </div>
// </Popup>
