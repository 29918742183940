import { DeviceContext } from "App";
import React, { useContext, useEffect } from "react";
import MobileLoadingMessage from "./MobileLoadingMessage";
import { getDynamicDeviceIcon } from "utils/iconUtils";

const MobileIntegrations = () => {
  const deviceContext = useContext(DeviceContext);

  useEffect(() => {
    deviceContext.getDevices();
  }, []);

  if (deviceContext.devices.length === 0)
    return <MobileLoadingMessage message="Loading devices..." />;

  // Group devices by type
  const groupedDevices = deviceContext.devices.reduce((acc, device) => {
    const type = (device.device_type || "Unknown").split("_").join(" - ");
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(device);
    return acc;
  }, {});

  return (
    <div className="flex column">
      {Object.keys(groupedDevices).map((type) => (
        <div key={type}>
          <div className="listItem title border-bottom bg-primaryShadow">
            {type}
          </div>
          {groupedDevices[type].map((device, index) => (
            <div key={index} className="listItem border-bottom bg-base">
              <img src={getDynamicDeviceIcon(device)} alt="Device Icon" />
              <div>{device.alias}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MobileIntegrations;
