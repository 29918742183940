import React, { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import * as api from "apis/FusionAPI";
import { ColorContext } from "App";

// const DEMO = true
// const DEMO = false

export const LicenseInformation = ({
  setShowLicenseInformation,
  inline,
  activationCode = "0",
  callback,
}) => {
  const [showKey, setShowKey] = useState(false);

  const [licenseDetails, setLicenseDetails] = useState();

  const [userInputKey, setUserInputKey] = useState("");

  const [validationError, setValidationError] = useState(false);

  const [fetching, setFetching] = useState(true);

  const [userInputID, setUserInputID] = useState("");

  const [userLocation, setUserLocation] = useState({
    lat: null,
    lon: null,
  });

  const [orgId, setOrgID] = useState("");

  const [msClientId, setMsClientId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const theme = useContext(ColorContext);

  useEffect(() => {
    (async () => {
      const res = await api.fusionGetLicense(activationCode);
      if (res.data.subscriptionInfo) {
        if (callback) {
          callback();
        }
        setLicenseDetails(res.data.subscriptionInfo[0]);
      } else if (res.data.activation) {
        setOrgID(res.data.activation[0].gen_wp_id);
      }
      setFetching(false);
    })().catch((err) => {
      setFetching(false);
    });
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setUserLocation({
                lat: position.coords.latitude,
                lon: position.coords.longitude,
              });
            },
            (err) => {
              console.warn(`ERROR(${err.code}): ${err.message}`);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          );
        } else {
          alert(
            "Please enable location access and refresh this page, otherwise authorization will not be successful."
          );
        }
      });
    } else {
      alert("Geolocation unavailable in this browser");
    }
  }, []);

  const updateMSClientId = () => {
    if (msClientId === "") return;
    // api.setMSClientId(msClientId).then((response) => {
    //   if (response.status === 200) {
    //     setResponseMessage("Successfully set Microsoft Client ID!");
    //     setTimeout(() => {
    //       setResponseMessage("");
    //     }, 3000);
    //   } else setResponseMessage("Something went wrong.");
    // });
  };
  const verifyLicense = () => {
    (async () => {
      if (userInputKey.length !== 29) {
        return;
      }

      if (userLocation.lat && userLocation.lon) {
      } else {
        setValidationError({
          message:
            "Location services not enabled. Please enable location services and try again.",
        });
        return;
      }
      setFetching(true);
      const res = await api.fusionVerifyLicense(
        userInputKey,
        activationCode,
        userLocation.lat,
        userLocation.lon
      );
      if (res.status === 200) {
        setValidationError(false);
        setLicenseDetails(res.data.subscriptionInfo[0]);
        if (callback) {
          callback();
        }
      }
      setFetching(false);
    })().catch((err) => {
      if (err.response && err.response.status === 500) {
        setValidationError({
          message: "Activation failed",
        });
      } else {
        setValidationError({
          message: "Invalid License Key",
        });
      }
      setFetching(false);
    });
  };

  const stringToLicense = (str) => {
    //add hyphens every 5 chars
    let license = "";
    str = str.replace(/-/g, "");
    for (let i = 0; i < str.length; i++) {
      if (i % 5 === 0 && i !== 0) license += "-";
      license += str[i];
    }
    return license;
  };

  return (
    <div
      style={{
        position: "absolute",
        background: theme.base,
        padding: 20,
        borderRadius: 10,
        border: inline ? "" : "1px solid #ccc",
      }}
    >
      {
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 24 }}>
            License Information
          </div>
          {!inline && (
            <div
              style={{ cursor: "pointer", fontWeight: "bold", fontSize: 24 }}
              onClick={() => setShowLicenseInformation(false)}
            >
              X
            </div>
          )}
        </div>
      }
      {fetching && (
        <div style={{ marginTop: 20 }}>Fetching license details...</div>
      )}
      {!fetching && !licenseDetails && (
        <div style={{ paddingTop: 20 }}>
          <div style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", paddingTop: 10 }}
            >
              <div style={{ marginRight: 20 }}>Organization ID: {orgId}</div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", paddingTop: 10 }}
          >
            <div style={{ marginRight: 20 }}>License Key:</div>
            <input
              style={{
                color: "#FFF",
                fontFamily: "monospace",
                fontSize: 16,
                background: theme.primaryHighlight,
                borderRadius: 40,
                padding: 5,
                width: 300,
              }}
              onChange={(event) => {
                setUserInputKey(event.target.value);
              }}
              maxLength={29}
              value={stringToLicense(userInputKey)}
            ></input>
            <div
              style={{
                cursor: userInputKey.length === 29 ? "pointer" : "auto",
                backgroundColor:
                  userInputKey.length === 29 ? theme.primaryHighlight : "#777",
                fontWeight: "bold",
                width: "fit-content",
                padding: "5px 10px",
                borderRadius: 10,
                marginLeft: 20,
              }}
              onClick={() => {
                verifyLicense();
              }}
            >
              Verify
            </div>
            {validationError && (
              <div style={{ marginLeft: 20, color: "red" }}>
                {validationError.message}
              </div>
            )}
          </div>
        </div>
      )}
      {!fetching && licenseDetails && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            marginTop: 20,
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>Organization ID:</div>
            <div style={{ color: "#ccc" }}>{licenseDetails.gen_wp_id}</div>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>License Key:</div>
            <div
              style={{ color: "#ccc", fontFamily: "monospace", fontSize: 16 }}
            >
              {showKey
                ? licenseDetails.license_key
                : "*****-*****-*****-*****-*****"}
            </div>
            <div onClick={() => setShowKey(!showKey)}>
              {showKey ? (
                <FaEyeSlash style={{ marginLeft: 10, cursor: "pointer" }} />
              ) : (
                <FaEye style={{ marginLeft: 10, cursor: "pointer" }} />
              )}
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>License Expiration:</div>
            <div style={{ color: "#ccc" }}>
              {
                licenseDetails.license_expiration
                // || licenseDetails.created_date
              }
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 50 }}
          >
            <div style={{ marginRight: 20, marginBottom: 10 }}>
              License Details:
            </div>
            <div
              style={{
                background: theme.primary,
                width: "100%",
                border: "1px solid white",
                borderBottom: "none",
                // height: 375,
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                Base Fusion: {licenseDetails.fusion_activations} /{" "}
                {licenseDetails.fusion_max_activations} licenses used
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                Custom Weather:{" "}
                {licenseDetails.custom_weather ? "Enabled" : "Disabled"}
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                Basic Systems: {licenseDetails.basic_system} available
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                Advanced Systems: {licenseDetails.advanced_system} available
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                Connected Devices: {licenseDetails.connected_devices} available
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                Connected Cameras: {licenseDetails.connected_cameras} available
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                SUSP: {licenseDetails.susp ? "Enabled" : "Disabled"}
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid white",
                  padding: 10,
                }}
              >
                Critical Mention: {licenseDetails.critical_mention} available
              </div>
            </div>
          </div>

          {/* Setting MS Client ID */}
          {/* <div
            style={{
              marginTop: 15,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: theme.primary,
                color: theme.fontColor,
                padding: 5,
                borderRadius: 5,
              }}
            >
              <input
                placeholder="Microsoft Client ID"
                style={{
                  width: "100%",
                }}
                onChange={(v) => setMsClientId(v.target.value)}
                value={msClientId}
              />
            </div>
            <div
              style={{
                width: "100%",
                justifyItems: "flex-end",
                marginTop: 5,
              }}
            >
              <button
                onClick={updateMSClientId}
                style={{
                  backgroundColor: theme.primary,
                  color: theme.fontColor,
                  borderRadius: 5,
                  width: "25%",
                  float: "right",
                  padding: 5,
                }}
              >
                Save
              </button>
            </div>
            {responseMessage && (
              <div
                style={{
                  color:
                    responseMessage === "Successfully set Microsoft Client ID!"
                      ? "green"
                      : "red",
                }}
              >
                {responseMessage}
              </div>
            )}
          </div> */}
        </div>
      )}
    </div>
  );
};
