import CurrentIntegrations from "components/Connections/CurrentIntegrations";
import IntegrationUsage from "components/Connections/IntegrationUsage";
import NewConnection from "components/Connections/NewConnection";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LicenseInformation } from "./LicenseInformation";
import { active } from "d3";
import * as api from "apis/FusionAPI";
// import { perms.testPermission } from "data/routeData";
import { usePerms } from "hooks/usePerms";
import Popup from "reactjs-popup";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext, DeviceContext } from "App";
import NewDevice from "components/Connections/NewDevice";
import NewGroup from "components/Connections/NewGroup";
import AWARE_ICON from "assets/icons/AWAREICON.png";
import AWARE_NODE from "assets/aware.png";
import RESCALERT_DEVICE from "assets/aware.png";
import { IntegrationItem } from "./IntegrationItem";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  deviceImageList,
  getDynamicDeviceIcon,
  group_icon,
} from "utils/iconUtils";
import { displayDate } from "utils/dateUtils";
import { FaEllipsis } from "react-icons/fa6";
import { ConnectionsContext } from "./Connections";
import NodeStatus from "components/NodeStatus";

export default function DeviceGroups() {
  const connectionsContext = useContext(ConnectionsContext);
  const deviceContext = useContext(DeviceContext);

  const [showNewConnection, setShowNewConnection] = useState(false);
  const [showNewDevice, setShowNewDevice] = useState(false);
  const [showNewGroup, setShowNewGroup] = useState(false);
  const [showLicenseInformation, setShowLicenseInformation] = useState(false);
  const [licenseInfo, setLicenseInfo] = useState({});
  const [initialData, setInitialData] = useState();

  const perms = usePerms();

  const deviceImages = deviceImageList;

  const theme = useContext(ColorContext);
  if (deviceContext.fetching) {
    return <WidgetLoadingAnimation />;
  }

  const GroupItem = ({ group, index }) => {
    const [expanded, setExpanded] = useState(false);
    return (
      <div onClick={() => setExpanded(!expanded)}>
        <div
          key={index}
          style={{
            display: "grid",
            color: theme.fontColor,
            gridTemplateColumns: "1.5fr 1fr .7fr 1.3fr .7fr .2fr",

            borderBottom: "1px solid grey",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* TODO: replace with system icon */}
            {group_icon(group.iconId, 50)}
            <div style={{ marginLeft: 10 }}>{group.name}</div>
          </div>
          <div>{JSON.parse(group.devices).length || "No Devices"}</div>
          <div></div>
          <div>{displayDate(group.date_created)}</div>
          <div>
            <div
              style={{
                cursor: "pointer",
                background: theme.primary,
                padding: 5,
                borderRadius: 20,
                textAlign: "center",
                width: 20,
                height: 20,
                alignContent: "center",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setInitialData(group);
                setShowNewGroup(true);
              }}
            >
              <FaEllipsis />
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {JSON.parse(group.devices).length > 0 ? (
              expanded ? (
                <FaChevronUp />
              ) : (
                <FaChevronDown />
              )
            ) : (
              ""
            )}
          </div>
        </div>
        {expanded && (
          <div
            onClick={() => {
              setExpanded(false);
            }}
          >
            {deviceContext.devices
              .filter((device) => {
                return JSON.parse(group.devices).includes(device.id);
              })
              .map((device) => {
                const device_type = device.device_type.split("_");

                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1.5fr 1fr .7fr 1.3fr .7fr .2fr",
                      backgroundColor: expanded ? "#2c363a" : "",
                      borderBottom: "1px solid grey",
                      padding: 10,
                      gap: 10,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div
                      style={{
                        display: "flex",

                        marginLeft: 5,
                        alignItems: "center",
                      }}
                    >
                      {/* TODO: replace with system icon */}
                      {deviceImages[device.device_type] ? (
                        <img
                          src={getDynamicDeviceIcon(device, "img")}
                          alt=""
                          style={{
                            height: 50,
                            width: 50,
                            marginRight: 10,
                            marginLeft: 10,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            background: theme.primary,
                            height: 35,
                            width: 35,
                            borderRadius: 10,
                            marginRight: 10,
                            marginLeft: 10,
                          }}
                        ></div>
                      )}

                      {device.alias}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {device_type.map((type, typeIndex) => {
                        if (typeIndex === 0) return <div>{type}</div>;
                        else
                          return (
                            <div style={{ fontSize: "0.8rem" }}>{type}</div>
                          );
                      })}
                    </div>
                    <div>
                      <NodeStatus device={device} />
                    </div>
                    <div>{displayDate(device.date_created)}</div>
                    <div>
                      <div
                        style={{
                          cursor: "pointer",
                          background: theme.primary,
                          padding: 5,
                          borderRadius: 20,
                          textAlign: "center",
                          width: 20,
                          height: 20,
                          alignContent: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setInitialData(device);
                          setShowNewDevice(true);
                        }}
                      >
                        <FaEllipsis />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {perms.testPermission([8, 9, 10, 11, 12, 13], ["ViewConnections"]) && (
        <div>
          <div style={{ fontWeight: "bold", fontSize: 24 }}>
            Connected Device Groups
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.5fr 1fr .7fr 1.3fr .7fr .2fr",
              marginTop: 20,
              color: theme.fontColor,
              fontWeight: "bold",
              borderBottom: "1px solid grey",
              padding: 10,
            }}
          >
            <div>Name</div>
            <div>Devices</div>
            <div>Status</div>
            <div>Date Created</div>
            <div></div>
          </div>
          {deviceContext.deviceGroups.map((group, index) => (
            <GroupItem key={index} group={group} />
          ))}
        </div>
      )}
      <div
        style={{
          display: "flex",
          gap: 20,
        }}
      >
        {
          <div
            style={{
              cursor: "pointer",
              backgroundColor: theme.primaryHighlight,
              fontWeight: "bold",
              width: "fit-content",
              padding: "20px 40px",
              borderRadius: 10,
              display: "flex",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              setShowNewGroup(true);

              setInitialData(null);
            }}
          >
            New Group
          </div>
        }
      </div>
      {showNewGroup && (
        <div
          style={{
            position: "absolute",
            top: 200,
            left: 350,
            width: "calc(100% - 590px)",
            height: "calc(100% - 400px)",
          }}
        >
          <NewGroup
            devices={deviceContext.devices}
            integrations={deviceContext.integrations}
            setDevices={deviceContext.setDevices}
            setShowNewGroup={setShowNewGroup}
            initialData={initialData}
            setInitialData={setInitialData}

            deviceGroups={deviceContext.deviceGroups}
            setDeviceGroups={deviceContext.setDeviceGroups}
          />
        </div>
      )}
      {showNewDevice && (
        <div
          style={{
            position: "absolute",
            top: 200,
            left: 350,
            width: "calc(100% - 590px)",
            height: "calc(100% - 400px)",
          }}
        >
          <NewDevice
            integrations={deviceContext.integrations}
            setDevices={deviceContext.setDevices}
            setShowNewDevice={setShowNewDevice}
            initialData={initialData}
            setInitialData={setInitialData}

            deviceGroups={deviceContext.deviceGroups}
          />
        </div>
      )}
    </div>
  );
}
