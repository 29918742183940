export function GetPermListForRole(deviceTypes) {
  if (!deviceTypes || !deviceTypes?.length) deviceTypes = [];

  let perms = [
    {
      display: "Manage Dashboard Configuration",
      name: "Settings: Manage Dashboard Configuration",
      desc: "Manage Dashboard Configuration master switch",
      linked: 5,
    },
    {
      name: "Home Location Editable",
      desc: "",
      linked: 0,
    },
    {
      name: "Create / Edit Places",
      desc: "",
      linked: 0,
    },
    {
      name: "Widgets Are Movable",
      desc: "*Does not apply to Admin-Locked widgets",
      linked: 0,
    },
    {
      name: "Widgets Are Editable",
      desc: "Adding / deleting widgets *Does not apply to Admin-Locked widgets",
      linked: 0,
    },
    {
      name: "Analytic Headers",
      desc: "",
      linked: 0,
    },

    //Overwatch
    {
      name: "Overwatch",
      desc: "Overwatch master switch",
      linked: 4,
    },
    {
      name: "Overwatch Enabled",
      desc: "",
      linked: 0,
      enableLinked: 3,
    },
    {
      name: "Pending Alarms",
      desc: "",
      linked: 0,
    },
    {
      name: "Alarm History",
      desc: "",
      linked: 0,
    },
    {
      name: "AI Analytics",
      desc: "",
      linked: 0,
    },

    //index = 7
    {
      name: "Responder",
      desc: "Responder Master Switch",
      linked: 9,
    },
    {
      name: "Responder Enabled",
      desc: "",
      linked: 0,
      enableLinked: 8,
    },
    {
      name: "Full Map",
      desc: "",
      linked: 0,
    },
    {
      name: "Incidents",
      desc: "",
      linked: 0,
    },
    {
      name: "Employees",
      desc: "",
      linked: 0,
    },
    {
      name: "Apparatus",
      desc: "",
      linked: 0,
    },
    {
      name: "Dispatcher Chat",
      desc: "",
      linked: 0,
    },
    {
      name: "View Instances",
      desc: "",
      linked: 0,
    },
    {
      name: "Edit Instances",
      desc: "",
      linked: 0,
    },
    {
      name: "Create Alert From Instances",
      desc: "",
      linked: 0,
    },

    //index = 12
    {
      name: "Weather",
      desc: "Weather master switch",
      linked: 8,
    },
    {
      name: "Weather Enabled",
      desc: "",
      linked: 0,
      enableLinked: 7,
    },
    {
      name: "Weather Alerts",
      desc: "",
      linked: 0,
    },
    {
      name: "Forecast Enabled",
      desc: "",
      linked: 0,
    },
    {
      name: "Maps Enabled",
      desc: "",
      linked: 0,
    },
    {
      name: "Storm Center Enabled",
      desc: "",
      linked: 0,
    },
    {
      name: "Marine Forecast Enabled",
      desc: "",
      linked: 0,
    },
    {
      name: "Special Reports Enabled",
      desc: "",
      linked: 0,
    },
    {
      name: "Charts Enabled",
      desc: "",
      linked: 0,
    },

    //index = 21
    {
      name: "Public Info",
      desc: "Public Info master switch",
      linked: 4,
    },
    {
      name: "Public Info Enabled",
      desc: "",
      linked: 0,
      enableLinked: 3,
    },
    {
      name: "View Alerts",
      desc: "",
      linked: 0,
    },
    {
      name: "Create / Edit Alerts",
      desc: "",
      linked: 0,
    },
    {
      name: "Delete Alerts",
      desc: "",
      linked: 0,
    },

    //index = 26
    {
      name: "System",
      desc: "System master switch",
      linked: 15 + 5 * deviceTypes.length,
    },
    {
      name: "System Enabled",
      desc: "",
      linked: 0,
      enableLinked: 14 + 5 * deviceTypes.length,
    },
  ];

  //dynamic section
  if (deviceTypes.length > 0) {
    perms = [
      ...perms,

      //index = 28
      {
        display: "Integrations",
        name: "Connections",
        desc: "Integrations master switch",
        linked: 5 * deviceTypes.length + 1,
      },
      {
        display: "Integrations Enabled",
        name: "Connections Enabled",
        desc: "",
        linked: 0,
        enableLinked: 5 * deviceTypes.length,
      },
    ];

    for (const deviceType of deviceTypes)
      perms = [
        ...perms,
        {
          display: `${deviceType.replace("_", " ")} Enabled`,
          name: `${deviceType} Enabled`,
          desc: `${deviceType.replace("_", " ")} Master Switch`,
          linked: 4,
          // enableLinked: 4,
        },
        {
          display: `View ${deviceType.replace("_", " ")} Integrations`,
          name: `View ${deviceType} Connections`,
          desc: "",
          linked: 0,
        },
        {
          display: `Create / Edit ${deviceType.replace("_", " ")} Integrations`,
          name: `Create / Edit ${deviceType} Connections`,
          desc: "",
          linked: 0,
        },
        {
          display: `Delete ${deviceType.replace("_", " ")} Integrations`,
          name: `Delete ${deviceType} Connections`,
          desc: "",
          linked: 0,
        },
        {
          display: `Import ${deviceType.replace("_", " ")} Integrations`,
          name: `Import ${deviceType} Connections`,
          desc: "",
          linked: 0,
        },
      ];
  }

  //last half
  perms = [
    ...perms, //index = 34
    {
      name: "Global Actions Master",
      desc: "Global Actions master switch",
      linked: 5,
    },
    {
      name: "Global Actions Enabled",
      desc: "",
      linked: 0,
      enableLinked: 4,
    },
    {
      name: "View Global Actions",
      desc: "",
      linked: 0,
    },
    {
      name: "Create / Edit Global Actions",
      desc: "",
      linked: 0,
    },
    {
      name: "Enable / Disable Global Actions",
      desc: "",
      linked: 0,
    },
    {
      name: "Delete Global Actions",
      desc: "",
      linked: 0,
    },

    //index = 39
    {
      name: "Manage Socials",
      desc: "Manage Socials master switch",
      linked: 5,
    },
    {
      name: "Manage Socials Enabled",
      desc: "",
      linked: 0,
      enableLinked: 4,
    },
    {
      name: "Create / Edit Connection To Social Pages",
      desc: "",
      linked: 0,
    },
    {
      name: "Remove Connection To Social Pages",
      desc: "",
      linked: 0,
    },
    {
      name: "Autopost To Facebook",
      desc: "",
      linked: 0,
    },
    {
      name: "Autopost To Twitter",
      desc: "",
      linked: 0,
    },

    //Settings
    {
      name: "Settings",
      desc: "Settings master switch",
      linked: 19,
    },
    {
      name: "Settings Enabled",
      desc: "",
      linked: 0,
      enableLinked: 18,
    },
    {
      name: "Manage Organization",
      desc: "Manage Organization master switch",
      linked: 4,
    },
    {
      name: "Manage Organization Enabled",
      desc: "",
      linked: 0,
      enableLinked: 3,
    },
    {
      name: "Edit Organization Name",
      desc: "",
      linked: 0,
    },
    {
      name: "View All Invites",
      desc: "",
      linked: 0,
    },
    {
      name: "View Audit Log",
      desc: "",
      linked: 0,
    },

    //Roles
    {
      display: "Roles",
      name: "Manage Roles",
      desc: "Roles master switch",
      linked: 7,
    },
    {
      display: "Roles Enabled",
      name: "Manage Roles Enabled",
      desc: "",
      linked: 0,
      enableLinked: 6,
    },
    {
      name: "View Roles",
      desc: "",
      linked: 0,
    },
    {
      name: "Create Role",
      desc: "Equal or lower than your role",
      linked: 0,
    },
    {
      name: "Delete Role",
      desc: "Equal or lower than your role",
      linked: 0,
    },
    {
      name: "Edit Role",
      desc: "lower to your role, not your role",
      linked: 0,
    },
    {
      name: "Edit Role Dashboard",
      desc: "",
      linked: 0,
    },
    {
      name: "View As Role",
      desc: "",
      linked: 0,
    },

    //Users
    {
      display: "Users",
      name: "Fusion Sign Up",
      desc: "Fusion Sign Up master switch",
      linked: 4,
    },
    {
      display: "Users Enabled",
      name: "Fusion Sign Up Enabled",
      desc: "",
      linked: 0,
      enableLinked: 3,
    },
    {
      name: "View Users",
      desc: "",
      linked: 0,
    },
    {
      name: "Create / Edit Users",
      desc: "",
      linked: 0,
    },
    {
      name: "Remove Users",
      desc: "",
      linked: 0,
    },
  ];

  return perms.map((item, index) => {
    return { ...item, index: index };
  });
}
