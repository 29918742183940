import React, { createContext, useContext, useEffect, useState } from "react";
import TabsHeader from "../../components/TabsHeader";
import ConnectedSystems from "./ConnectedSystems";
import Devices from "./Devices";
import * as api from "apis/FusionAPI";
import { ColorContext } from "App";
import DeviceGroups from "./DeviceGroups";
import { usePerms } from "hooks/usePerms";
import RescAlertDevicesWidget from "./RescAlertDevicesWidget";

export const ConnectionsContext = createContext({});

export default function Connections({ widget = false, widgetParams }) {
  const [tabs, setTabs] = useState([1, 0]);

  const theme = useContext(ColorContext);
  const perms = usePerms();

  const [visibleDeviceTypes, setVisibleDeviceTypes] = useState({
    Flood: true,
    "Manual Road Closure": true,
    "Technology Bridge_Flashing Lights": true,
    "Technology Bridge_Siren/Giant Voice": true,
  });

  const canViewDeviceType = (device) => {
    //REPLACE _ACCESS WITH PERMS
    if (device.device_type === "Flood")
      return (
        perms.testPermission(
          [8, 9, 10, 11, 12, 13],
          ["ViewAWAREFloodConnections"]
        ) && visibleDeviceTypes["Flood"]
      );
    else if (device.device_type === "Manual Road Closure")
      return (
        perms.testPermission(
          [8, 9, 10, 11, 12, 13],
          ["ViewRescAlertDevicesManualRoadClosureConnections"]
        ) && visibleDeviceTypes["Manual Road Closure"]
      );
    else if (device.device_type === "Technology Bridge_Flashing Lights")
      return (
        perms.testPermission(
          [8, 9, 10, 11, 12, 13],
          ["ViewRescAlertDevicesTechnologyBridge_FlashingLightsConnections"]
        ) && visibleDeviceTypes["Technology Bridge_Flashing Lights"]
      );
    else if (device.device_type === "Technology Bridge_Siren/Giant Voice")
      return (
        perms.testPermission(
          [8, 9, 10, 11, 12, 13],
          ["ViewRescAlertDevicesTechnologyBridge_Siren-GiantVoiceConnections"]
        ) && 
        visibleDeviceTypes["Technology Bridge_Siren/Giant Voice"]
      );
    else return true;
  };

  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        return <ConnectedSystems />;
      case 1:
        return <Devices />;
      case 2:
        return <DeviceGroups />;
      default:
        return <div />;
    }
  };

  if (widget) {
    if (
      widgetParams.widgetType === "map" ||
      widgetParams.widgetType === "graph"
    ) {
      return (
        <ConnectionsContext.Provider
          value={{
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          <Devices
            sidebarOpen={widgetParams.sidebarOpen}
            widgetType={widgetParams.widgetType}
            dimensions={widgetParams.dimensions}
            preview={widgetParams.preview}
            widget
          />
        </ConnectionsContext.Provider>
      );
    }
    if (widgetParams.widgetType === "technologyBridge") {
      return (
        <ConnectionsContext.Provider
          value={{
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          <RescAlertDevicesWidget
            type="technologyBridge"
            sidebarOpen={widgetParams.sidebarOpen}
            dimensions={widgetParams.dimensions}
          />
        </ConnectionsContext.Provider>
      );
    }
    if (widgetParams.widgetType === "manualRoadClosure") {
      return (
        <ConnectionsContext.Provider
          value={{
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          <RescAlertDevicesWidget
            type="manualRoadClosure"
            sidebarOpen={widgetParams.sidebarOpen}
            dimensions={widgetParams.dimensions}
          />
        </ConnectionsContext.Provider>
      );
    }
    if (widgetParams.widgetType === "technologyBridgeSiren") {
      return (
        <ConnectionsContext.Provider
          value={{
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          <RescAlertDevicesWidget
            type="technologyBridgeSiren"
            sidebarOpen={widgetParams.sidebarOpen}
            dimensions={widgetParams.dimensions}
          />
        </ConnectionsContext.Provider>
      );
    }
  }

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {/* header */}
      <TabsHeader
        onTabChange={(newTabArr) => {
          setTabs([...newTabArr]);
        }}
        currentTabs={tabs}
        options={[
          { option: "Connected Systems" },
          { option: "Connected Devices" },
          { option: "Connected Device Groups" },
        ]}
        styles={[{}, { backgroundColor: "#436c98" }]}
      />

      {/* content */}
      <div
        style={{
          height: "calc(100% - 44px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ConnectionsContext.Provider
          value={{
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          {RenderTab()}
        </ConnectionsContext.Provider>
      </div>
    </div>
  );
}
