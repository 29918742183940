import useDebounce from "hooks/useDebounce";
import React, { useState, useContext } from "react";
import { AlertCard } from "components/AlertCard";
import Cookies from "js-cookie";
import { FaSort } from "react-icons/fa";
import { useNavigate, useOutlet } from "react-router-dom";
import Toggle from "./UI/Toggle";
import WidgetMap from "./WidgetMap";
import { Slider } from "antd";
import { MapContainer, TileLayer, Polygon, useMap } from "react-leaflet";
import { ColorContext, LayoutSizeContext } from "App";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import PageIndex from "./UI/PageIndex";

const alertLimit = 100;

export const AlertListPage = ({
  widget,
  dimensions,
  title,
  alertsList,
  setAlertsList,
  sidebarOpen,
  ROUTE,
  preview = false,
}) => {
  const layout = useContext(LayoutSizeContext);

  const navigate = useNavigate();

  const [filterInput, setFilterInput] = useState("");
  const debouncedFilter = useDebounce(filterInput, 1000);

  const [severitySortDirection, setSeveritySortDirection] = useState(true); // true is ascending, false is descending
  const [pageIndex, setPageIndex] = useState(1);

  const severitySort = () => {
    setSeveritySortDirection(!severitySortDirection);
    if (severitySortDirection)
      setAlertsList(alertsList.sort((a, b) => b.severity - a.severity));
    else setAlertsList(alertsList.sort((a, b) => a.severity - b.severity));
  };

  const [maxDist, setMaxDist] = useState(100);

  const [showLocalOnly, setShowLocalOnly] = useState(false);

  const [selectedAlert, setSelectedAlert] = useState(null);
  const [mapPolygon, setMapPolygon] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);

  function ChangeView({ center, bounds }) {
    const map = useMap();
    map.setView(center, map.getBoundsZoom(bounds));
    return null;
  }

  const [test, setTest] = useState([]);

  const onFinish = (alert, timestamps) => {
    if (test.some((obj) => obj.alert === alert.message_id)) return;
    let newObj = [...test];

    newObj.push({ alert: alert.message_id, timestamps: timestamps });

    newObj.sort(
      (a, b) =>
        a.timestamps[a.timestamps.length - 1] -
        b.timestamps[b.timestamps.length - 1]
    );

    setTest(newObj);
  };

  const theme = useContext(ColorContext);

  if (!widget) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 50,
              }}
            >
              <div style={{ fontSize: 20 }}>{title}</div>
              <input
                style={{
                  background: theme.primaryHighlight,
                  padding: 10,
                  borderRadius: 10,
                }}
                value={filterInput}
                onChange={(e) => setFilterInput(e.target.value)}
                placeholder="Search"
              ></input>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Show Local Only
                <Toggle
                  value={showLocalOnly}
                  callback={setShowLocalOnly}
                  colorDisabled={theme.primaryShadow}
                />
                {showLocalOnly && (
                  <div style={{ marginLeft: 20 }}>
                    Max Distance (mi):{" "}
                    <input
                      type="number"
                      min={0}
                      max={1000}
                      value={maxDist}
                      onChange={(e) => setMaxDist(e.target.value)}
                    />
                    <Slider
                      value={maxDist}
                      onChange={setMaxDist}
                      min={0}
                      max={1000}
                      step={5}
                      style={{ width: 150 }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                padding: "10px 60px",
                background: theme.primaryHighlight,
                borderRadius: 10,
                cursor: "pointer",
              }}
              onClick={() => navigate("/publicuser/createAlert")}
            >
              Create Alert
            </div>
          </div>
          <div
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
            onClick={() => {
              severitySort();
            }}
          >
            Severity <FaSort />
            {/* {alertsList.length} alerts */}
          </div>
        </div>

        <div style={{ overflow: "auto" }}>
          {alertsList.map((alert, index) => {
            const pageStart = alertLimit * (pageIndex - 1);
            const pageEnd = pageStart + alertLimit;

            if (
              (debouncedFilter === "" ||
                JSON.stringify(alert)
                  .toLowerCase()
                  .includes(debouncedFilter.toLowerCase())) &&
              index >= pageStart &&
              index < pageEnd
            )
              return (
                <AlertCard
                  index={index}
                  alert={alert}
                  getAlerts={() => {
                    setAlertsList(alertsList.filter((a) => a !== alert));
                  }}
                  canCloseAlert={
                    parseInt(alert.workplaceId) ===
                    parseInt(Cookies.get("workplaceId"))
                  }
                  localOnly={showLocalOnly}
                  maxDistance={maxDist}
                  onFinish={onFinish}
                />
              );
          })}
        </div>
        <PageIndex
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          maxPages={alertsList.length / alertLimit}
        />
      </div>
    );
  }

  if (widget) {
    if (dimensions[0] === 1 && dimensions[1] === 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              display: "flex",
              // borderTop: "1px solid #4A4A4A",
              borderBottom: "1px solid #4A4A4A",
            }}
          >
            <p style={{ width: 90, marginLeft: 30, textAlign: "center" }}>
              Severity
            </p>
            <p style={{ width: 150, textAlign: "center" }}>Type</p>
            <p style={{ width: 220, textAlign: "center" }}>Sender</p>
          </div>

          <div
            style={{
              height: layout.widgetHeight - 135,
              overflow: "auto",
            }}
          >
            {alertsList.map((alert, index) => {
              if (
                debouncedFilter === "" ||
                JSON.stringify(alert)
                  .toLowerCase()
                  .includes(debouncedFilter.toLowerCase())
              )
                return (
                  <AlertCard
                    key={index}
                    widget
                    dimensions={dimensions}
                    index={index}
                    alert={alert}
                    getAlerts={() => {
                      setAlertsList(alertsList.filter((a) => a !== alert));
                    }}
                    canCloseAlert={
                      parseInt(alert.workplaceId) ===
                      parseInt(Cookies.get("workplaceId"))
                    }
                    localOnly={showLocalOnly}
                    maxDistance={maxDist}
                  />
                );
            })}
          </div>

          <div
            style={{
              height: 57,
              display: "flex",
              alignContent: "center",
            }}
          >
            <button
              onClick={() => navigate("/publicuser/alerts")}
              style={{
                padding: 5,
                margin: 5,
                marginLeft: 0,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
              }}
            >
              All Alerts
            </button>
            <button
              onClick={() => navigate("/publicuser/createAlert")}
              style={{
                padding: 5,
                margin: 5,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
                marginLeft: 3,
              }}
            >
              Create Alert
            </button>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <p>Local Only</p>
              <Toggle
                value={showLocalOnly}
                callback={setShowLocalOnly}
                colorDisabled={theme.primaryShadow}
              />
            </div>
            {showLocalOnly && (
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 5,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Max Distance (mi):{" "}
                <Slider
                  value={maxDist}
                  onChange={setMaxDist}
                  min={0}
                  max={1000}
                  step={5}
                  style={{ width: "80%" }}
                />
              </div>
            )}
          </div>
        </div>
      );
    }

    if (dimensions[0] === 1 && dimensions[1] === 2) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div>
            {mapCenter && mapPolygon && mapPolygon[0][0] !== 0 && (
              <WidgetMap
                sidebarOpen={sidebarOpen}
                center={mapCenter}
                zoom={11}
                satelliteToggleStyle={{ bottom: 5, left: 5 }}
                preview={preview}
              >
                <ChangeView center={mapCenter} bounds={mapPolygon} />
                {mapPolygon.map((poly, index) => (
                  <Polygon key={index} positions={poly} />
                ))}
              </WidgetMap>
            )}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid #4A4A4A",
                borderBottom: "1px solid #4A4A4A",
              }}
            >
              <p style={{ width: 90, marginLeft: 30, textAlign: "center" }}>
                Severity
              </p>
              <p style={{ width: 150, textAlign: "center" }}>Type</p>
              <p style={{ width: 220, textAlign: "center" }}>Sender</p>
            </div>

            <div
              style={{
                height: layout.widgetHeight - 65,
                overflow: "auto",
              }}
            >
              {alertsList.map((alert, index) => {
                if (
                  debouncedFilter === "" ||
                  JSON.stringify(alert)
                    .toLowerCase()
                    .includes(debouncedFilter.toLowerCase())
                )
                  return (
                    <AlertCard
                      key={index}
                      widget
                      dimensions={dimensions}
                      index={index}
                      alert={alert}
                      getAlerts={() => {
                        setAlertsList(alertsList.filter((a) => a !== alert));
                      }}
                      canCloseAlert={
                        parseInt(alert.workplaceId) ===
                        parseInt(Cookies.get("workplaceId"))
                      }
                      localOnly={showLocalOnly}
                      maxDistance={maxDist}
                      selectedAlert={selectedAlert}
                      setSelectedAlert={setSelectedAlert}
                      setMapCenter={setMapCenter}
                      setMapPolygon={setMapPolygon}
                      sidebarOpen={sidebarOpen}
                    />
                  );
              })}
            </div>

            <div
              style={{
                height: 57,
                display: "flex",
                alignContent: "center",
              }}
            >
              <button
                onClick={() => navigate("/publicuser/alerts")}
                style={{
                  padding: 5,
                  margin: 5,
                  marginLeft: 0,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 10,
                }}
              >
                All Alerts
              </button>
              <button
                onClick={() => navigate("/publicuser/createAlert")}
                style={{
                  padding: 5,
                  margin: 5,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 10,
                  marginLeft: 3,
                }}
              >
                Create Alert
              </button>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <p>Local Only</p>
                <Toggle
                  value={showLocalOnly}
                  callback={setShowLocalOnly}
                  colorDisabled={theme.primaryShadow}
                />
              </div>
              {showLocalOnly && (
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Max Distance (mi):{" "}
                  <Slider
                    value={maxDist}
                    onChange={setMaxDist}
                    min={0}
                    max={1000}
                    step={5}
                    style={{ width: "80%" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (dimensions[0] === 2 && dimensions[1] === 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              display: "flex",
              borderTop: "1px solid #4A4A4A",
              borderBottom: "1px solid #4A4A4A",
              justifyContent: "space-between",
            }}
          >
            <p style={{ width: 140 }}>Severity</p>
            <p style={{ width: 200 }}>Type</p>
            <p style={{ width: 200 }}>Sender</p>
            <p style={{ width: 150, marginRight: 10 }}>Status</p>
          </div>
          <div style={{ height: layout.widgetHeight - 135, overflow: "auto" }}>
            {alertsList.map((alert, index) => {
              if (
                debouncedFilter === "" ||
                JSON.stringify(alert)
                  .toLowerCase()
                  .includes(debouncedFilter.toLowerCase())
              )
                return (
                  <AlertCard
                    key={index}
                    widget
                    dimensions={dimensions}
                    index={index}
                    alert={alert}
                    getAlerts={() => {
                      setAlertsList(alertsList.filter((a) => a !== alert));
                    }}
                    canCloseAlert={
                      parseInt(alert.workplaceId) ===
                      parseInt(Cookies.get("workplaceId"))
                    }
                    localOnly={showLocalOnly}
                    maxDistance={maxDist}
                  />
                );
            })}
          </div>
          <div
            style={{
              height: 57,
              display: "flex",
              alignContent: "center",
            }}
          >
            <button
              onClick={() => navigate("/publicuser/alerts")}
              style={{
                padding: 5,
                margin: 5,
                marginLeft: 0,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
              }}
            >
              All Alerts
            </button>
            <button
              onClick={() => navigate("/publicuser/createAlert")}
              style={{
                padding: 5,
                margin: 5,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
                marginLeft: 3,
              }}
            >
              Create Alert
            </button>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ marginRight: 5 }}>Local Only</p>
              <Toggle
                value={showLocalOnly}
                callback={setShowLocalOnly}
                colorDisabled={theme.primaryShadow}
              />
              {showLocalOnly && (
                <div style={{ marginLeft: 10 }}>
                  Max Distance (mi):{" "}
                  {/* <input
                    type="number"
                    min={0}
                    max={1000}
                    value={maxDist}
                    onChange={(e) => setMaxDist(e.target.value)}
                  /> */}
                  <Slider
                    value={maxDist}
                    onChange={setMaxDist}
                    min={0}
                    max={1000}
                    step={5}
                    style={{ width: 150 }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
};
