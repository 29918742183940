import { ColorContext } from "App";
import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/Sidebar_Logo_Open.png";

const TermsAndConditions = ({ data, setShowFull, setShowSmallMenu, mobile }) => {
  const theme = useContext(ColorContext);

  useEffect(() => {
    setShowFull(false);
    setShowSmallMenu(false);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // width: "100%",
        height: "100%",
        padding: 20,
      }}
    >
      {mobile ? (
        <img
          src={logo}
          style={{
            width: "80%",
            marginBottom: 20,
          }}
        />
      ) : (
        <div
          style={{
            backgroundImage: `url(${logo})`,
            backgroundSize: 289,
            width: 289,
            height: 164,
            alignSelf: "flex-start",
          }}
        />
      )}
      <h1>Terms of Use</h1>
      <p>This Terms of Use Policy was last modified on {data.date}</p>
      <div
        className="customScrollbar-Y"
        style={{
          background: theme.primaryShadow,
          overflow: "auto",
          width: "90%",
          marginTop: 40,
          padding: 20,
          height: "100%",
          whiteSpace: "pre-wrap",
          textAlign: "center",
        }}
      >
        <div
          className="html-content"
          dangerouslySetInnerHTML={{ __html: data.page }}
        />
      </div>
    </div>
  );
};

export default TermsAndConditions;
