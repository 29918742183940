import { Slider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { capitalizeWord, GetDeviceTypeString } from "utils/stringUtils";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import DevicesMap from "pages/System/DevicesMap";
import RadialAlertMap from "./RadialAlertMap";
import AlertIcons from "components/AlertIcons";
import { ColorContext, DeviceContext } from "App";
import CreateRadialAlert, {
  VerifyRadialAlert,
} from "./DeviceActions/CreateRadialAlert";
import ToggleFlashingLights from "./DeviceActions/ToggleFlashingLights";

const DeviceAction = ({ deviceList, setData, data, setError }) => {
  const [systemType, setSystemType] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceSubType, setDeviceSubType] = useState("");

  useEffect(() => {
    if (
      data?.node_id === undefined ||
      data?.deviceType === undefined ||
      data?.selectedDevice === undefined ||
      data?.selectedFunction === undefined
    ) {
      setData({
        node_id: "",
        deviceType: "",
        selectedDevice: "",
        selectedFunction: "",
        error: true,
      });
    }

    //TODO: Spend at least a month making this make sense
    if (data.deviceType) {
      if (data.deviceType.includes("RescAlert Devices")) {
        setSystemType("RescAlert Devices");
        const types = data.deviceType
          .replace("RescAlert Devices ", "")
          .split("_");
        setDeviceType(types[0]);
        setDeviceSubType(types[1]);
      } else {
        const systemType = data.deviceType.split(" ")[0];
        const deviceType = data.deviceType.split(" ")[1].split("_")[0];
        const subType = data.deviceType.split(" ")[1].split("_")[1];

        setSystemType(systemType);
        setDeviceType(deviceType);
        setDeviceSubType(subType);
      }
    }
  }, []);
  const theme = useContext(ColorContext);
  const deviceContext = useContext(DeviceContext);

  const updateData = (item) => {
    //create new object
    let newObj = { ...data, ...item };

    //trim unwanted keys
    newObj = Object.keys(newObj).reduce((acc, key) => {
      if (
        deviceFunctions[data.selectedFunction]?.keys.includes(key) ||
        universalKeys.includes(key)
      )
        acc[key] = newObj[key];

      return acc;
    }, {});

    setData({
      ...newObj,
      error: verifyData(newObj),
    });
  };

  const verifyData = (data) => {
    let error = false;
    if (
      data.node_id === "" ||
      data.deviceType === "" ||
      data.selectedFunction === ""
    ) {
      error = true;
    }

    //verify function variables
    error = deviceFunctions[data.selectedFunction]?.verify
      ? !deviceFunctions[data.selectedFunction].verify(data)
      : true;

    if (!error) {
      setError(null);
    }
    return error;
  };

  // const deviceTypes = [
  //   ...new Set(deviceList.map((device) => GetDeviceTypeString(device)).sort()),
  // ];

  const functionList = {
    "aware flood": ["Create Radial Alert"],
    "rescalert devices manual road closure": ["Toggle Manual Road Closure"],
    "rescalert devices technology bridge_flashing lights": [
      "Toggle Flashing Lights",
    ],
    "rescalert devices technology bridge_siren/giant voice": [
      "Toggle Siren/Giant Voice",
    ],
  };

  const deviceFunctions = {
    "Create Radial Alert": {
      component: (
        <CreateRadialAlert
          dataObj={data}
          deviceList={deviceList}
          onUpdate={updateData}
        />
      ),
      verify: (obj) => {
        const verified = VerifyRadialAlert(obj);
        if (!verified)
          setError({ message: "Action incomplete, check for missing fields." });
        return verified;
      },
      keys: ["headline", "description", "type", "radius", "severity"],
    },
    "Toggle Manual Road Closure": {
      component: (
        <ToggleFlashingLights
          dataObj={data}
          deviceList={deviceList}
          onUpdate={updateData}
        />
      ),
      verify: (obj) => {
        if (obj.status === null || obj.status === undefined || !obj.node_id) {
          setError({
            message: "Action incomplete, check for missing fields.",
          });
          return false;
        } else return true;
      },
      keys: ["status", "comment"],
    },
    "Toggle Flashing Lights": {
      component: (
        <ToggleFlashingLights
          dataObj={data}
          deviceList={deviceList}
          onUpdate={updateData}
        />
      ),
      verify: (obj) => {
        if (obj.status === null || obj.status === undefined || !obj.node_id) {
          setError({
            message: "Action incomplete, check for missing fields.",
          });
          return false;
        } else return true;
      },
      keys: ["status", "comment"],
    },
    "Toggle Siren/Giant Voice": {
      component: (
        <ToggleFlashingLights
          dataObj={data}
          deviceList={deviceList}
          onUpdate={updateData}
        />
      ),
      verify: (obj) => {
        if (obj.status === null || obj.status === undefined || !obj.node_id) {
          setError({
            message: "Action incomplete, check for missing fields.",
          });
          return false;
        } else return true;
      },
      keys: ["status", "comment"],
    },
  };

  const universalKeys = [
    "node_id",
    "deviceType",
    "selectedDevice",
    "selectedFunction",
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          padding: 20,
          borderRadius: 10,
          margin: 10,
          background: theme.primary,
          display: "flex",
          flexDirection: "row",
          gap: 20,
          alignItems: "center",
        }}
      >
        <div>
          System:{" "}
          <select
            style={{
              background: theme.base,
              padding: 5,
            }}
            onChange={(e) => {
              setSystemType(e.target.value);
              setDeviceType("");
              setDeviceSubType("");
              updateData({
                //we have to reset the values beneath it
                node_id: "",
                deviceType: "",
                selectedDevice: "",
                selectedFunction: "",
                error: true,
              });
            }}
            value={systemType}
          >
            <option value="">-Select-</option>
            {Object.keys(deviceContext.deviceTypes).map((type) => {
              return <option value={type}>{type}</option>;
            })}
          </select>
        </div>
        {systemType && (
          <div>
            Device Type:{" "}
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                setDeviceType(e.target.value);
                setDeviceSubType("");

                if (
                  deviceContext.deviceTypes[systemType][e.target.value] &&
                  (Object.keys(
                    deviceContext.deviceTypes[systemType][e.target.value]
                  ).length === 0 ||
                    Object.keys(
                      deviceContext.deviceTypes[systemType][e.target.value]
                    ).includes("validDevices"))
                )
                  updateData({
                    deviceType: systemType + " " + e.target.value,

                    //we have to reset the values beneath it
                    node_id: "",
                    selectedDevice: "",
                    selectedFunction: "",
                    error: true,
                  });
                else
                  updateData({
                    //we have to reset the values beneath it
                    node_id: "",
                    deviceType: "",
                    selectedDevice: "",
                    selectedFunction: "",
                    error: true,
                  });
              }}
              value={deviceType}
            >
              <option value="">-Select-</option>
              {Object.keys(deviceContext.deviceTypes[systemType]).map(
                (type) => {
                  return <option value={type}>{type}</option>;
                }
              )}
            </select>
          </div>
        )}
        {deviceType &&
          Object.keys(deviceContext.deviceTypes[systemType][deviceType])
            .length > 0 &&
          !Object.keys(
            deviceContext.deviceTypes[systemType][deviceType]
          ).includes("validDevices") && (
            <div>
              Sub Type:{" "}
              <select
                style={{
                  background: theme.base,
                  padding: 5,
                }}
                onChange={(e) => {
                  setDeviceSubType(e.target.value);
                  updateData({
                    deviceType:
                      systemType + " " + deviceType + "_" + e.target.value,

                    //we have to reset the values beneath it
                    node_id: "",
                    selectedDevice: "",
                    selectedFunction: "",
                    error: true,
                  });
                }}
                value={deviceSubType}
              >
                <option value="">-Select-</option>
                {Object.keys(
                  deviceContext.deviceTypes[systemType][deviceType]
                ).map((type) => {
                  return <option value={type}>{type}</option>;
                })}
              </select>
            </div>
          )}
        {data.deviceType && functionList[data.deviceType.toLowerCase()] && (
          <>
            Device Action:
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                updateData({ selectedFunction: e.target.value });
              }}
              value={data.selectedFunction || ""}
            >
              <option value="">-Select-</option>
              {functionList[data.deviceType.toLowerCase()].map((func) => {
                return <option value={func}>{func}</option>;
              })}
            </select>
          </>
        )}
      </div>
      {data.selectedFunction &&
        deviceFunctions[data.selectedFunction]?.component}
    </div>
  );
};

export default DeviceAction;
