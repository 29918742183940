import { ColorContext } from "App";
import React, { useContext, useEffect } from "react";
import Popup from "reactjs-popup";

const ConfirmPopup = ({
  open,
  setOpen,
  message,
  options,
  callbacks,
  buttonStyles = [],
  style,
  closeAction = () => setOpen(false),
}) => {
  useEffect(() => {
    
  }, [options]);
  const theme = useContext(ColorContext);
  return (
    <Popup open={open} onClose={closeAction} closeOnDocumentClick closeOnEscape>
      <div
        style={{
          backgroundColor: "white",
          color: "black",
          borderRadius: 15,
          userSelect: "none",
          padding: 30,
          alignItems: "center",
          justifyContent: "center",
          width: 250,
          ...style,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div>{message}</div>
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            gap: 10,
          }}
        >
          {options.map((option, index) => {
            return (
              <div
                key={index}
                style={{
                  color: theme.fontColor,
                  padding: "7px 20px 7px 20px",
                  backgroundColor: theme.background,
                  borderRadius: 15,
                  cursor: "pointer",
                  ...buttonStyles[index],
                }}
                onClick={() => {
                  callbacks[index]();
                }}
              >
                {option}
              </div>
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmPopup;
