import React, { useContext, useEffect, useState } from "react";
import GlobalActionsDropdown from "./GlobalActionsDropdown";
import * as api from "apis/FusionAPI";
import Device from "./GlobalActions/If/Device";
import CreateFusionAlert from "./GlobalActions/then/CreateFusionAlert";
import DateTime from "./GlobalActions/If/DateTime";
import { ArrayToLinkedList, LinkedListToArray } from "utils/parserUtils";
import { PiWarningFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

export const GlobalActionsBuilder = ({
  setShowNewGlobalAction,
  setGlobalActions,
  deviceData,
  initialData, // for editing
  setInitialData,
}) => {
  const navigate = useNavigate();
  const perms = usePerms();
  const addGlobalAction = (action) => {
    setInitialData(null);
    setSubmitting(true);

    if (initialData) {
      const updatedData = {
        ...action,
        title: actionTitle,
      };
      api.updateGlobalAction(initialData.id, updatedData).then((res) => {
        // Then fetch new global actions from server
        if (res.status === 200) {
          api.getGlobalActions().then((res) => {
            setGlobalActions(res.data.result);
            setShowNewGlobalAction(false);
            setSubmitting(false);
          });
        }
      });
    } else {
      api.insertGlobalAction(action).then((res) => {
        // Then fetch new global actions from server
        if (res.status === 200) {
          api.getGlobalActions().then((res) => {
            setGlobalActions(res.data.result);
            setShowNewGlobalAction(false);
            setSubmitting(false);
          });
        }
      });
    }
  };

  const [actionTitle, setActionTitle] = useState("");

  const [retrigger, setRetrigger] = useState("");

  const [anyErrors, setAnyErrors] = useState(true);
  const [error, setError] = useState(null);

  const theme = useContext(ColorContext);

  const [head, setHead] = useState({
    type: "if",
    selectedAction: "-Select-",
    integrationType: "-Select-",
    integrationData: {
      error: true,
    },
    showDropdown: false,
    next: null,
    prev: null,
  });

  const [list, setList] = useState([head]);

  const [submitting, setSubmitting] = useState(false);

  const menuItems = {
    if: ["-Select-", "Device", "Date & Time", "Date", "Time"],
    then: ["-Select-", "Device Action", "Send Email"],
    until: [
      "-Select-",
      "Original Condition Resolved",
      "Device",
      "Date & Time",
      "Date",
      "Time",
    ],
    done: ["-Select-", "submit"],
  };

  const latLongToCityState = async (lat, long) => {
    let city, state;
    try {
      const result = await api.getCityStateFromCoordinates(lat, long);
      city = result.data.result.city_name;
      state = result.data.result.state_name;
      if (city && state) {
        return { city: city, state: state };
      }
    } catch (error) {
      console.error("Error getting city and state:", error);
    }
  };

  const renderList = (listHead) => {
    setList(LinkedListToArray(listHead));
  };

  useEffect(() => {
    renderList(head);
  }, [retrigger]);

  useEffect(() => {
    (async () => {
      if (
        !(await perms.validatePermissions([
          "ViewConnections",
          "Create-EditGlobalActions",
        ]))
      ) {
        navigate("/404");
        return;
      }
    })();
  }, []);

  useEffect(() => {
    if (initialData) {
      //RECONSTRUCT DATA INTO GLOBAL ACTION
      setActionTitle(initialData.title);

      try {
        const rawDataList = JSON.parse(initialData.rawDataList);
        //build linked list
        const listHead = ArrayToLinkedList(rawDataList);
        setList(rawDataList);
        setHead(listHead);
        renderList(listHead);
      } catch (e) {}
    } else {
    }
  }, []);

  const getAnyErrors = () => {
    //check if any until date/time is before if date/time
    const ifNodes = list.filter((item) => item.type === "if");
    const untilNodes = list.filter((item) => item.type === "until");

    const ifDates = ifNodes.map((item) => {
      if (item.integrationType === "Date & Time") {
        return item.integrationData.dateTime;
      } else if (item.integrationType === "Date") {
        return item.integrationData.date;
      } else if (item.integrationType === "Time") {
        return item.integrationData.time;
      }
    });

    const untilDates = untilNodes.map((item) => {
      if (item.integrationType === "Date & Time") {
        return item.integrationData.dateTime;
      } else if (item.integrationType === "Date") {
        return item.integrationData.date;
      } else if (item.integrationType === "Time") {
        return item.integrationData.time;
      }
    });

    for (let i = 0; i < ifDates.length; i++) {
      for (let j = 0; j < untilDates.length; j++) {
        if (ifDates[i] >= untilDates[j]) {
          setError({ message: "Invalid date/time." });
          setAnyErrors(true);
          return true;
        }
      }
    }
    setError(null);
    setAnyErrors(false);

    if (
      list
        .map((item) => {
          if (item.type === "done") return true;
          if (item.integrationType === "Original Condition Resolved")
            return true;
          if (item.integrationData && item.integrationData.error) {
            setAnyErrors(true);

            return false;
          } else return true;
        })
        .includes(false)
    ) {
      if (!error) setError({ message: "Action not valid, check for errors." });
      return true;
    } else {
      setError(null);
      setAnyErrors(false);
      return false;
    }
  };

  useEffect(() => {
    getAnyErrors();
  }, [list]);

  async function submitHandler() {
    if (submitting) {
      return;
    }

    //verify list
    if (getAnyErrors()) {
      setError({ message: "Some action has an error." });
      return false;
    }

    //apply function specific finishes to data
    for (let i = 0; i < list.length; i++) {
      switch (list[i].integrationData.selectedFunction) {
        // check for create radial alert and convert latlong to citystate
        case "Create Radial Alert":
          const { city, state } = await latLongToCityState(
            list[i].integrationData.selectedDevice.location.lat,
            list[i].integrationData.selectedDevice.location.lng
          );

          list[i].integrationData.city = city;
          list[i].integrationData.state = state;
          break;
        default:
          break;
      }
    }

    setSubmitting(true);

    // return;
    addGlobalAction(BuildGlobalAction(list));
  }

  return (
    <div
      style={{
        marginTop: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 20,
          backgroundColor: theme.primaryShadow,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 24,
          }}
        >
          {initialData ? "Edit" : "Add"} Global Action
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <input
              type="text"
              name="title"
              placeholder="Title"
              style={{
                marginTop: 10,
                padding: 5,
                borderRadius: 5,
                border: "1px solid grey",
              }}
              onChange={(e) => {
                setActionTitle(e.target.value);
              }}
              value={actionTitle}
            />
            {actionTitle.length === 0 && (
              <div style={{ marginTop: 12 }}>
                <PiWarningFill style={{ color: "yellow" }} />
              </div>
            )}
          </div>
        </div>
        <button
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            width: "fit-content",
            padding: "5px 20px",
            border: "1px solid grey",
            borderRadius: 10,
          }}
          onClick={() => {
            setShowNewGlobalAction(false);
            setInitialData(null);
          }}
        >
          Cancel
        </button>
      </div>

      {/* builder */}
      <div>
        {list.map((item, index) => {
          return (
            <>
              <GlobalActionsDropdown
                key={index}
                itemNode={item}
                menuItems={menuItems[item.type]}
                index={index}
                setRetrigger={setRetrigger}
                submitHandler={submitHandler}
                deviceData={deviceData}
                anyErrors={anyErrors}
                submitting={submitting}
              />
            </>
          );
        })}

        {error && (
          <div style={{ color: "red", margin: 10, textAlign: "right" }}>
            {error.message}
          </div>
        )}
      </div>
    </div>
  );

  //Convert the list of actions to a global action object
  function BuildGlobalAction(arr) {
    let mode = 0;
    let obj = {
      state: "inactive",
      title: actionTitle || "New Global Action",
      actionName: "",
      triggerName: "",
      resolutionName: "",
      triggers: [],
      actions: [],
      resolutions: [],
      rawDataList: JSON.stringify(
        arr.map((item) => {
          const itemCopy = { ...item };
          delete itemCopy.next;
          delete itemCopy.prev;
          return itemCopy;
        })
      ),
    };

    let trigger = [];
    let actionName = "";
    let triggerName = "";
    let resolutionName = "";
    let actionCount = 0;
    let triggerCount = 0;
    let resolutionCount = 0;

    let date;

    //create triggers
    arr.forEach((item, index) => {
      let objMap;

      if (item.type === "if") {
        if (triggerCount === 0) triggerName = item.integrationType;
        triggerCount++;
      } else if (item.type === "then") {
        if (actionCount === 0) actionName = item.integrationType;
        actionCount++;
      } else if (item.type === "until") {
        if (resolutionCount === 0) resolutionName = item.integrationType;
        resolutionCount++;
      }

      //map object to expected values
      switch (item.integrationType) {
        //conditions
        case "Device":
          objMap = {
            value: {
              type: "device",
              connection: item.integrationData.deviceType,
              deviceId: item.integrationData.node_id,
              value1: {
                type: "key",
                value: item.integrationData.keyToCheck,
              },
              operation: item.integrationData.operator,
              value2: {
                type: item.integrationData.inputValueType ? "immediate" : "key",
                value: item.integrationData.inputValueType
                  ? item.integrationData.valueToCompare
                  : item.integrationData.keyToCompare,
              },
            },
          };
          break;

        case "Date":
          date = new Date(item.integrationData.date);

          objMap = {
            value: {
              type: "date",
              value: {
                value: date,
              },
            },
          };
          break;
        case "Time":
          const strParts = item.integrationData.time.split(":");
          date = new Date();
          date.setHours(strParts[0], strParts[1], 0);

          objMap = {
            value: {
              type: "time",
              value: {
                value: date,
              },
            },
          };
          break;
        case "Date & Time":
          date = new Date(item.integrationData.dateTime);

          objMap = {
            value: {
              type: "datetime",
              value: {
                value: date,
              },
            },
          };
          break;
        case "Original Condition Resolved":
          objMap = {
            value: {
              type: "triggerresolve",
              value: {},
            },
          };
          break;

        //actions
        case "Create Fusion Alert":
          objMap = {
            value: {
              params: {
                function: "createAlert",
                params: {
                  ...item.integrationData,
                },
              },
            },
          };
          break;
        case "Toggle Manual Road Closure":
          objMap = {
            value: {
              params: {
                function: "toggleFlashingLights",
                params: {
                  ...item.integrationData,
                },
              },
            },
          };
          break;
        case "Device Action":
          objMap = {
            value: {
              function: "deviceAction",
              params: {
                ...item.integrationData,
                deviceId: item.integrationData.node_id,
                actionType: item.integrationData.selectedFunction,
              },
            },
          };
          break;
        case "Send Email":
          objMap = {
            value: {
              function: "sendEmail",
              params: {
                ...item.integrationData,
              },
            },
          };
          break;
        default:
          objMap = {
            value: {},
          };
          break;
      }

      objMap = {
        ...objMap,
        option: index > 0 ? arr[index - 1].selectedAction : "and",
      };

      switch (mode) {
        case 0: //if mode
          if (objMap.option.toLowerCase().includes("and"))
            trigger.push(objMap.value);
          else if (objMap.option.toLowerCase().includes("or")) {
            obj.triggers.push(trigger);
            trigger = [objMap.value];
          }

          if (objMap.option.toLowerCase().includes("then")) {
            obj.triggers.push(trigger);
            mode++;
            trigger = []; //reset trigger for until block
          } else break;
        case 1: //then mode
          if (
            (objMap.option.toLowerCase().includes("and") ||
              objMap.option.toLowerCase().includes("then")) &&
            !objMap.option.toLowerCase().includes("until") &&
            !objMap.option.toLowerCase().includes("done")
          ) {
            obj.actions.push(objMap.value);
            break;
          } else if (objMap.option.toLowerCase().includes("until")) {
            mode++;
          } else if (objMap.option.toLowerCase().includes("done")) {
            obj.resolutions.push([
              {
                type: "triggerresolve",
                value: {},
              },
            ]);

            return;
          } else break;
        case 2: //until mode
          //   "Built global action: objMap.option",
          //   objMap.option,
          //   objMap
          // );

          if (!objMap.option.toLowerCase().includes("done")) {
            if (
              objMap.option.toLowerCase().includes("and") ||
              objMap.option.toLowerCase().includes("then") ||
              objMap.option.toLowerCase() === "until"
            ) {
              trigger.push(objMap.value);
            } else if (objMap.option.toLowerCase().includes("or")) {
              obj.resolutions.push(trigger);
              trigger = [objMap.value];
            }

            break;
          } else {
            obj.resolutions.push(trigger);
            return;
          }
        default:
          break;
      }
    });

    obj = {
      ...obj,
      actionName: actionCount === 1 ? actionName : "Multiple",
      triggerName: triggerCount === 1 ? triggerName : "Multiple",
      resolutionName:
        resolutionCount === 1
          ? resolutionName
          : resolutionCount === 0
          ? "none"
          : "Multiple",
    };

    return obj;
  }
};
