import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  useMapEvents,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getRandomHexColor, greyOutColor } from "utils/colorUtils";
import { ColorContext } from "App";
import PlacesSearch from "components/PlacesSearch";
import Cookies from "js-cookie";
import {
  displayStatusOptions,
  getDynamicDeviceIcon,
  getRescAlertStatus,
  imageToIcon,
} from "utils/iconUtils";
import { minWidth, textAlign } from "@mui/system";
import { FaX } from "react-icons/fa6";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import FullScreenMap from "components/FullScreenMap";

function RoadDefinerMap({
  lines,
  updateLines,
  initialData,
  endsIcon,
  uninteractive = false,
  device,
}) {
  const colorContext = useContext(ColorContext);

  const [selectedLine, setSelectedLine] = useState(0);
  const [pointPopupOpen, setPointPopupOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState(
    initialData
      ? [initialData.location.lat, initialData.location.lng]
      : [Cookies.get("latitude"), Cookies.get("longitude")]
  );
  const [searchCoords, setSearchCoordsState] = useState(
    initialData
      ? {
          latitude: initialData.location.lat,
          longitude: initialData.location.lng,
        }
      : {
          latitude: 0,
          longitude: 0,
        }
  );

  const [timesUpdated, setTimesUpdated] = useState(0);

  useEffect(() => {
    const latitude = initialData?.location.lat || Cookies.get("latitude");
    const longitude = initialData?.location.lng || Cookies.get("longitude");
    setMapCenter([latitude ? latitude : 0, longitude ? longitude : 0]);
  }, []);

  // const [aVariable, setAVariable] = useState(false);

  const MapEventListener = () => {
    useMapEvents({
      popupopen: (e) => setPointPopupOpen(true),
      popupclose: (e) => setPointPopupOpen(false),
      click: (e) => {
        if (pointPopupOpen) return;
        // Pass the latlng of the click to the addMarker function
        addPointToLine(e.latlng);
      },
    });
  };

  const moveToLocation = (location) => {
    setMapCenter([location.latitude, location.longitude]);
    setSearchCoordsState({
      latitude: location.latitude,
      longitude: location.longitude,
    });
  };

  const setSearchCoords = (newCoords) => {
    setSearchCoordsState({
      latitude: newCoords.latitude,
      longitude: newCoords.longitude,
    });
  };

  const applySearchCoords = () => {
    if (parseFloat(searchCoords.latitude) && parseFloat(searchCoords.longitude))
      moveToLocation({
        latitude: parseFloat(searchCoords.latitude),
        longitude: parseFloat(searchCoords.longitude),
      });
  };

  const updateLinesT = (func) => {
    setTimesUpdated(timesUpdated + 1);
    updateLines(func);
  };

  const addLine = () => {
    let newSelected = lines.length;
    updateLinesT((p) => {
      newSelected = p.length;
      p.push({
        points: [],
      });
      return p;
    });
    setSelectedLine(newSelected);
  };

  const removeLine = (lineIndex) => {
    if (selectedLine === lineIndex) setSelectedLine(0);
    updateLinesT((p) => {
      p.splice(lineIndex, 1);
      return p;
    });
  };

  const addPointToLine = (point) => {
    if (!lines[selectedLine]) return;
    let run = false;
    updateLinesT((p) => {
      //for some reason this runs more than once sometimes. I have no clue why.
      if (run) return p;

      run = true;
      const pLines = [...p];
      pLines[selectedLine].points.push(point);
      return pLines;
    });
  };

  const removePointFromLine = (lineIndex, pointIndex) => {
    if (!lines[lineIndex] || !lines[lineIndex].points[pointIndex]) return;
    let run = false;

    updateLinesT((p) => {
      //for some reason this runs more than once. I have no clue why.
      if (run) return p;

      run = true;
      const pLines = [...p];
      p[lineIndex].points.splice(pointIndex, 1);
      return pLines;
    });
  };

  useEffect(() => {
    setPointPopupOpen(false);
  }, [lines, selectedLine]);

  const styles = {
    lineButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      backgroundColor: colorContext.primary,
      padding: 10,
      borderRadius: 10,
      cursor: "pointer",
      marginRight: 5,
      height: 25,
      textAlign: "center",
      minWidth: 25,
    },
    selectedLine: {
      backgroundColor: colorContext.primaryHighlight,
    },
  };

  return (
    <div
      style={{
        userSelect: "none",
        display: "flex",
        flexDirection: "column",
        maxWidth: 1000,
        height: "100%",
      }}
    >
      {/* Line selector */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          overflowX: "auto",
          fontSize: 24,
          marginBottom: 7,
        }}
      >
        <div style={{ ...styles.lineButton, fontSize: 30 }} onClick={addLine}>
          +
        </div>
        {lines &&
          lines.map((line, index) => {
            return (
              <div
                style={
                  index === selectedLine
                    ? { ...styles.lineButton, ...styles.selectedLine }
                    : styles.lineButton
                }
                onClick={() => setSelectedLine(index)}
              >
                Road Segment {index + 1}
                <div
                  style={{
                    marginLeft: 2,
                    color: "lightgrey",
                    fontSize: 15,
                    marginTop: 5,
                    padding: 5,
                  }}
                  onClick={() => removeLine(index)}
                >
                  <FaX />
                </div>
              </div>
            );
          })}
      </div>

      {/* Map */}
      <div
        style={{
          flex: 1,
          marginTop: -80,
          pointerEvents: "none",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            // zIndex: 1000,
            top: 100,
            right: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "flex-end",
              pointerEvents: "auto",
            }}
          >
            <PlacesSearch
              noBack
              noAutoAdd
              places={[]}
              setSearching={() => {}}
              addLocation={moveToLocation}
              setMapCenter={setMapCenter}
              quickSave
            />
            <div style={{ display: "flex", zIndex: 999 }}>
              <input
                type="text"
                placeholder="latitude"
                style={{
                  background: colorContext.primary,
                  height: 40,
                  borderRadius: 10,
                  paddingLeft: 15,
                  marginRight: 15,
                  width: 75,
                }}
                value={searchCoords.latitude}
                onChange={(e) => {
                  e.stopPropagation();
                  setSearchCoords({
                    latitude: e.target.value,
                    longitude: searchCoords.longitude,
                  });
                  if (parseFloat(e.target.value)) {
                    setMapCenter([parseFloat(e.target.value), mapCenter[1]]);
                  }
                }}
              />
              <input
                type="text"
                placeholder="longitude"
                style={{
                  background: colorContext.primary,
                  height: 40,
                  borderRadius: 10,
                  paddingLeft: 15,
                  width: 75,
                }}
                value={searchCoords.longitude}
                onChange={(e) => {
                  e.stopPropagation();
                  setSearchCoords({
                    latitude: searchCoords.latitude,
                    longitude: e.target.value,
                  });
                  if (parseFloat(e.target.value)) {
                    setMapCenter([mapCenter[0], parseFloat(e.target.value)]);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <FullScreenMap
          key={"fullScreenMap"}
          mapCenter={mapCenter}
          zoom={13}
          style={{ borderRadius: 10, height: "100%", pointerEvents: "auto" }}
        >
          <LocationSetter center={mapCenter} zoom={16} />
          {/* <MapContainer
          key={mapCenter[0].toString() + mapCenter[1].toString()}
          center={mapCenter}
          zoom={13}
          style={{ height: "100%", width: "100%" }}
        > */}

          {/* Handle map click events */}
          <MapEventListener />
          {/* Render each line with its markers */}
          {lines.map((line, lineIndex) => (
            <>
              {line.points.length > 1 && (
                <Polyline
                  key={lineIndex + "" + timesUpdated}
                  positions={line.points}
                  color={
                    getRescAlertStatus(device) ===
                    displayStatusOptions(device)[-1]
                      ? "#FFA500"
                      : getRescAlertStatus(device) ===
                        displayStatusOptions(device)[0]
                      ? lineIndex === selectedLine
                        ? "#00ff00"
                        : greyOutColor("#00ff00", 0.75)
                      : getRescAlertStatus(device) === "Warning"
                      ? lineIndex === selectedLine
                        ? "#ffAA00"
                        : greyOutColor("#ffAA00", 0.75)
                      : lineIndex === selectedLine
                      ? "#ff0000"
                      : greyOutColor("#ff0000", 0.75)
                  }
                />
              )}
              {line.points.map((point, pointIndex) => {
                return (
                  <Marker
                    key={lineIndex + "" + pointIndex + "" + timesUpdated}
                    position={point}
                    draggable={lineIndex === selectedLine}
                    eventHandlers={{
                      dragend: (e) => {
                        const newPosition = e.target.getLatLng();

                        if (
                          !newPosition ||
                          typeof newPosition.lat === "undefined" ||
                          typeof newPosition.lng === "undefined"
                        )
                          return;

                        const newLines = [...lines];
                        newLines[lineIndex].points[pointIndex] = newPosition;
                        updateLinesT(() => newLines);
                      },
                    }}
                    icon={
                      pointIndex == 0 || pointIndex == line.points.length - 1
                        ? device
                          ? getDynamicDeviceIcon(device, "icon")
                          : imageToIcon(endsIcon, [50, 50], [10, 16])
                        : new L.DivIcon({
                            className: "custom-x-icon",
                            html: `<div>
                <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                <div style="color: ${
                  getRescAlertStatus(device) ===
                  displayStatusOptions(device)[-1]
                    ? "#FFA500"
                    : getRescAlertStatus(device) ===
                      displayStatusOptions(device)[0]
                    ? lineIndex === selectedLine
                      ? "#00ff00"
                      : greyOutColor("#00ff00", 0.75)
                    : getRescAlertStatus(device) === "Warning"
                    ? lineIndex === selectedLine
                      ? "#ffAA00"
                      : greyOutColor("#ffAA00", 0.75)
                    : lineIndex === selectedLine
                    ? "#ff0000"
                    : greyOutColor("#ff0000", 0.75)
                }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                </div>`,
                            iconSize: [20, 20],
                            iconAnchor: [10, 16],
                          })
                    }
                    popup
                    interactive
                  >
                    <Popup closeOnClick closeButton={false} autoClose>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          removePointFromLine(lineIndex, pointIndex)
                        }
                      >
                        Delete
                      </div>
                    </Popup>
                  </Marker>
                );
              })}
            </>
          ))}
        </FullScreenMap>
      </div>
    </div>
  );
}

export default RoadDefinerMap;
