import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { usePerms } from "hooks/usePerms";
import { ColorContext, DeviceContext } from "App";
import DropdownSelect from "components/UI/DropdownSelect";
import { height } from "@mui/system";
import { FaEye, FaHourglass, FaLock } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";
import { group_icon, iconList } from "utils/iconUtils";
import ConfirmPopup from "components/UI/ConfirmPopup";

const NewGroup = ({
  setShowNewGroup,
  setDevices,
  integrations,
  initialData,
  setInitialData,
}) => {
  const deviceContext = useContext(DeviceContext);
  const theme = useContext(ColorContext);
  const [data, setData] = useState({
    name: "",
    iconId: 0,
    devices: [],
  });

  const [showDelete, setShowDelete] = useState(false);

  const perms = usePerms();

  const createGroup = async () => {
    if (!data.name) return alert("Please fill all fields");

    let res;
    if (initialData) {
      res = await api.editDeviceGroup(data);
    } else {
      res = await api.addDeviceGroup(data);
    }
    if (res.status === 200) {
      setShowNewGroup(false);
      setInitialData(null);
      deviceContext.getDevices();
    } else {
      alert("Error creating connection");
    }
  };

  const removeGroup = async () => {
    if (initialData) {
      const res = await api.deleteDeviceGroup(initialData);
      if (res.status === 200) {
        setShowNewGroup(false);
        setShowDelete(false);
        setInitialData(null);
        deviceContext.getDevices();
      }
    }
  };

  useEffect(() => {
    if (initialData) {
      setData({ ...initialData, devices: JSON.parse(initialData.devices) });
    }
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        color: theme.fontColor,
        background: theme.base,
        padding: 20,
        borderRadius: 10,
        border: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
          {initialData ? "Edit Group" : "New Group"}
        </div>
        <div
          style={{ cursor: "pointer", fontWeight: "bold", fontSize: "1.5rem" }}
          onClick={() => setShowNewGroup(false)}
        >
          X
        </div>
      </div>

      <form
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 10,
          height: "70%",
        }}
      >
        <label style={{ fontSize: "1rem" }}>Group Name</label>
        <input
          type="text"
          style={{
            background: theme.primary,
            height: 160,
            marginTop: 10,
            marginBottom: 30,
            borderRadius: 10,
            paddingLeft: 15,
          }}
          value={data.name}
          onChange={(e) =>
            setData({ ...data, name: e.target.value.substring(0, 45) })
          }
        ></input>
        <label style={{ fontSize: "1rem" }}>Group Icon</label>
        <div
          style={{
            display: "flex",
            gap: 10,
            marginBottom: 10,
          }}
        >
          {iconList().map((icon, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                background:
                  data.iconId === index
                    ? theme.primaryHighlight
                    : theme.primaryShadow,
                gap: 10,
                height: 40,
                width: 40,
                padding: 5,
                marginTop: 5,
                borderRadius: 5,
                cursor: "pointer",
                alignContent: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
              onClick={() => setData({ ...data, iconId: index })}
            >
              {group_icon(index)}
            </div>
          ))}
        </div>
        <label style={{ fontSize: "1rem" }}>Devices</label>
        <div
          style={{
            padding: 10,
            background: theme.primary,
            borderRadius: 10,
            overflowY: "auto",
            gap: 10,
          }}
        >
          {Object.keys(deviceContext.deviceTypes).map((system_type) => {
            return (
              <div>
                <h2>{system_type}</h2>

                <div style={{ marginLeft: 10 }}>
                  {Object.keys(deviceContext.deviceTypes[system_type]).map(
                    (device_type) => {
                      return (
                        <div>
                          <h3>{device_type}</h3>

                          <div style={{ marginLeft: 10 }}>
                            {(Object.keys(
                              deviceContext.deviceTypes[system_type][
                                device_type
                              ]
                            ).length > 0
                              ? Object.keys(
                                  deviceContext.deviceTypes[system_type][
                                    device_type
                                  ]
                                )
                              : [""]
                            )?.map((subType) => {
                              let deviceArr = deviceContext.devices.filter(
                                (f) =>
                                  f.system_type === system_type &&
                                  f.device_type ===
                                    device_type +
                                      (subType !== "" &&
                                      subType !== "validDevices"
                                        ? "_" + subType
                                        : "")
                              );

                              return (
                                <div>
                                  <h4>
                                    {subType !== "validDevices" ? subType : ""}
                                  </h4>

                                  <div style={{ marginLeft: 10 }}>
                                    {deviceArr.length > 0 &&
                                      deviceArr.map((device) => (
                                        <div key={device.id}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              background: data.devices.includes(
                                                device.id
                                              )
                                                ? theme.primaryShadow
                                                : "",
                                              gap: 10,
                                              padding: 5,
                                              marginTop: 5,
                                              borderRadius: 5,
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              checked={data.devices.includes(
                                                device.id
                                              )}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setData({
                                                    ...data,
                                                    devices: [
                                                      ...data.devices,
                                                      device.id,
                                                    ],
                                                  });
                                                } else {
                                                  setData({
                                                    ...data,
                                                    devices:
                                                      data.devices.filter(
                                                        (d) => d !== device.id
                                                      ),
                                                  });
                                                }
                                              }}
                                            />
                                            <div>{device.alias}</div>
                                          </div>
                                        </div>
                                      ))}
                                    {deviceArr.length === 0 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 10,
                                          padding: 5,
                                          marginTop: 5,
                                          borderRadius: 5,
                                        }}
                                      >
                                        No devices
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </form>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        {" "}
        {initialData ? (
          <div
            style={{
              cursor: "pointer",
              backgroundColor: "red",
              fontWeight: "bold",
              width: "fit-content",
              padding: "15px 30px",
              borderRadius: 10,
              fontSize: "1rem",
            }}
            onClick={() => setShowDelete(true)}
          >
            Remove
          </div>
        ) : (
          <div></div>
        )}
        <div
          style={{
            cursor: "pointer",
            backgroundColor: theme.primaryHighlight,
            fontWeight: "bold",
            width: "fit-content",
            padding: "15px 30px",
            borderRadius: 10,
            fontSize: "1rem",
          }}
          onClick={() => createGroup()}
        >
          {initialData ? "Save" : "Create"}
        </div>
      </div>

      {showDelete && (
        <ConfirmPopup
          open={showDelete}
          setOpen={setShowDelete}
          message={
            <>
              <h2>Warning: Permanent Removal of Group</h2>
              You are about to remove an entire group. This action is permanent
              and cannot be undone. Removing this group will NOT remove all
              devices connected to it. Please confirm you wish to proceed with
              this action.
            </>
          }
          options={["Cancel", "Remove"]}
          callbacks={[
            () => {
              setShowDelete(false);
            },
            () => {
              removeGroup();
            },
          ]}
          closeAction={() => {
            setShowDelete(false);
          }}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: "red", color: theme.fontColor },
          ]}
        />
      )}

      <style jsx>{`
        @media (max-width: 768px) {
          div[style*="flex-direction: column"] {
            flex-direction: column;
          }
          div[style*="flex-direction: row"] {
            flex-direction: column;
          }
          select,
          input {
            width: 100%;
          }
          div[style*="flex-direction: row"] > div {
            margin-top: 10px;
          }
          div[style*="justify-content: flex-end"] {
            justify-content: center;
          }
        }

        @media (max-width: 480px) {
          div[style*="font-size: 1.5rem"] {
            font-size: 1.2rem;
          }
          div[style*="font-size: 1rem"] {
            font-size: 0.9rem;
          }
          input,
          select {
            font-size: 0.9rem;
            height: 35px;
          }
          div[style*="padding: 15px 30px"] {
            padding: 10px 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default NewGroup;
