import React, { useState, useEffect, useContext } from "react";
import { Marker, Polygon, Polyline, Popup } from "react-leaflet";
import Cookies from "js-cookie";
import { capitalizeWord } from "utils/stringUtils";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import { useAPIData } from "hooks/useAPIData";
import TextToggle from "components/UI/TextToggle";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";
import L from "leaflet";
import { toDegrees, toRadians } from "utils/mathUtils";
import * as d3 from "d3";

import hurricaneIcon from "assets/icons/red-hurricane.png";

import * as turf from "@turf/turf";
import {
  ColorContext,
  LayoutSizeContext,
  UniqueWidgetRefreshContext,
} from "App";

const HurricanesTropicalStorms = ({
  widget,
  widgetRefresh,
  dimensions,
  preview,
  sidebarOpen,
}) => {
  const [fetching, setFetching] = useState(true);
  const [stormString, setStormString] = useState("");
  const [stormNames, setStormNames] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: Cookies.get("latitude"),
    lng: Cookies.get("longitude"),
  });
  const [historicalData, setHistoricalData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [showHistorical, setShowHistorical] = useState(false);

  const [error, setError] = useState({ state: false, message: "" });
  const [hurricaneData, setHurricaneData] = useState([]);
  const [zoom, setZoom] = useState(4);

  const layout = useContext(LayoutSizeContext);
  const theme = useContext(ColorContext);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    if (liveData.length === 0) {
      setHurricaneData(historicalData);
      setShowHistorical(true);
    } else {
      setHurricaneData(liveData);
      setShowHistorical(false);
    }
  }, [liveData, historicalData]);

  useEffect(() => {
    // setFetching(true);
  }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getHurricanesLive";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          let fixedData = res.data.result.map((item) => {
            if (
              item.name.includes("LIVE:") ||
              item.local_storm_name.includes("LIVE:")
            )
              return item;
            item.name = "LIVE: " + item.name;
            item.local_storm_name = "LIVE: " + item.local_storm_name;
            return item;
          });

          if (fixedData.length > 0) {
            setLiveData(fixedData);
            setHurricaneData(fixedData);
            getStormNames(fixedData);
          }

          FusionData.forceGet("getHurricanesHistorical").then((res) => {
            if (fixedData.length === 0) {
            } else {
              setStormString("All Live Storms");
              setMapCenter([Cookies.get("latitude"), Cookies.get("longitude")]);
            }
            setHistoricalData(res.data.result);

            setFetching(false);
          });
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    if (showHistorical) {
      setHurricaneData(historicalData);
      getStormNames(historicalData);
    } else {
      if (liveData.length > 0) {
        setHurricaneData(liveData);
        getStormNames(liveData);
      } else setShowHistorical(true);
    }
  }, [showHistorical, historicalData]);

  const getStormNames = (data) => {
    let stormList = [];
    data.map((item) => {
      const nameAndYear = item.name + " (" + item.code.split("_")[0] + ")";
      if (!stormList.includes(nameAndYear)) {
        stormList.push(nameAndYear);
      }
    });
    stormList.sort((a, b) => {
      return (
        parseInt(b.split("(")[1].split(")")[0]) -
        parseInt(a.split("(")[1].split(")")[0])
      );
    });
    setStormNames(stormList);
    setStormString(stormList[0]);
  };

  useEffect(() => {
    let instances = hurricaneData.filter(
      (item) => item.name + " (" + item.code.split("_")[0] + ")" === stormString
    );

    if (instances.length > 0)
      setMapCenter([instances[0].latitude, instances[0].longitude]);
  }, [stormString]);

  const generateHurricanePath = (stormName) => {
    let instances = hurricaneData.filter(
      (item) => item.name + " (" + item.code.split("_")[0] + ")" === stormName
    );

    let lines = [];
    for (let i = 0; i < instances.length; i++) {
      if (i === instances.length - 1) break;
      let lat1 = instances[i].latitude;
      let lon1 = instances[i].longitude;
      let lat2 = instances[i + 1].latitude;
      let lon2 = instances[i + 1].longitude;

      lines.push([
        [lat1, lon1],
        [lat2, lon2],
      ]);
    }

    return lines;
  };

  const getHurricaneInfo = () => {
    let instances = hurricaneData.filter(
      (item) => item.name + " (" + item.code.split("_")[0] + ")" === stormString
    );
    let lastUpdate = instances[instances.length - 1];

    if (lastUpdate) {
      return (
        <div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Type: {capitalizeWord(lastUpdate.type)} <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Date: {lastUpdate.locale_time} <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Category: {lastUpdate.category} <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Max Wind Speed: {parseFloat(lastUpdate.wind_speed).toFixed(1)} mph{" "}
            <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Latitude: {lastUpdate.latitude} ° <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Longitude: {lastUpdate.longitude} ° <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Baro Pressure: {lastUpdate.central_pressure} in.
            <br />
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const getLocalStormName = (stormName) => {
    let yearStr = stormName.split("(")[1].split(")")[0];
    let instances = hurricaneData.filter((item) => {
      return item.name === stormName.split("(")[0].trim();
    });

    let lastUpdate = instances[instances.length - 1];

    if (lastUpdate?.local_storm_name)
      return lastUpdate.local_storm_name + " (" + yearStr + ")";
    else return stormName;
  };

  const addColonToStormName = (name) => {
    const types = [
      "Tropical Depression",
      "Tropical Storm",
      "Hurricane",
      "Typhoon",
      "Severe Tropical Storm",
    ];
    let newName = name;
    types.forEach((type) => {
      if (name.includes(type)) {
        newName = name.replace(type, type + ":");
      }
    });
    return newName;
  };

  const generateLineToOutermostPoint = (currentPoint, prevPoint, radius) => {
    radius *= 0.0176229;
    const lat1 = toRadians(currentPoint[0]);
    const lon1 = toRadians(currentPoint[1]);
    const lat2 = toRadians(prevPoint[0]);
    const lon2 = toRadians(prevPoint[1]);

    // Calculate bearing from prevPoint to currentPoint
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const initialBearing = Math.atan2(y, x);

    // Perpendicular bearing
    const perpBearing = initialBearing + Math.PI / 2;

    // Length of the perpendicular line in degrees
    const lineLength = (radius / 6378137) * (180 / Math.PI);

    const point1 = [
      toDegrees(lat1 + lineLength * Math.cos(perpBearing)),
      toDegrees(lon1 + lineLength * Math.sin(perpBearing)),
    ];

    const point2 = [
      toDegrees(lat1 - lineLength * Math.cos(perpBearing)),
      toDegrees(lon1 - lineLength * Math.sin(perpBearing)),
    ];

    return [point1, point2];
  };

  const getOutermostSinglePoint = (radius, currentPoint, prevPoint) => {
    radius *= 0.0176229;

    const lat1 = toRadians(currentPoint[0]);
    const lon1 = toRadians(currentPoint[1]);
    const lat2 = toRadians(prevPoint[0]);
    const lon2 = toRadians(prevPoint[1]);

    // Calculate bearing from prevPoint to currentPoint
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const initialBearing = Math.atan2(y, x);
    const lBearing = initialBearing + Math.PI / 4;
    const rBearing = initialBearing + (7 * Math.PI) / 4;

    const lineLength = (radius / 6378137) * (180 / Math.PI);
    const point1 = [
      toDegrees(lat1 - lineLength * Math.cos(initialBearing)),
      toDegrees(lon1 - lineLength * Math.sin(initialBearing)),
    ];
    const point2 = [
      toDegrees(lat1 - lineLength * Math.cos(lBearing)),
      toDegrees(lon1 - lineLength * Math.sin(lBearing)),
    ];
    const point3 = [
      toDegrees(lat1 - lineLength * Math.cos(rBearing)),
      toDegrees(lon1 - lineLength * Math.sin(rBearing)),
    ];

    return [point3, point1, point2];
  };

  const getInnermostSinglePoint = (radius, currentPoint, prevPoint) => {
    radius *= 0.0176229 * 0.5;

    const lat1 = toRadians(currentPoint[0]);
    const lon1 = toRadians(currentPoint[1]);
    const lat2 = toRadians(prevPoint[0]);
    const lon2 = toRadians(prevPoint[1]);

    // Calculate bearing from prevPoint to currentPoint
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const initialBearing = Math.atan2(y, x);
    const lBearing = initialBearing + Math.PI / 4;
    const rBearing = initialBearing + (7 * Math.PI) / 4;

    const lineLength = (radius / 6378137) * (180 / Math.PI);
    const point1 = [
      toDegrees(lat1 - lineLength * Math.cos(initialBearing)),
      toDegrees(lon1 - lineLength * Math.sin(initialBearing)),
    ];
    const point2 = [
      toDegrees(lat1 - lineLength * Math.cos(lBearing)),
      toDegrees(lon1 - lineLength * Math.sin(lBearing)),
    ];
    const point3 = [
      toDegrees(lat1 - lineLength * Math.cos(rBearing)),
      toDegrees(lon1 - lineLength * Math.sin(rBearing)),
    ];

    return [point3, point1, point2];
  };

  const getHurricaneLivePredictSize = (item, time, baseTime) => {
    const t = new Date(time);
    const bt = new Date(baseTime);
    //base time to hours diff
    const timeDiff = (t - bt) / 1000 / 60 / 60;

    const tdToMi = (td) => {
      return d3
        .scaleLinear()
        .domain([0, 12, 24, 36, 48, 60, 72, 96, 120])
        .range([0, 30, 45, 61, 77, 93, 114, 167, 236])(td);
    };
    const size = 1609.34 * tdToMi(timeDiff) + 50000;

    return size;
  };

  const generateHurricanePathOutline = (stormName) => {
    let instances = hurricaneData.filter((item, index) => {
      return item.name + " (" + item.code.split("_")[0] + ")" === stormName;
    });
    let lines = [];

    for (let i = 1; i < instances.length; i++) {
      const line = generateLineToOutermostPoint(
        [instances[i].latitude, instances[i].longitude],
        [instances[i - 1].latitude, instances[i - 1].longitude],
        400000
      );
      lines.push(line);
    }

    if (lines.length == 0) return [];

    // map lines1 and lines2
    let line1 = lines.map((line) => line[0]);
    let line2 = lines.map((line) => line[1]);
    let farEdge = getOutermostSinglePoint(
      10000 / 0.0176229,
      [
        instances[instances.length - 1].latitude,
        instances[instances.length - 1].longitude,
      ],
      [
        instances[instances.length - 1 - 1].latitude,
        instances[instances.length - 1 - 1].longitude,
      ]
    );
    let closeEdge = getInnermostSinglePoint(
      10000 / 0.0176229,
      [instances[0].latitude, instances[0].longitude],
      [instances[1].latitude, instances[1].longitude]
    );

    return [
      [closeEdge[0][0], closeEdge[0][1]],
      ...closeEdge,
      ...line1,
      ...farEdge,
      ...line2.reverse(),
      [closeEdge[0][0], closeEdge[0][1]],
    ];
  };

  const generateHurricaneCone = (stormName) => {
    let instances = hurricaneData.filter(
      (item) =>
        item.name + " (" + item.code.split("_")[0] + ")" === stormName &&
        item.type === "forecast"
    );

    let lines = [];

    for (let i = 1; i < instances.length; i++) {
      const line = generateLineToOutermostPoint(
        [instances[i].latitude, instances[i].longitude],
        [instances[i - 1].latitude, instances[i - 1].longitude],
        getHurricaneLivePredictSize(
          instances[i],
          instances[i].utc_time,
          instances[0].utc_time
        )
      );
      lines.push(line);
    }

    if (lines.length == 0) return [];

    // map lines1 and lines2
    let line1 = lines.map((line) => line[0]);
    let line2 = lines.map((line) => line[1]);
    let farEdge = getOutermostSinglePoint(
      getHurricaneLivePredictSize(
        instances[instances.length - 1],
        instances[instances.length - 1].utc_time,
        instances[0].utc_time
      ),
      [
        instances[instances.length - 1].latitude,
        instances[instances.length - 1].longitude,
      ],
      [
        instances[instances.length - 1 - 1].latitude,
        instances[instances.length - 1 - 1].longitude,
      ]
    );

    return [
      [instances[0].latitude, instances[0].longitude],
      ...line1,
      ...farEdge,
      ...line2.reverse(),
      [instances[0].latitude, instances[0].longitude],
    ];
  };

  const smoothShape = (shape) => {
    if (shape.length < 3) return shape;
    let polygon = turf.polygon([shape]);

    let smoothed = turf.polygonSmooth(polygon, { iterations: 3 });

    //append the origin point to the end of the smoothed shape

    let simple = turf.simplify(smoothed, {
      tolerance: 0.5,
      highQuality: true,
    });
    let smoothed2 = turf.polygonSmooth(simple, { iterations: 3 });

    const cleanedShape = turf.union(
      turf.featureCollection([
        turf.polygon(smoothed2.features[0].geometry.coordinates),
        turf.polygon(smoothed2.features[0].geometry.coordinates),
      ])
    );

    const simple2 = turf.simplify(cleanedShape, {
      tolerance: 0.8,
      highQuality: true,
    });
    const smoothed3 = turf.polygonSmooth(simple2, { iterations: 3 });
    return smoothed3.features[0].geometry.coordinates[0];
  };

  const smoothPolyWithOrigin = (shape) => {
    if (shape.length < 3) return shape;
    let polygon = turf.polygon([shape]);

    let smoothed = turf.polygonSmooth(polygon, { iterations: 3 });
    const smoothPoly = smoothed.features[0].geometry.coordinates[0];

    return smoothPoly;
  };

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (fetching && widget) {
    return (
      <WidgetLoadingAnimation
        widgetName={"HurricanesTropicalStorms"}
        dimensions={dimensions}
      />
    );
  }

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  if (liveData.length === 0 && historicalData.length === 0) {
    return (
      <div>
        {widget && <WidgetTimeUpdate route={ROUTE} updateIntervalBasis={180} />}
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  const LiveStorm = () => {
    if (stormString === "All Live Storms") {
      const names = hurricaneData
        .map((item) => item.name)
        .reduce((acc, curr) => {
          if (!acc.includes(curr)) acc.push(curr);
          return acc;
        }, []);

      return names.map((item, index) => {
        const code = hurricaneData.find((storm) => storm.name === item).code;
        return (
          <Polygon
            key={index}
            positions={smoothPolyWithOrigin(
              generateHurricaneCone(item + " (" + code.split("_")[0] + ")")
            )}
            color="red"
          />
        );
      });
    }
    return (
      <Polygon
        positions={smoothPolyWithOrigin(generateHurricaneCone(stormString))}
        color="red"
      />
    );
  };

  const HistoricalStorm = () => {
    return (
      <Polygon
        positions={smoothShape(generateHurricanePathOutline(stormString))}
        color="red"
      />
    );
  };

  const HurricanePath = () => {
    if (stormString === "All Live Storms" && !showHistorical) {
      //find all live storms by name
      const names = hurricaneData
        .filter((filter) => filter.name.substring(0, 5) === "LIVE:")
        .map((item) => item.name)
        .reduce((acc, curr) => {
          if (!acc.includes(curr)) acc.push(curr);
          return acc;
        }, []);

      return names.map((item, index) => {
        const code = hurricaneData.find((storm) => storm.name === item).code;
        return (
          <Polyline
            key={`polyline-${index}`}
            positions={generateHurricanePath(
              item + " (" + code.split("_")[0] + ")"
            )}
            color="gray"
          />
        );
      });
    }
    return (
      <Polyline
        key={`polyline-1`}
        positions={generateHurricanePath(stormString)}
        color="gray"
      />
    );
  };

  const HurricaneIcons = () => {
    if (stormString === "All Live Storms") {
      return hurricaneData.map((item, index) => {
        return (
          <Marker
            key={`circle-${index}`}
            position={[item.latitude, item.longitude]}
            icon={L.icon({
              iconUrl: hurricaneIcon,
              iconSize: [20, 20],
              iconAnchor: [10, 10],
              html: `<span style="filter: invert(100%) sepia(100%) saturate(1000%) hue-rotate(-50deg);" />`,
            })}
            color="red"
          >
            <Popup>
              <div style={{ fontWeight: "bold" }}>{item.local_storm_name} </div>
              <div>Type: {capitalizeWord(item.type)} </div>
              <div>Date: {item.locale_time} </div>
              <div>Category: {item.category} </div>
              <div>
                Max Wind Speed: {parseFloat(item.wind_speed).toFixed(1)} mph{" "}
              </div>
              <div>Latitude: {item.latitude} ° </div>
              <div>Longitude: {item.longitude} ° </div>
              <div>Baro Pressure: {item.central_pressure} in. </div>
            </Popup>
          </Marker>
        );
      });
    }

    return hurricaneData
      .filter((item, index) => {
        return item.name + " (" + item.code.split("_")[0] + ")" === stormString;
      })
      .map((item, index) => {
        return (
          <Marker
            key={`circle-${index}`}
            position={[item.latitude, item.longitude]}
            icon={L.icon({
              iconUrl: hurricaneIcon,
              iconSize: [20, 20],
              iconAnchor: [10, 10],
              html: `<span style="filter: invert(100%) sepia(100%) saturate(1000%) hue-rotate(-50deg);" />`,
            })}
            color="red"
          >
            <Popup>
              <div style={{ fontWeight: "bold" }}>{item.local_storm_name} </div>
              <div>Type: {capitalizeWord(item.type)} </div>
              <div>Date: {item.locale_time} </div>
              <div>Category: {item.category} </div>
              <div>
                Max Wind Speed: {parseFloat(item.wind_speed).toFixed(1)} mph{" "}
              </div>
              <div>Latitude: {item.latitude} ° </div>
              <div>Longitude: {item.longitude} ° </div>
              <div>Baro Pressure: {item.central_pressure} in. </div>
            </Popup>
          </Marker>
        );
      });
  };

  const StormSelector = () => {
    return stormNames.length ? (
      <select
        style={{
          padding: 10,
          display: "flex",
          borderRadius: 5,
          backgroundColor: theme.primary,
          marginTop: 5,
          marginBottom: 5,
          width: "100%",
        }}
        onChange={(e) => {
          if (e.target.value === "") return;
          setStormString(e.target.value);
        }}
        value={stormString}
      >
        <option value="">- Select a storm -</option>
        {!showHistorical && (
          <option value="All Live Storms">All Live Storms</option>
        )}
        {stormNames.map((item, index) => {
          return (
            <option key={index} value={item}>
              {addColonToStormName(getLocalStormName(item))}
            </option>
          );
        })}
      </select>
    ) : (
      <div>No storms found</div>
    );
  };

  const HurricaneMap = ({ small = false }) => {
    return (
      <WidgetMap
        sidebarOpen={sidebarOpen}
        mapCenter={mapCenter}
        zoom={5}
        satelliteToggleStyle={small ? { bottom: "60px" } : {}}
        preview={preview}
      >
        <LocationSetter center={mapCenter} zoom={zoom} />

        {showHistorical && <HistoricalStorm />}
        {!showHistorical && <LiveStorm />}
        <HurricaneIcons />
        <HurricanePath />
      </WidgetMap>
    );
  };

  if (!dimensions)
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} updateIntervalBasis={180} addMargin />
        <div
          style={{
            overflowY: "auto",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FullScreenMap
            mapCenter={[Cookies.get("latitude"), Cookies.get("longitude")]}
            zoom={5}
            satelliteToggleStyle={{ bottom: "25px", left: "30px" }}
          >
            <LocationSetter center={mapCenter} zoom={zoom} />

            {showHistorical && <HistoricalStorm />}
            {!showHistorical && <LiveStorm />}
            <HurricaneIcons />
            <HurricanePath />
          </FullScreenMap>

          <div style={{ width: "100%" }}>
            <StormSelector />
            <div>{getHurricaneInfo()}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextToggle
                options={["Live", "Historical"]}
                value={showHistorical}
                setValue={setShowHistorical}
                disabled={liveData.length === 0}
              />
              {liveData.length === 0 && (
                <div style={{ marginLeft: 15 }}>
                  No live storms at this time.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE} updateIntervalBasis={180} />
        {hurricaneData && (
          <div
            style={{
              fontSize: layout.baseFontSize,
              width: "100%",
              height: layout.widgetHeight - 100,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                padding: 10,
                width: layout.doubleWidgetWidth,
              }}
            >
              {StormSelector()}

              <div
                style={{
                  position: "relative",
                  bottom: -layout.widgetHeight + 190,
                  height: 0,
                  fontSize: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextToggle
                    options={["Live", "Historical"]}
                    value={showHistorical}
                    setValue={setShowHistorical}
                    disabled={liveData.length === 0}
                  />
                  {liveData.length === 0 && (
                    <div style={{ marginLeft: 15 }}>
                      No live storms at this time.
                    </div>
                  )}
                </div>
              </div>
              <div>{getHurricaneInfo()}</div>
            </div>
            <div
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <HurricaneMap />
            </div>
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE} updateIntervalBasis={180} />
        <div
          style={{
            width: "100%",
            padding: 20,
            position: "absolute",
            marginTop: 20,
            marginLeft: 45,
            zIndex: 1000,
          }}
        >
          <div
            style={{
              width: layout.widgetWidth - 120,
            }}
          >
            {StormSelector()}
          </div>
        </div>
        {hurricaneData && hurricaneData.length > 0 && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "relative",
                  bottom: -layout.widgetHeight + 125,
                  left: 20,
                  zIndex: 1000,
                  height: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextToggle
                    options={["Live", "Historical"]}
                    value={showHistorical}
                    setValue={setShowHistorical}
                    disabled={liveData.length === 0}
                  />
                  {liveData.length === 0 && (
                    <div style={{ marginLeft: 15, color: "black" }}>
                      No live storms at this time.
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  height: "45%",
                  width: "100%",
                }}
              >
                <HurricaneMap small />
              </div>
            </div>
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE} updateIntervalBasis={180} />
        {hurricaneData && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "45%",
                width: "100%",
              }}
            >
              <HurricaneMap />
            </div>
            <div style={{ width: "100%", padding: 10 }}>
              <div
                style={{
                  width: layout.widgetWidth - 50,
                }}
              >
                {StormSelector()}
              </div>
              <div>{getHurricaneInfo()}</div>
              <div style={{ position: "absolute", bottom: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextToggle
                    options={["Live", "Historical"]}
                    value={showHistorical}
                    setValue={setShowHistorical}
                    disabled={liveData.length === 0}
                  />
                  {liveData.length === 0 && (
                    <div style={{ marginLeft: 15 }}>
                      No live storms at this time.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default HurricanesTropicalStorms;
