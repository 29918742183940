import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import {
  FaBuilding,
  FaCity,
  FaHome,
  FaMapPin,
  FaMarker,
  FaPen,
  FaQuestion,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import PlacesSearch from "components/PlacesSearch";
import { MapContainer, TileLayer } from "react-leaflet";
import Cookies from "js-cookie";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import { FaBuildingColumns, FaMapLocation } from "react-icons/fa6";
import { RiCloseFill } from "react-icons/ri";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

export const UserPlaces = () => {
  const [places, setPlaces] = useState([]);
  const [mapCenter, setMapCenter] = useState([
    Cookies.get("latitude"),
    Cookies.get("longitude"),
  ]);
  const [zoom, setZoom] = useState(11);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const [workingTitle, setWorkingTitle] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [placeToDelete, setPlaceToDelete] = useState(null);
  const [filterInput, setFilterInput] = useState("");

  const theme = useContext(ColorContext);
  const perms = usePerms();

  function typeMap(type) {
    switch (type) {
      case "locality":
        return "City";
      case "administrative_area_level_1":
        return "State";
      case "administrative_area_level_2":
        return "County";
      case "premise":
        return "Building";
      case "country":
        return "Country";
      case "geocode":
        return "Location";
      default:
        return "Building";
    }
  }

  function typeIcon(type) {
    switch (type) {
      case "DefaultLocationUserHome":
        return <FaHome size={20} />;
      case "locality":
        return <FaCity size={20} />;
      case "administrative_area_level_1":
        return <FaBuildingColumns size={20} />;
      case "administrative_area_level_2":
        return <FaBuildingColumns size={20} />;
      case "premise":
        return <FaBuilding size={20} />;
      case "geocode":
        return <FaMapLocation size={20} />;
      default:
        return <FaBuilding size={20} />;
    }
  }

  useEffect(() => {
    (async () => {
      const response = await api.getUserPlacesData();
      const placeList = response.data.places || [];
      setPlaces(placeList);
      if (placeList.length > 0) {
        setMapCenter([placeList[0].latitude, placeList[0].longitude]);
        setSelectedPlace(placeList[Cookies.get("selectedLocationIndex")]);
      }
    })();
  }, []);
  const addLocation = (location) => {
    setMapCenter([location.latitude, location.longitude]);
    setSelectedLocation(location);
  };

  const saveLocation = (location) => {
    if (places.find((place) => place.place_id === location.place_id)) return;
    let tempPlaces = [...places];
    tempPlaces.push(location);
    tempPlaces.sort((a, b) => {
      if (a.DefaultLocationUserHome === true) return -1;
      if (b.DefaultLocationUserHome === true) return 1;
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });
    setPlaces([...tempPlaces]);
    updateUserPlacesData([...tempPlaces]);
  };

  const editLocationTitle = (location, title) => {
    let tempPlaces = [...places];
    let index = tempPlaces.indexOf(location);
    if (index > -1) {
      tempPlaces[index].title = title;
    }
    setPlaces([...tempPlaces]);
    updateUserPlacesData([...tempPlaces]);
  };

  const changeHomeLocation = (location) => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((place) => {
      place.DefaultLocationUserHome = false;
      return place;
    });
    let index = tempPlaces.indexOf(location);
    if (index > -1) {
      tempPlaces[index].DefaultLocationUserHome = true;
    }
    tempPlaces = tempPlaces.sort((a, b) => {
      if (a.DefaultLocationUserHome === true) return -1;
      if (b.DefaultLocationUserHome === true) return 1;
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });

    setEditIndex(-1);
    setPlaces([...tempPlaces]);
    updateUserPlacesData([...tempPlaces]);
    setSelectedPlace([...tempPlaces][0]);
    Cookies.set("selectedLocationIndex", 0);
  };

  async function updateUserPlacesData(places) {
    const res = await api.updateUserPlacesData(places);
  }

  useEffect(() => {
    if (selectedPlace) {
      setMapCenter([selectedPlace.latitude, selectedPlace.longitude]);
      setSelectedLocation(selectedPlace);
      Cookies.set("city", selectedPlace.city);
      Cookies.set("state", selectedPlace.state_long);
      Cookies.set("latitude", selectedPlace.latitude);
      Cookies.set("longitude", selectedPlace.longitude);
    }
  }, [selectedPlace]);

  const removeLocation = (location) => {
    if (places.length === 1) return;
    let tempPlaces = [...places];
    let index = tempPlaces.indexOf(location);
    if (index > -1) {
      tempPlaces.splice(index, 1);
    }
    setPlaces([...tempPlaces]);
    updateUserPlacesData([...tempPlaces]);
    setSelectedPlace([...tempPlaces][0]);
    Cookies.set("selectedLocationIndex", 0);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {showConfirmDelete && (
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                padding: 20,
                background: "#F2F2F2",
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <div style={{ fontWeight: "bold", color: "black" }}>
                Are you sure you want to delete this place?
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    padding: 10,
                    background: theme.background,
                    borderRadius: 10,
                    cursor: "pointer",
                    width: "30%",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setShowConfirmDelete(false);
                  }}
                >
                  No
                </div>
                <div
                  style={{
                    padding: 10,
                    background: theme.primaryHighlight,
                    borderRadius: 10,
                    cursor: "pointer",
                    width: "30%",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    removeLocation(placeToDelete);
                    setShowConfirmDelete(false);
                  }}
                >
                  Yes
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          height: "100%",
        }}
      >
        {perms.testPermission([8, 9, 10, 11, 12, 13], ["Create-EditPlaces"]) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.base,
              padding: 10,
              height: "max-content",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: 20 }}>
                Add a Place
              </div>
              <PlacesSearch
                noBack
                noAutoAdd
                places={places}
                setSearching={() => {}}
                addLocation={addLocation}
                setMapCenter={setMapCenter}
                quickSave
              />
            </div>
            <div style={{ width: 400, height: 300, background: "grey" }}>
              <MapContainer
                center={mapCenter}
                zoom={11}
                style={{ height: "100%", width: "100%" }}
              >
                <LocationSetter center={mapCenter} zoom={zoom} />
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
              </MapContainer>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {selectedLocation && (
                <div style={{ padding: 5, marginTop: 5 }}>
                  {selectedLocation.place_data?.formatted_address}
                </div>
              )}
              <div
                onClick={() => {
                  saveLocation(selectedLocation);
                }}
                style={{
                  padding: 10,
                  marginTop: 10,
                  background: theme.primaryHighlight,
                  borderRadius: 5,
                  width: "max-content",
                  cursor: "pointer",
                }}
              >
                Save
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontWeight: "bold", fontSize: 20 }}>Saved Places</div>
            <input
              style={{
                background: theme.primaryHighlight,
                padding: 10,
                marginBottom: 10,
                borderRadius: 10,
              }}
              placeholder="Search..."
              value={filterInput}
              onChange={(e) => {
                setFilterInput(e.target.value);
              }}
            ></input>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.5fr 1fr 1fr .75fr",
              gap: 10,
              padding: 15,
              borderBottom: "1px solid grey",
            }}
          >
            <div>Title</div>
            <div>Type</div>
            <div>Location</div>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            {places.map((place, index) => {
              if (
                JSON.stringify(place)
                  .toLowerCase()
                  .includes(filterInput.toLowerCase())
              )
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1.5fr 1fr 1fr .75fr",
                      gap: 10,
                      padding: 15,
                      borderBottom: "1px solid grey",
                      background:
                        selectedPlace === place
                          ? theme.primaryHighlight
                          : index % 2 === 0
                          ? "#1f2528"
                          : "#2C373B",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setSelectedPlace(place);
                      Cookies.set("selectedLocationIndex", index);
                    }}
                  >
                    {!place.title && editIndex !== index && (
                      <div>
                        {place.city}, {place.state_short}
                      </div>
                    )}
                    {place.title && editIndex !== index && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            border: "2px solid white",
                            background: theme.primaryShadow,
                            borderRadius: 30,
                            width: 30,
                            height: 30,
                            alignContent: "center",
                            marginRight: 20,
                          }}
                        >
                          <div style={{ paddingLeft: 5, paddingTop: 2 }}>
                            {typeIcon(
                              place.DefaultLocationUserHome
                                ? "DefaultLocationUserHome"
                                : place.type
                            ) || (
                              <div style={{ marginLeft: 2 }}>
                                <FaQuestion />
                              </div>
                            )}
                          </div>
                        </div>
                        <div style={{ width: "75%" }}>{place.title}</div>
                      </div>
                    )}
                    {editIndex === index && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          editLocationTitle(place, workingTitle);
                          setEditIndex(-1);
                        }}
                      >
                        <input
                          value={workingTitle}
                          onChange={(e) => {
                            setWorkingTitle(e.target.value);
                          }}
                          style={{
                            border: "1px solid white",
                            width: "100%",
                            borderRadius: 5,
                          }}
                        ></input>
                      </form>
                    )}
                    <div>{typeMap(place.type) || "?"}</div>
                    <div>{place.place_data?.formatted_address}</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: 20,
                        cursor: "pointer",
                        gap: 10,
                        alignItems: "center",
                      }}
                    >
                      {perms.testPermission(
                        [8, 9, 10, 11, 12, 13],
                        ["Create-EditPlaces"]
                      ) &&
                        editIndex === index && (
                          <div
                            onClick={() => {
                              editLocationTitle(place, workingTitle);
                              setEditIndex(-1);
                            }}
                          >
                            <FaSave />
                          </div>
                        )}
                      {perms.testPermission(
                        [8, 9, 10, 11, 12, 13],
                        ["Create-EditPlaces"]
                      ) &&
                        editIndex !== index && (
                          <div
                            onClick={() => {
                              setEditIndex(index);
                              setWorkingTitle(place.title || "");
                            }}
                          >
                            <FaPen />
                          </div>
                        )}
                      {!place.DefaultLocationUserHome &&
                        perms.testPermission(
                          [8, 9, 10, 11, 12, 13],
                          ["HomeLocationEditable"]
                        ) && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              changeHomeLocation(place);
                            }}
                          >
                            <FaHome />
                          </div>
                        )}
                      {perms.testPermission(
                        [8, 9, 10, 11, 12, 13],
                        ["Create-EditPlaces"]
                      ) &&
                        !place.DefaultLocationUserHome && (
                          <div
                            onClick={() => {
                              setPlaceToDelete(place);
                              setShowConfirmDelete(true);
                            }}
                          >
                            <RiCloseFill size={25} />
                          </div>
                        )}
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
