import React, { useState, useEffect, useLayoutEffect, useContext } from "react";

// moment.js
import moment from "moment";

// API
import * as api from "../../apis/FusionAPI";

import { Slider } from "antd";

import MapComponent from "./components/MapComponent";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import AlertIcons from "components/AlertIcons";

import LocationSearch from "./components/LocationSearch";
import Cookies from "js-cookie";
import {
  borderRadius,
  display,
  height,
  maxHeight,
  minHeight,
  width,
} from "@mui/system";
import { usePerms } from "hooks/usePerms";
import { useNavigate } from "react-router-dom";
import WidgetMap from "components/WidgetMap";
import CreatePolygon from "./components/CreatePolygon";
import { Marker, Polygon } from "react-leaflet";
import { ColorContext, LayoutSizeContext } from "App";
import { layouts } from "chart.js";
import MoveablePoints from "./components/MoveablePoints";

////////////
// Render //
////////////
export default function AlertCreator({
  onSubmitCallback = null,
  timeInputMode = "setTime",
  data = null,
  setData = null,
  widget,
  dimensions,
  sidebarOpen,
  preview = false,
}) {
  const layoutSize = useContext(LayoutSizeContext);

  const theme = useContext(ColorContext);
  /////////////////////
  // State Variables //
  /////////////////////

  // Leaflet Specific States
  const [mapCenter, setMapCenter] = useState([
    Cookies.get("latitude"),
    Cookies.get("longitude"),
  ]);
  const [bounds, setMapBounds] = useState([
    [Cookies.get("latitude"), Cookies.get("longitude")],
    [Cookies.get("latitude"), Cookies.get("longitude")],
  ]);
  const [zoom, setZoom] = useState(13);
  const [getMapCenter, setGetMapCenter] = useState([0, 0]);
  const [jurisdictions, setJurisdictions] = useState(null);

  const perms = usePerms();
  const navigate = useNavigate();

  // Data states
  const [alertData, setAlertData] = useState({
    coordinates: [],
    city: "",
    state: "",
    issuedTime: timeInputMode === "setTime" ? new Date().toISOString() : "",
    expirationTime: timeInputMode === "setTime" ? new Date().toISOString() : "",
    severity: 100,
    headline: "",
    description: "",
    type: "",
    isInternalUse: false,
    searchLocation: "",
    foundLocation: "",
    duration: 0,
    mapCenter: "",
  });

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["Create-EditAlerts"]))) {
        navigate("/404");
        return;
      }
    })();

    if (
      data &&
      (data?.coordinates === null ||
        data?.city === null ||
        data?.state === null ||
        data?.issuedTime === null ||
        data?.expirationTime === null ||
        data?.severity === null ||
        data?.headline === null ||
        data?.description === null ||
        data?.type === null ||
        data?.isInternalUse === null ||
        data?.searchLocation === null ||
        data?.foundLocation === null ||
        data?.duration === null ||
        data?.mapCenter === null)
    ) {
      setData({
        coordinates: [],
        city: "",
        state: "",
        issuedTime: timeInputMode === "setTime" ? new Date().toISOString() : "",
        expirationTime:
          timeInputMode === "setTime" ? new Date().toISOString() : "",
        severity: 100,
        headline: "",
        description: "",
        type: "",
        isInternalUse: false,
        searchLocation: "",
        foundLocation: "",
        duration: 0,
        mapCenter: "",
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      const res = await api.getJurisdictions();
      setJurisdictions(res.data.result.filter((f) => f !== null));
    })();
  }, []);

  // Rendered States
  const [validationErr, setValidationErr] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [pendingMsg, setPendingMsg] = useState("");

  // Check screen width
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  //////////////////
  // Side Effects //
  //////////////////

  // Get user location to zoom in to them on map
  useEffect(() => {
    data?.mapCenter
      ? setMapCenter(data.mapCenter)
      : setMapCenter([Cookies.get("latitude"), Cookies.get("longitude")]);

    setZoom(14);
  }, []);

  // useEffect(() => {
  //   setPendingMsg("");
  // }, [successMsg, validationErr]);

  const updateData = (newValue) => {
    let error = false;
    if (data ? !data.type : !alertData.type) {
      setValidationErr("Alert type cannot be blank");
      error = true;
    }
    if (data ? !data.headline : !alertData.headline) {
      setValidationErr("Headline cannot be blank");
      error = true;
    }
    if (data ? !data.description : !alertData.description) {
      setValidationErr("Description cannot be blank");
      error = true;
    }
    if (data ? !data.state : !alertData.state) {
      setValidationErr("State cannot be blank");
      error = true;
    }

    if (!error) {
      setData({ ...data, ...newValue, error: false });
      setValidationErr("");
    } else {
      setData({ ...data, error: true });
    }
  };

  ////////////////////
  // Layout Effects //
  ////////////////////
  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < 1555);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  ///////////////
  // Functions //
  ///////////////
  const removeLastCoordinate = () => {
    // find index matching last inserted
    let coord = data
      ? data.lastInserted[data.lastInserted.length - 1]
      : alertData.lastInserted[alertData.lastInserted.length - 1];

    const newData = {
      coordinates: [],
      lastInserted: [],
    };

    const truncData = (c, index) => {
      if (c.lat !== coord.lat || c.lng !== coord.lng)
        newData.coordinates.push(c);
    };

    const truncLastInserted = (i, index) => {
      if (
        index <
        (data ? data.lastInserted.length : alertData.lastInserted.length) - 1
      )
        newData.lastInserted.push(i);
    };

    data
      ? data.coordinates.forEach(truncData)
      : alertData.coordinates.forEach(truncData);
    data
      ? data.lastInserted.forEach(truncLastInserted)
      : alertData.lastInserted.forEach(truncLastInserted);

    setData
      ? updateData({
          ...data,
          coordinates: newData.coordinates,
          lastInserted: newData.lastInserted,
        })
      : setAlertData((prevData) => ({
          ...prevData,
          coordinates: newData.coordinates,
          lastInserted: newData.lastInserted,
        }));
  };

  const removeAllCoordinates = () => {
    setData
      ? updateData({
          ...data,
          coordinates: [],
          lastInserted: [],
        })
      : setAlertData((prevData) => ({
          ...prevData,
          coordinates: [],
          lastInserted: [],
        }));
  };

  // Handles input fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    setData
      ? updateData({
          ...data,
          [name]: value,
        })
      : setAlertData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
  };

  // Changes funky time string stuff to UTC for db
  const handleTimeString = (e) => {
    const { name, value } = e.target;

    const date = new Date(value);
    const newValue = date.toISOString();

    setData
      ? updateData({
          ...data,
          [name]: newValue,
        })
      : setAlertData((prevData) => ({
          ...prevData,
          [name]: newValue,
        }));
  };

  // Form validator + API call
  const handleSubmit = async () => {
    setPendingMsg("Creating Alert");

    if (data ? data.coordinates.length < 3 : alertData.coordinates.length < 3) {
      setValidationErr("Coordinates must have 3 or more points");
      setPendingMsg("");
      return;
    }
    if (data ? !data.type : !alertData.type) {
      setValidationErr("Alert type cannot be blank");
      setPendingMsg("");
      return;
    }
    if (data ? !data.headline : !alertData.headline) {
      setValidationErr("Headline cannot be blank");
      setPendingMsg("");
      return;
    }
    if (data ? !data.description : !alertData.description) {
      setValidationErr("Description cannot be blank");
      setPendingMsg("");
      return;
    }
    if (data ? !data.state : !alertData.state) {
      setValidationErr("State cannot be blank");
      setPendingMsg("");
      return;
    }

    if (
      data
        ? data.expirationTime <= data.issuedTime
        : alertData.expirationTime <= alertData.issuedTime
    ) {
      setValidationErr("Expiration time cannot be before or at start time");
      setPendingMsg("");
      return;
    }

    setValidationErr("");

    if (!onSubmitCallback) {
      // Further processing or API calls can be done here
      try {
        const res = await api.postAlert(alertData);

        if (res.status === 200) {
          setSuccessMsg("Alert successfully sent!");
          setPendingMsg("");
          setAlertData({
            coordinates: [],
            city: "",
            state: "",
            issuedTime: timeInputMode === "setTime" ? new Date().toISOString() : "",
            expirationTime: timeInputMode === "setTime" ? new Date().toISOString() : "",
            severity: 100,
            headline: "",
            description: "",
            type: "",
            isInternalUse: false,
            searchLocation: "",
            foundLocation: "",
            duration: 0,
            mapCenter: "",
          })
          setTimeout(() => setSuccessMsg(""), 5000);
        } else {
          setValidationErr("Error sending alert");
          setPendingMsg("");
        }
      } catch (error) {
        if (error.response.status === 403) {
          setValidationErr(error.response.data.message);
          setPendingMsg("");
        } else {
          setValidationErr("Error sending alert");
          setPendingMsg("");
        }
      }
    }
  };

  const reset = () => {
    setAlertData({
      coordinates: [],
      city: "",
      state: "",
      issuedTime: timeInputMode === "setTime" ? new Date().toISOString() : "",
      expirationTime:
        timeInputMode === "setTime" ? new Date().toISOString() : "",
      severity: 100,
      headline: "",
      description: "",
      type: "",
      isInternalUse: false,
      searchLocation: "",
      foundLocation: "",
      duration: 0,
      mapCenter: "",
    });

    setSuccessMsg("");
    setValidationErr("");
    setPendingMsg("");
  };

  const styles = {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.base,
      borderRadius: 5,
      height: "100%",
    },
    headerContainer: {
      marginBottom: 10,
      flexDirection: "row",
    },
    validationError: {
      color: "red",
      background: "#000",
      padding: 10,
      borderRadius: 10,
      width: "max-content",
    },
    successMessage: { color: "green" },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      borderTop: "solid grey 1px",
      overflow: "hidden",
    },
    mapContainer: { margin: 0, width: "calc(100% - 700px)", height: "100%" },
    formStyle: {
      display: "flex",
      flexDirection: "column",
      height: widget ? "100%" : "100%",
      margin: widget ? 0 : 5,
      flex: 1,
      padding: 10,
      overflow: "auto",
    },
    input: {
      padding: 10,
      display: "flex",
      borderRadius: 5,
      backgroundColor: theme.primary,
      margin: widget ? 0 : 5,
      justifyContent: "center",
    },
    button: {
      padding: 10,
      margin: widget ? 0 : 5,
      borderRadius: 5,
    },
  };

  if (perms.testPermission([8, 9, 10, 11, 12, 13], ["Create-EditAlerts"])) {
    if (!widget)
      return (
        <div style={styles.mainContainer} className="alertCreator">
          <div style={styles.headerContainer}>
            <h3>Alert Creator</h3>
            {validationErr && (
              <p style={styles.validationError}>{validationErr}</p>
            )}
            {successMsg && <p style={styles.successMessage}>{successMsg}</p>}
          </div>
          <div
            style={{
              ...styles.contentContainer,
              display: "flex",
              flexDirection: "row",
              paddingTop: 10,
            }}
          >
            <div style={styles.mapContainer} id="map-container">
              <MoveablePoints
                mapCenter={mapCenter}
                zoom={zoom}
                alertData={
                  !data
                    ? alertData
                    : data.coordinates
                    ? data
                    : { coordinates: [] }
                }
                setAlertData={setData ? updateData : setAlertData}
                isMobile={isMobile}
                bounds={bounds}
                setGetMapCenter={setGetMapCenter}
                jurisdictions={jurisdictions}
              />
            </div>
            <div style={styles.formStyle}>
              <LocationSearch
                alertData={data ? data : alertData}
                setMapCenter={setMapCenter}
                setMapBounds={setMapBounds}
                mapCenter={mapCenter}
                styles={styles}
                handleChange={handleChange}
                searchLocation={
                  data ? data.searchLocation : alertData.searchLocation
                }
                getMapCenter={getMapCenter}
                setAlertData={setData ? updateData : setAlertData}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ ...styles.input, alignItems: "center" }}>
                  <div style={{ marginRight: 5 }}>
                    <AlertIcons type={data ? data.type : alertData.type} />
                  </div>
                  <select
                    style={{ backgroundColor: theme.primary }}
                    name="type"
                    value={data ? data.type : alertData.type}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Alert Type
                    </option>
                    {eventTypes.map((event) => (
                      <option key={event} value={event}>
                        {event}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    ...styles.input,
                    width: "100%",
                    flexDirection: "column",
                    padding: 5,
                    paddingRight: 25,
                  }}
                >
                  <div
                    style={{
                      marginBottom: -10,
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: alertColorScale(
                          data ? data.severity : alertData.severity
                        ),
                        padding: 5,
                        borderRadius: 5,
                        marginBottom: 5,
                        width: "max-content",
                      }}
                    >
                      Severity:{" "}
                      {getSeverityLevel(
                        data ? data.severity : alertData.severity
                      )}
                    </p>
                  </div>
                  <Slider
                    style={{ width: "100%" }}
                    name="severity"
                    min={0}
                    max={100}
                    step={1}
                    value={100 - (data ? data.severity : alertData.severity)}
                    onChange={(event) => {
                      setData
                        ? updateData(
                            data
                              ? { ...data, severity: 100 - event }
                              : { ...alertData, severity: 100 - event }
                          )
                        : setAlertData(
                            data
                              ? { ...data, severity: 100 - event }
                              : { ...alertData, severity: 100 - event }
                          );
                    }}
                  />
                </div>
              </div>
              <input
                style={styles.input}
                name="headline"
                value={data ? data.headline : alertData.headline}
                onChange={handleChange}
                placeholder="Headline"
              />
              <textarea
                style={{ ...styles.input, height: 300 }}
                name="description"
                value={data ? data.description : alertData.description}
                onChange={handleChange}
                placeholder="Description"
              />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  style={styles.input}
                  name="state"
                  value={data ? data.state : alertData.state}
                  onChange={handleChange}
                  placeholder="State"
                />
                <input
                  style={styles.input}
                  name="city"
                  value={data ? data.city : alertData.city}
                  onChange={handleChange}
                  placeholder="City"
                />
              </div>
              {timeInputMode === "setTime" && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ ...styles.input, flexDirection: "column" }}>
                    <p style={{ color: theme.fontColor }}>Start Time</p>
                    <input
                      type="datetime-local"
                      name="issuedTime"
                      value={moment(
                        data ? data.issuedTime : alertData.issuedTime
                      ).format("YYYY-MM-DDTHH:mm")}
                      onChange={handleTimeString}
                      placeholder="Start Time"
                    />
                  </div>
                  <div style={{ ...styles.input, flexDirection: "column" }}>
                    <p style={{ color: theme.fontColor }}>Expiration Time</p>
                    <input
                      type="datetime-local"
                      name="expirationTime"
                      value={moment(
                        data ? data.expirationTime : alertData.expirationTime
                      ).format("YYYY-MM-DDTHH:mm")}
                      onChange={handleTimeString}
                      placeholder="Expiration Time"
                    />
                  </div>
                </div>
              )}
              {timeInputMode === "duration" && (
                <div style={{ ...styles.input, flexDirection: "column" }}>
                  <div>
                    Duration:{" "}
                    {(data ? data.duration : alertData.duration) === 0
                      ? "Indefinite"
                      : (data ? data.duration : alertData.duration) +
                        " hour" +
                        ((data ? data.duration : alertData.duration) === 1
                          ? ""
                          : "s")}{" "}
                  </div>
                  <Slider
                    style={{ width: "100%" }}
                    name="duration"
                    min={0}
                    max={24}
                    step={1}
                    value={data ? data.duration : alertData.duration}
                    onChange={(event) => {
                      setData
                        ? updateData(
                            data
                              ? { ...data, duration: event }
                              : { ...alertData, duration: event }
                          )
                        : setAlertData(
                            data
                              ? { ...data, duration: event }
                              : { ...alertData, duration: event }
                          );
                    }}
                  />
                </div>
              )}

              <div style={{ margin: 5 }}>
                <button
                  style={{ ...styles.button, backgroundColor: "orange" }}
                  onClick={removeLastCoordinate}
                >
                  Remove Last Coordinate
                </button>
                <button
                  style={{ ...styles.button, backgroundColor: "red" }}
                  onClick={removeAllCoordinates}
                >
                  Remove ALL Coordinates
                </button>
                {!onSubmitCallback && (
                  <button
                    style={{ ...styles.button, backgroundColor: "green" }}
                    onClick={handleSubmit}
                  >
                    Create Alert
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      );

    if (widget) {
      if (dimensions[0] == 1 && dimensions[1] == 2)
        // current
        return (
          <div style={styles.mainContainer} className="alertCreator">
            <div
              style={{
                ...styles.contentContainer,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              {/* Map */}
              <div
                style={{
                  zIndex: 1000,
                  position: "absolute",
                  width: "100%",
                  top: 70,
                }}
              >
                <LocationSearch
                  alertData={data ? data : alertData}
                  setMapCenter={setMapCenter}
                  setMapBounds={setMapBounds}
                  mapCenter={mapCenter}
                  styles={styles}
                  handleChange={handleChange}
                  searchLocation={
                    data ? data.searchLocation : alertData.searchLocation
                  }
                  getMapCenter={getMapCenter}
                  setAlertData={setData ? updateData : setAlertData}
                  widget
                />
              </div>
              <div
                style={{
                  ...styles.mapContainer,
                  width: layoutSize.widgetWidth - 10,

                  height: layoutSize.widgetHeight - 60,
                  borderBottom: "solid grey 1px",
                }}
                id="map-container"
              >
                <MoveablePoints
                  mapCenter={mapCenter}
                  zoom={zoom}
                  alertData={
                    !data
                      ? alertData
                      : data.coordinates
                      ? data
                      : { coordinates: [] }
                  }
                  setAlertData={setData ? updateData : setAlertData}
                  isMobile={isMobile}
                  bounds={bounds}
                  setGetMapCenter={setGetMapCenter}
                  jurisdictions={jurisdictions}
                />
                {/* <WidgetMap
                  sidebarOpen={sidebarOpen}
                  mapCenter={mapCenter}
                  zoom={zoom}
                  preview={preview}
                >
                  <CreatePolygon
                    alertData={alertData}
                    setAlertData={setAlertData}
                  />
                  {alertData.coordinates.map((coord, index) => (
                    <Marker key={index} position={coord} />
                  ))}
                  {alertData.coordinates.length > 2 && (
                    <Polygon
                      pathOptions={{
                        color: alertColorScale(alertData.severity),
                      }}
                      positions={alertData.coordinates}
                    />
                  )}
                  {jurisdictions &&
                    jurisdictions.map((jurisdiction) => (
                      <Polygon
                        positions={JSON.parse(
                          jurisdiction.original_coordinates
                        )[0].map(([longitude, latitude]) => [
                          latitude,
                          longitude,
                        ])}
                        color="purple"
                        fillOpacity={0}
                      />
                    ))}
                </WidgetMap> */}
              </div>

              {/* Content */}
              <div
                style={{
                  ...styles.formStyle,
                  height: layoutSize.widgetHeight - 400,
                  backgroudColor: "blue",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {/* Alert Type */}
                  <div
                    style={{
                      ...styles.input,
                      alignItems: "center",
                      // width: "40%",
                      padding: 10,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <select
                      style={{ backgroundColor: theme.primary, width: "100%" }}
                      name="type"
                      value={data ? data.type : alertData.type}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Alert Type
                      </option>
                      {eventTypes.map((event) => (
                        <option key={event} value={event}>
                          {event}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Start Time */}
                  <div
                    style={{
                      ...styles.input,
                      flexDirection: "column",
                      marginBottom: 10,
                    }}
                  >
                    <p style={{ color: theme.fontColor }}>Start Time</p>
                    <input
                      type="datetime-local"
                      name="issuedTime"
                      value={moment(
                        data ? data.issuedTime : alertData.issuedTime
                      ).format("YYYY-MM-DDTHH:mm")}
                      onChange={handleTimeString}
                      placeholder="Start Time"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {/* Severity */}
                  <div
                    style={{
                      ...styles.input,
                      flex: 1,
                      flexDirection: "column",
                      padding: 5,
                      paddingRight: 5,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: -10,
                      }}
                    >
                      <p
                        style={{
                          backgroundColor: alertColorScale(
                            data ? data.severity : alertData.severity
                          ),
                          padding: 5,
                          borderRadius: 5,
                          marginBottom: 5,
                          // width: "max-content",
                        }}
                      >
                        Severity:{" "}
                        {getSeverityLevel(
                          data ? data.severity : alertData.severity
                        )}
                      </p>
                    </div>
                    <Slider
                      style={{ width: "100%" }}
                      name="severity"
                      min={0}
                      max={100}
                      step={1}
                      value={100 - (data ? data.severity : alertData.severity)}
                      onChange={(event) => {
                        setData
                          ? updateData(
                              data
                                ? { ...data, severity: 100 - event }
                                : { ...alertData, severity: 100 - event }
                            )
                          : setAlertData(
                              data
                                ? { ...data, severity: 100 - event }
                                : { ...alertData, severity: 100 - event }
                            );
                      }}
                    />
                  </div>

                  {/* End Time */}
                  <div
                    style={{
                      ...styles.input,
                      flexDirection: "column",
                      marginBottom: 10,
                    }}
                  >
                    <p style={{ color: theme.fontColor }}>Expiration Time</p>
                    <input
                      type="datetime-local"
                      name="expirationTime"
                      value={moment(
                        data ? data.expirationTime : alertData.expirationTime
                      ).format("YYYY-MM-DDTHH:mm")}
                      onChange={handleTimeString}
                      placeholder="Expiration Time"
                    />
                  </div>
                </div>

                {/* Headline / Description */}
                <input
                  style={{
                    ...styles.input,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: "solid grey 1px",
                  }}
                  name="headline"
                  value={data ? data.headline : alertData.headline}
                  onChange={handleChange}
                  placeholder="Headline"
                />
                <textarea
                  style={{
                    ...styles.input,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    height: 100,
                    resize: "none",
                    marginBottom: 10,
                    minHeight: 40,
                  }}
                  name="description"
                  value={data ? data.description : alertData.description}
                  onChange={handleChange}
                  placeholder="Description"
                />

                {/* Buttons */}
                <button
                  style={{
                    width: "100%",
                    marginBottom: 15,
                    padding: 10,
                  }}
                  onClick={reset}
                >
                  Reset
                </button>
                <div
                  style={{
                    margin: 5,
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 15,
                  }}
                >
                  <button
                    style={{
                      ...styles.button,
                      backgroundColor: "orange",
                      flex: 0.5,
                      marginRight: 10,
                    }}
                    onClick={removeLastCoordinate}
                  >
                    Undo Last Coordinate
                  </button>
                  <button
                    style={{
                      ...styles.button,
                      backgroundColor: "red",
                      flex: 0.5,
                    }}
                    onClick={removeAllCoordinates}
                  >
                    Remove Coordinates
                  </button>
                  <div style={{ flex: 1 }} />
                  {!onSubmitCallback && (
                    <button
                      style={{
                        ...styles.button,
                        backgroundColor: theme.primaryHighlight,
                        flex: 0.5,
                      }}
                      onClick={handleSubmit}
                    >
                      Create Alert
                    </button>
                  )}
                </div>

                {/* Error Message */}
                {validationErr && (
                  <div style={{ alignSelf: "center", color: "red" }}>
                    {validationErr}
                  </div>
                )}

                {/* Success Message */}
                {successMsg && (
                  <div style={{ alignSelf: "center", color: "green" }}>
                    {successMsg}
                  </div>
                )}

                {/* Pending Message */}
                {pendingMsg && (
                  <div style={{ alignSelf: "center", color: theme.fontColor }}>
                    {pendingMsg}
                  </div>
                )}
              </div>
            </div>
          </div>
        );

      if (dimensions[0] == 2 && dimensions[1] == 1)
        return (
          <div style={styles.mainContainer} className="alertCreator">
            <div
              style={{
                ...styles.contentContainer,
                flexDirection: "row",
                borderTop: "solid grey 1px",
                paddingTop: 8,
              }}
            >
              {/* Map */}
              <div
                style={{
                  ...styles.mapContainer,
                  marginRight: 15,
                  width: layoutSize.widgetWidth,
                }}
                id="map-container"
              >
                <MoveablePoints
                  mapCenter={mapCenter}
                  zoom={zoom}
                  alertData={
                    !data
                      ? alertData
                      : data.coordinates
                      ? data
                      : { coordinates: [] }
                  }
                  setAlertData={setData ? updateData : setAlertData}
                  isMobile={isMobile}
                  bounds={bounds}
                  setGetMapCenter={setGetMapCenter}
                  jurisdictions={jurisdictions}
                />
                {/* <WidgetMap
                  sidebarOpen={sidebarOpen}
                  mapCenter={mapCenter}
                  zoom={zoom}
                  preview={preview}
                >
                  <CreatePolygon
                    alertData={alertData}
                    setAlertData={setAlertData}
                  />
                  {alertData.coordinates.map((coord, index) => (
                    <Marker key={index} position={coord} />
                  ))}
                  {alertData.coordinates.length > 2 && (
                    <Polygon
                      pathOptions={{
                        color: alertColorScale(alertData.severity),
                      }}
                      positions={alertData.coordinates}
                    />
                  )}
                  {jurisdictions &&
                    jurisdictions.map((jurisdiction) => (
                      <Polygon
                        positions={JSON.parse(
                          jurisdiction.original_coordinates
                        )[0].map(([longitude, latitude]) => [
                          latitude,
                          longitude,
                        ])}
                        color="purple"
                        fillOpacity={0}
                      />
                    ))}
                </WidgetMap> */}
                <div
                  style={{
                    zIndex: 1000,
                    position: "absolute",
                    width: "50%",
                    top: 70,
                  }}
                >
                  <LocationSearch
                    alertData={data ? data : alertData}
                    setMapCenter={setMapCenter}
                    setMapBounds={setMapBounds}
                    mapCenter={mapCenter}
                    styles={styles}
                    handleChange={handleChange}
                    searchLocation={
                      data ? data.searchLocation : alertData.searchLocation
                    }
                    getMapCenter={getMapCenter}
                    setAlertData={setData ? updateData : setAlertData}
                    widget
                  />
                </div>
              </div>

              {/* Content */}
              <div
                style={{
                  ...styles.formStyle,
                  height: layoutSize.widgetHeight - 80,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {/* Alert Type */}
                  <div
                    style={{
                      ...styles.input,
                      alignItems: "center",
                      // width: "40%",
                      padding: 10,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <select
                      style={{ backgroundColor: theme.primary, width: "100%" }}
                      name="type"
                      value={data ? data.type : alertData.type}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Alert Type
                      </option>
                      {eventTypes.map((event) => (
                        <option key={event} value={event}>
                          {event}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Start Time */}
                  <div
                    style={{
                      ...styles.input,
                      flexDirection: "column",
                      marginBottom: 10,
                    }}
                  >
                    <p style={{ color: theme.fontColor }}>Start Time</p>
                    <input
                      type="datetime-local"
                      name="issuedTime"
                      value={moment(
                        data ? data.issuedTime : alertData.issuedTime
                      ).format("YYYY-MM-DDTHH:mm")}
                      onChange={handleTimeString}
                      placeholder="Start Time"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {/* Severity */}
                  <div
                    style={{
                      ...styles.input,
                      flex: 1,
                      flexDirection: "column",
                      padding: 5,
                      paddingRight: 5,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: -10,
                      }}
                    >
                      <p
                        style={{
                          backgroundColor: alertColorScale(
                            data ? data.severity : alertData.severity
                          ),
                          padding: 5,
                          borderRadius: 5,
                          marginBottom: 5,
                          // width: "max-content",
                        }}
                      >
                        Severity:{" "}
                        {getSeverityLevel(
                          data ? data.severity : alertData.severity
                        )}
                      </p>
                    </div>
                    <Slider
                      style={{ width: "100%" }}
                      name="severity"
                      min={0}
                      max={100}
                      step={1}
                      value={100 - (data ? data.severity : alertData.severity)}
                      onChange={(event) => {
                        setData
                          ? updateData(
                              data
                                ? { ...data, severity: 100 - event }
                                : { ...alertData, severity: 100 - event }
                            )
                          : setAlertData(
                              data
                                ? { ...data, severity: 100 - event }
                                : { ...alertData, severity: 100 - event }
                            );
                      }}
                    />
                  </div>

                  {/* End Time */}
                  <div
                    style={{
                      ...styles.input,
                      flexDirection: "column",
                      marginBottom: 10,
                    }}
                  >
                    <p style={{ color: theme.fontColor }}>Expiration Time</p>
                    <input
                      type="datetime-local"
                      name="expirationTime"
                      value={moment(
                        data ? data.expirationTime : alertData.expirationTime
                      ).format("YYYY-MM-DDTHH:mm")}
                      onChange={handleTimeString}
                      placeholder="Expiration Time"
                    />
                  </div>
                </div>

                {/* Headline / Description */}
                <input
                  style={{
                    ...styles.input,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: "solid grey 1px",
                  }}
                  name="headline"
                  value={data ? data.headline : alertData.headline}
                  onChange={handleChange}
                  placeholder="Headline"
                />
                <textarea
                  style={{
                    ...styles.input,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    height: 100,
                    resize: "none",
                    marginBottom: 10,
                    minHeight: 40,
                  }}
                  name="description"
                  value={data ? data.description : alertData.description}
                  onChange={handleChange}
                  placeholder="Description"
                />

                {/* Buttons */}
                <button
                  style={{
                    width: "100%",
                    marginBottom: 15,
                    padding: 10,
                  }}
                  onClick={reset}
                >
                  Reset
                </button>
                <div
                  style={{
                    margin: 5,
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 15,
                  }}
                >
                  <button
                    style={{
                      ...styles.button,
                      backgroundColor: "orange",
                      flex: 0.5,
                      marginRight: 10,
                    }}
                    onClick={removeLastCoordinate}
                  >
                    Undo Last Coordinate
                  </button>
                  <button
                    style={{
                      ...styles.button,
                      backgroundColor: "red",
                      flex: 0.5,
                    }}
                    onClick={removeAllCoordinates}
                  >
                    Remove Coordinates
                  </button>
                  <div style={{ flex: 1 }} />
                  {!onSubmitCallback && (
                    <button
                      style={{
                        ...styles.button,
                        backgroundColor: theme.primaryHighlight,
                        flex: 0.5,
                      }}
                      onClick={handleSubmit}
                    >
                      Create Alert
                    </button>
                  )}
                </div>

                {/* Error Message */}
                {validationErr && (
                  <div style={{ alignSelf: "center", color: "red" }}>
                    {validationErr}
                  </div>
                )}

                {/* Success Message */}
                {successMsg && (
                  <div style={{ alignSelf: "center", color: "green" }}>
                    {successMsg}
                  </div>
                )}

                {/* Pending Message */}
                {pendingMsg && (
                  <div style={{ alignSelf: "center", color: theme.fontColor }}>
                    {pendingMsg}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
    }
  }
}

const severities = ["Extreme", "Severe", "Moderate", "Minor", "Unknown"];

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const eventTypes = [
  "911 Telephone Outage Emergency",
  "Administrative Message",
  "Air Quality Alert",
  "Air Stagnation Advisory",
  "Arroyo And Small Stream Flood Advisory",
  "Ashfall Advisory",
  "Ashfall Warning",
  "Avalanche Advisory",
  "Avalanche Warning",
  "Avalanche Watch",
  "Beach Hazards Statement",
  "Blizzard Warning",
  "Blizzard Watch",
  "Blowing Dust Advisory",
  "Blowing Dust Warning",
  "Brisk Wind Advisory",
  "Child Abduction Emergency",
  "Civil Danger Warning",
  "Civil Emergency Message",
  "Coastal Flood Advisory",
  "Coastal Flood Statement",
  "Coastal Flood Warning",
  "Coastal Flood Watch",
  "Dense Fog Advisory",
  "Dense Smoke Advisory",
  "Dust Advisory",
  "Dust Storm Warning",
  "Earthquake Warning",
  "Evacuation - Immediate",
  "Excessive Heat Warning",
  "Excessive Heat Watch",
  "Extreme Cold Warning",
  "Extreme Cold Watch",
  "Extreme Fire Danger",
  "Extreme Wind Warning",
  "Fire Warning",
  "Fire Weather Watch",
  "Flash Flood Statement",
  "Flash Flood Warning",
  "Flash Flood Watch",
  "Flood Advisory",
  "Flood Statement",
  "Flood Warning",
  "Flood Watch",
  "Freeze Warning",
  "Freeze Watch",
  "Freezing Fog Advisory",
  "Freezing Rain Advisory",
  "Freezing Spray Advisory",
  "Frost Advisory",
  "Gale Warning",
  "Gale Watch",
  "Hard Freeze Warning",
  "Hard Freeze Watch",
  "Hazardous Materials Warning",
  "Hazardous Seas Warning",
  "Hazardous Seas Watch",
  "Hazardous Weather Outlook",
  "Heat Advisory",
  "Heavy Freezing Spray Warning",
  "Heavy Freezing Spray Watch",
  "High Surf Advisory",
  "High Surf Warning",
  "High Wind Warning",
  "High Wind Watch",
  "Hurricane Force Wind Warning",
  "Hurricane Force Wind Watch",
  "Hurricane Local Statement",
  "Hurricane Warning",
  "Hurricane Watch",
  "Hydrologic Advisory",
  "Hydrologic Outlook",
  "Ice Storm Warning",
  "Lake Effect Snow Advisory",
  "Lake Effect Snow Warning",
  "Lake Effect Snow Watch",
  "Lake Wind Advisory",
  "Lakeshore Flood Advisory",
  "Lakeshore Flood Statement",
  "Lakeshore Flood Warning",
  "Lakeshore Flood Watch",
  "Law Enforcement Warning",
  "Local Area Emergency",
  "Low Water Advisory",
  "Marine Weather Statement",
  "Nuclear Power Plant Warning",
  "Radiological Hazard Warning",
  "Red Flag Warning",
  "Rip Current Statement",
  "Severe Thunderstorm Warning",
  "Severe Thunderstorm Watch",
  "Severe Weather Statement",
  "Shelter In Place Warning",
  "Short Term Forecast",
  "Small Craft Advisory",
  "Small Craft Advisory For Hazardous Seas",
  "Small Craft Advisory For Rough Bar",
  "Small Craft Advisory For Winds",
  "Small Stream Flood Advisory",
  "Snow Squall Warning",
  "Special Marine Warning",
  "Special Weather Statement",
  "Storm Surge Warning",
  "Storm Surge Watch",
  "Storm Warning",
  "Storm Watch",
  "Test",
  "Tornado Warning",
  "Tornado Watch",
  "Tropical Depression Local Statement",
  "Tropical Storm Local Statement",
  "Tropical Storm Warning",
  "Tropical Storm Watch",
  "Tsunami Advisory",
  "Tsunami Warning",
  "Tsunami Watch",
  "Typhoon Local Statement",
  "Typhoon Warning",
  "Typhoon Watch",
  "Urban And Small Stream Flood Advisory",
  "Volcano Warning",
  "Wind Advisory",
  "Wind Chill Advisory",
  "Wind Chill Warning",
  "Wind Chill Watch",
  "Winter Storm Warning",
  "Winter Storm Watch",
  "Winter Weather Advisory",
];
