import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import PasswordInput from "components/UI/PasswordInput";
import { usePerms } from "hooks/usePerms";
import { ColorContext, DeviceContext } from "App";
import ConfirmPopup from "components/UI/ConfirmPopup";

const NewConnection = ({
  setShowNewConnection,
  setIntegrations,
  integrations,
  initialData,
  setInitialData,
}) => {
  const deviceContext = useContext(DeviceContext);
  const theme = useContext(ColorContext);
  const [data, setData] = useState({
    system_type: "",
    system_name: "",
    user_email: "",
    password: "",
    status: 1,
    thumbnail: "",
    name: "",
    alias: "",
    uuid: "",
    integration_type: "system",
  });

  const [password, setPassword] = useState("");
  const [currentTypes, setCurrentTypes] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const perms = usePerms();

  useEffect(() => {
    setData({
      ...data,
      password: password,
    });
  }, [password]);

  const removeConnection = async () => {
    if (initialData) {
      const res = await api.fusionDeleteIntegration(initialData);
      if (res.status === 200) {
        setShowNewConnection(false);
        setInitialData(null);
        const res = await api.fusionGetIntegrations();
        setIntegrations(res.data.result);
      }
    }
  };

  const createConnection = async () => {
    if (
      data.user_email.indexOf("@") === -1 ||
      data.user_email.indexOf(".") === -1
    )
      return alert("Invalid email");
    if (
      !data.system_name ||
      !data.user_email ||
      !data.password ||
      !data.system_type
    )
      return alert("Please fill all fields");

    let res;
    if (initialData) {
      res = await api.fusionUpdateIntegration(data);
    } else {
      res = await api.fusionAddIntegration(data);
    }
    if (res.status === 200) {
      const res = await api.fusionGetIntegrations();
      setIntegrations(res.data.result);
      setShowNewConnection(false);
      setShowDelete(false);
      setInitialData(null);
    } else {
      alert("Error creating connection");
    }
  };

  useEffect(() => {
    if (initialData) {
      setData(initialData);
      setPassword(initialData.password);
    } else {
      let types = [];
      integrations.forEach((integration) => {
        if (!types.includes(integration.system_type)) {
          types.push(integration.system_type);
        }
      });
      setCurrentTypes(types);
    }
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        color: theme.fontColor,
        background: theme.base,
        padding: 20,
        borderRadius: 10,
        border: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
          {initialData ? "Edit System" : "New System"}
        </div>
        <div
          style={{ cursor: "pointer", fontWeight: "bold", fontSize: "1.5rem" }}
          onClick={() => setShowNewConnection(false)}
        >
          X
        </div>
      </div>

      <form style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div
          style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}
        >
          <label style={{ fontSize: "1rem" }}>System Type</label>
          <select
            value={data.system_type}
            onChange={(e) => setData({ ...data, system_type: e.target.value })}
            style={{
              background: initialData ? "#454545" : theme.primary,
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              fontSize: "1rem",
            }}
            disabled={initialData}
          >
            <option value="">Select System Type</option>
            {Object.keys(deviceContext.deviceTypes).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          {data.system_type &&
            currentTypes.includes(data.system_type) &&
            !initialData && <div>This system type already exists.</div>}

          {data.system_type && !currentTypes.includes(data.system_type) && (
            <>
              <label style={{ fontSize: "1rem", marginTop: 10 }}>
                System Name
              </label>
              <input
                type="text"
                style={{
                  background: theme.primary,
                  height: 40,
                  borderRadius: 10,
                  paddingLeft: 15,
                  fontSize: "1rem",
                }}
                value={data.system_name}
                onChange={(e) =>
                  setData({
                    ...data,
                    system_name: e.target.value,
                  })
                }
              />

              <label style={{ fontSize: "1rem", marginTop: 10 }}>Email</label>
              <input
                type="text"
                style={{
                  background: theme.primary,
                  height: 40,
                  borderRadius: 10,
                  paddingLeft: 15,
                  fontSize: "1rem",
                }}
                value={data.user_email}
                onChange={(e) =>
                  setData({
                    ...data,
                    user_email: e.target.value,
                  })
                }
              />

              <label style={{ fontSize: "1rem", marginTop: 10 }}>
                Password
              </label>
              <PasswordInput value={password} callback={setPassword} />

              <div
                style={{
                  backgroundColor: data.status ? "#01B574" : "",
                  border: data.status ? "0px" : "1px solid #ccc",
                  borderRadius: 8,
                  padding: 5,
                  width: "fit-content",
                  textAlign: "center",
                  boxSizing: "border-box",
                  cursor: "pointer",
                  marginTop: 20,
                  fontSize: "1rem",
                }}
                onClick={() =>
                  setData({
                    ...data,
                    status: !data.status,
                  })
                }
              >
                {data.status ? "Online" : "Offline"}
              </div>
            </>
          )}
        </div>
      </form>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        {" "}
        {initialData ? (
          <div
            style={{
              cursor: "pointer",
              backgroundColor: "red",
              fontWeight: "bold",
              width: "fit-content",
              padding: "15px 30px",
              borderRadius: 10,
              fontSize: "1rem",
            }}
            onClick={() => setShowDelete(true)}
          >
            Remove
          </div>
        ) : (
          <div></div>
        )}
        <div
          style={{
            cursor: "pointer",
            backgroundColor: theme.primaryHighlight,
            fontWeight: "bold",
            width: "fit-content",
            padding: "15px 30px",
            borderRadius: 10,
            fontSize: "1rem",
          }}
          onClick={() => createConnection()}
        >
          {initialData ? "Save" : "Create"}
        </div>
      </div>

      {showDelete && (
        <ConfirmPopup
          open={showDelete}
          setOpen={setShowDelete}
          message={
            <>
              <h2>Warning: Permanent Removal of System</h2>
              You are about to remove an entire system. This action is permanent
              and cannot be undone. Removing this system will also delete all
              devices connected to it. Please confirm you wish to proceed with
              this action.
            </>
          }
          options={["Cancel", "Remove"]}
          callbacks={[
            () => {
              setShowDelete(false);
            },
            () => {
              removeConnection();
            },
          ]}
          closeAction={() => {
            setShowDelete(false);
          }}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: "red", color: theme.fontColor },
          ]}
        />
      )}
      <style jsx>{`
        @media (max-width: 768px) {
          div[style*="flex-direction: column"] {
            flex-direction: column;
          }
          div[style*="flex-direction: row"] {
            flex-direction: column;
          }
          select,
          input {
            width: 100%;
          }
          div[style*="flex-direction: row"] > div {
            margin-top: 10px;
          }
          div[style*="justify-content: flex-end"] {
            justify-content: center;
          }
        }

        @media (max-width: 480px) {
          div[style*="font-size: 1.5rem"] {
            font-size: 1.2rem;
          }
          div[style*="font-size: 1rem"] {
            font-size: 0.9rem;
          }
          input,
          select {
            font-size: 0.9rem;
            height: 35px;
          }
          div[style*="padding: 15px 30px"] {
            padding: 10px 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default NewConnection;
