import { ColorContext } from "App";
import React, { useContext, useEffect, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import * as api from "apis/FusionAPI";

const AudioPreview = ({ type, tone, roadStatus, setRoadStatus }) => {
  const theme = useContext(ColorContext);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioValid, setAudioValid] = useState(false);


   useEffect(() => {
    async function getAudio() {
      try {
        if (!tone) {
          return;
        }
        if (!tone.name){
          return;
        }
        const url = await api.getAudioFile(`${tone.name}`);
        const newAudio = new Audio(url);
        newAudio.onended = () => setIsPlaying(false);
        newAudio.onerror = (e) => {
          console.error("Audio playback error:", e);
          setAudioValid(false);
          setAudio(null);
        };
        setAudio(newAudio);
        setAudioValid(true);
      } catch (error) {
        console.error("Error getting audio file:", error);
        setAudioValid(false);
        setAudio(null);
      }
    }
    getAudio();
  }, [tone]);


  const playTone = () => {
    try {
      if (isPlaying && audio) {
        audio.pause();
        setIsPlaying(false);
      } else if (audio) {
        audio
          .play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
          });
      }
    } catch (error) {
      console.error("Error handling audio playback:", error);
    }
  };

  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        width: "100%",
        margin: 10,
        background: theme.primaryShadow,
        userSelect: "none",
        cursor: audioValid ? "pointer" : "not-allowed",
        filter: audioValid ? "none" : "grayscale(100%)",
      }}
      onClick={() => {
        playTone();
      }}
    >
      <div
        style={{
          opacity: audioValid ? 1 : 0.5,
          pointerEvents: audioValid ? "all" : "none",
          paddingLeft: 10,
          paddingRight: 10,
          display: "flex",
          gap: 10,
          alignItems: "center",
          fontStyle: "italic",
        }}
      >
        {isPlaying ? (
          <>
            <FaPause />
          </>
        ) : (
          <FaPlay />
        )}
        Preview
      </div>
    </div>
  );
};

export default AudioPreview;
