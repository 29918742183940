import React, { useContext, useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Polyline,
  Polygon,
} from "react-leaflet";
import L from "leaflet";

import aware from "assets/aware.png";
import awarecam from "assets/awarecam.png";
import aware_alarm_camera_cell from "assets/awareicons/aware_alarm_camera_cell.png";
import aware_alarm_camera_sat from "assets/awareicons/aware_alarm_camera_sat.png";
import aware_alarm_nocamera from "assets/awareicons/aware_alarm_nocamera.png";
import aware_camera_cell from "assets/awareicons/aware_camera_cell.png";
import aware_camera_sat from "assets/awareicons/aware_camera_sat.png";
import aware_offline_camera_cell from "assets/awareicons/aware_offline_camera_cell.png";
import aware_offline_camera_sat from "assets/awareicons/aware_offline_camera_sat.png";
import aware_offline_nocamera from "assets/awareicons/aware_offline_nocamera.png";

import * as api from "apis/FusionAPI";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import Cookies from "js-cookie";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";

import { ColorContext, DeviceContext, LayoutSizeContext } from "App";
import { borderRadius } from "@mui/system";
import { extractDateTimeFromUrl } from "utils/dateUtils";
import DeviceMapDataView from "./DeviceMapDataView";
import { ConnectionsContext } from "./Connections";
import MANUAL_ROAD_CLOSURE_MBTN from "assets/map buttons/RescAlert Devices Manual Road Closures - Map Button.png";
import TECHNOLOGY_BRIDGE_MBTN from "assets/map buttons/RescAlert Devices Technology Bridge - Map Button.png";
import TECHNOLOGY_BRIDGE_SIREN_MBTN from "assets/map buttons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) - Map button.png";
import AWARE_MBTN from "assets/map buttons/AWARE Flood - Map button.png";

import { getDynamicDeviceIcon, getRescAlertStatus } from "utils/iconUtils";
import { usePerms } from "hooks/usePerms";
import BoundsSetter from "components/AlertCreator/components/BoundsSetter";

const useCloseAllPopups = () => {
  const map = useMap();

  const closeAllPopups = () => {
    map.eachLayer((layer) => {
      if (layer instanceof L.Popup) {
        layer.remove();
      }
    });
  };

  return closeAllPopups;
};

const ClosePopups = ({ closePopups }) => {
  const closeAllPopups = useCloseAllPopups();

  useEffect(() => {
    closeAllPopups();
  }, [closePopups]);

  return null;
};

export default function DevicesMap({
  selectedDevice,
  nodeList,
  setSelectedDevice,
  setPageIndex,
  setShowCurrentDevice,
  longMap,
  tallMap,
  widget,
  sidebarOpen,
  preview = false,
  deviceTypeToFilter,
  customDataOverride,
}) {
  const layout = useContext(LayoutSizeContext);

  const perms = usePerms();

  const theme = useContext(ColorContext);
  const [userPosition, setUserPosition] = useState(null);
  const [closePopups, setClosePopups] = useState(false);
  const [jurisdictions, setJurisdictions] = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const [initialGrab, setInitialGrab] = useState(false);
  const [zoom, setZoom] = useState(16);

  useEffect(() => {
    if (Cookies.get("latitude") && Cookies.get("longitude")) {
      setUserPosition([Cookies.get("latitude"), Cookies.get("longitude")]);
      return;
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserPosition([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, []);

  const [mapCenter, setMapCenter] = useState([
    Cookies.get("latitude"),
    Cookies.get("longitude"),
  ]);

  useEffect(() => {
    if (
      selectedDevice !== null &&
      nodeList[selectedDevice] &&
      nodeList[selectedDevice].location.lat &&
      nodeList[selectedDevice].location.lng
    ) {
      setMapCenter([
        nodeList[selectedDevice].location.lat,
        nodeList[selectedDevice].location.lng,
      ]);
    }
    if (nodeList[selectedDevice].device_type === "Manual Road Closure") {
      let c = nodeList[selectedDevice].location;
      // c = JSON.parse(c);
      setMapCenter([c.lat, c.lng]);

      setShowCurrentDevice(false);
    }
    if (
      nodeList[selectedDevice].device_type ===
      "Technology Bridge_Flashing Lights"
    ) {
      let c = nodeList[selectedDevice].location;
      // c = JSON.parse(c);
      setMapCenter([c.lat, c.lng]);

      setShowCurrentDevice(false);
    }
    if (
      nodeList[selectedDevice].device_type ===
      "Technology Bridge_Siren/Giant Voice"
    ) {
      let c = nodeList[selectedDevice].location;
      // c = JSON.parse(c);
      setMapCenter([c.lat, c.lng]);

      setShowCurrentDevice(false);
    }
  }, [selectedDevice]);

  useEffect(() => {
    (async () => {
      let res = await api.getJurisdictions();
      res.data.result = res.data.result.filter((f) => f !== null);
      setTimeout(() => {
        setJurisdictions(res.data.result);
        const oc = JSON.parse(res.data.result[0].original_coordinates);
        //if i am tenant a, use index 0

        //   v
        // a 0 , 1 , 2
        //       v
        // b 0 , 1 , 2
        //           v
        // c 0 , 1 , 2

        //find the left, right, up, and downmost coords
        let left = 180;
        let right = -180;
        let up = -90;
        let down = 90;
        oc[0].forEach(([longitude, latitude]) => {
          if (longitude < left) left = longitude;
          if (longitude > right) right = longitude;
          if (latitude > up) up = latitude;
          if (latitude < down) down = latitude;
        });
        //pad the bounds
        const padding = 0.07;
        left -= padding;
        right += padding;
        up -= padding;
        down += padding;
        const upperLeft = [up, left];
        const lowerRight = [down, right];
        const center = [(up + down) / 2, (left + right) / 2];
        setMapCenter(center);
        setMapBounds([upperLeft, lowerRight]);
        setInitialGrab(true);
      }, 1000);
    })();
  }, []);
  const connectionsContext = useContext(ConnectionsContext);
  const deviceContext = useContext(DeviceContext);

  let currentSystemType = null;

  if (!widget) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 999,
            marginLeft: 60,
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              background: theme.primaryHighlight,
              borderRadius: 100,
              padding: 10,
              width: 300,
              height: 25,
            }}
          >
            <select
              style={{
                background: theme.primaryHighlight,
                color: theme.fontColor,
                padding: 0,
                borderRadius: 5,
                border: "none",
                cursor: "pointer",
                width: "100%",
              }}
              onChange={(e) => {
                setClosePopups(!closePopups);
                setSelectedDevice(e.target.value);
                if (nodeList[e.target.value].system_type === "AWARE") {
                  setPageIndex(nodeList[e.target.value].data.length);
                  setShowCurrentDevice(true);
                } else {
                  setShowCurrentDevice(false);
                }
                setClosePopups(!closePopups);
              }}
              value={selectedDevice}
            >
              <option
                value=""
                disabled
                style={{
                  color: "#fff",
                  backgroundColor: theme.primaryShadow,
                  textAlign: "center",
                }}
              >
                Select Device
              </option>
              {nodeList
                .sort((a, b) => {
                  const systemTypeComparison = a.system_type.localeCompare(
                    b.system_type
                  );
                  if (systemTypeComparison !== 0) {
                    return systemTypeComparison;
                  }
                  const deviceTypeComparison = a.device_type.localeCompare(
                    b.device_type
                  );
                  if (deviceTypeComparison !== 0) {
                    return deviceTypeComparison;
                  }
                  return parseInt(a.node_id, 10) - parseInt(b.node_id, 10);
                })
                .map((device, i) => {
                  let device_type = device.device_type;

                  if (device_type.includes("_")) {
                    let str = "";
                    const subStrs = device_type.split("_");
                    for (let i = 0; i < subStrs.length; i++) {
                      if (i == 0) str += subStrs[i];
                      else str += " (" + subStrs[i] + ")";
                    }

                    device_type = str;
                  }

                  const showHeader = device.device_type !== currentSystemType;
                  currentSystemType = device.device_type;
                  if (connectionsContext.canViewDeviceType(device))
                    return (
                      <React.Fragment key={device.node_id}>
                        {showHeader && (
                          <option
                            value=""
                            disabled
                            style={{
                              fontWeight: "bold",
                              color: "#fff",
                              backgroundColor: theme.primaryShadow,
                              textAlign: "center",
                            }}
                          >
                            {device.system_type} - {device_type}
                          </option>
                        )}
                        <option value={i}>{device.alias}</option>
                      </React.Fragment>
                    );
                })}
            </select>
          </div>
          <div
            style={{
              zIndex: 998,
              display: "flex",
              flexDirection: "row",
              gap: 10,
              marginLeft: 10,
              position: "relative",
              top: 0,
              right: 0,
            }}
          >
            {perms.testPermission(
              [8, 9, 10, 11, 12, 13],
              ["ViewAWAREFloodConnections"]
            ) && (
              <button
                style={{
                  backgroundColor: connectionsContext.visibleDeviceTypes[
                    "Flood"
                  ]
                    ? theme.primaryHighlight
                    : "#555",
                  borderRadius: 10,
                  padding: 5,
                  border: "none",
                  cursor: "pointer",
                  width: "fit-content",
                  margin: "5px 0",
                }}
                onClick={() => {
                  connectionsContext.setVisibleDeviceTypes({
                    ...connectionsContext.visibleDeviceTypes,
                    Flood: !connectionsContext.visibleDeviceTypes["Flood"],
                  });
                }}
              >
                <img
                  src={AWARE_MBTN}
                  alt="aware"
                  style={{ width: 25, height: 25 }}
                ></img>
              </button>
            )}
            {perms.testPermission(
              [8, 9, 10, 11, 12, 13],
              ["ViewRescAlertDevicesManualRoadClosureConnections"]
            ) && (
              <button
                style={{
                  backgroundColor: connectionsContext.visibleDeviceTypes[
                    "Manual Road Closure"
                  ]
                    ? theme.primaryHighlight
                    : "#555",
                  borderRadius: 10,
                  padding: 5,
                  border: "none",
                  cursor: "pointer",
                  width: "fit-content",
                  margin: "5px 0",
                }}
                onClick={() => {
                  connectionsContext.setVisibleDeviceTypes({
                    ...connectionsContext.visibleDeviceTypes,
                    "Manual Road Closure":
                      !connectionsContext.visibleDeviceTypes[
                        "Manual Road Closure"
                      ],
                  });
                }}
              >
                <img
                  src={MANUAL_ROAD_CLOSURE_MBTN}
                  alt="MANUAL_ROAD_CLOSURE"
                  style={{ width: 25, height: 25 }}
                ></img>
              </button>
            )}
            {perms.testPermission(
              [8, 9, 10, 11, 12, 13],
              ["ViewRescAlertDevicesTechnologyBridge_FlashingLightsConnections"]
            ) && (
              <button
                style={{
                  backgroundColor: connectionsContext.visibleDeviceTypes[
                    "Technology Bridge_Flashing Lights"
                  ]
                    ? theme.primaryHighlight
                    : "#555",
                  borderRadius: 10,
                  padding: 5,
                  border: "none",
                  cursor: "pointer",
                  width: "fit-content",
                  margin: "5px 0",
                }}
                onClick={() => {
                  connectionsContext.setVisibleDeviceTypes({
                    ...connectionsContext.visibleDeviceTypes,
                    "Technology Bridge_Flashing Lights":
                      !connectionsContext.visibleDeviceTypes[
                        "Technology Bridge_Flashing Lights"
                      ],
                  });
                }}
              >
                <img
                  src={TECHNOLOGY_BRIDGE_MBTN}
                  alt="TECHNOLOGY_BRIDGE"
                  style={{ width: 25, height: 25 }}
                ></img>
              </button>
            )}
            {
            perms.testPermission(
              [8, 9, 10, 11, 12, 13],
              [
                "ViewRescAlertDevicesTechnologyBridge_Siren-GiantVoiceConnections",
              ]
            ) && 
            (
              <button
                style={{
                  backgroundColor: connectionsContext.visibleDeviceTypes[
                    "Technology Bridge_Siren/Giant Voice"
                  ]
                    ? theme.primaryHighlight
                    : "#555",
                  borderRadius: 10,
                  padding: 5,
                  border: "none",
                  cursor: "pointer",
                  width: "fit-content",
                  margin: "5px 0",
                }}
                onClick={() => {
                  connectionsContext.setVisibleDeviceTypes({
                    ...connectionsContext.visibleDeviceTypes,
                    "Technology Bridge_Siren/Giant Voice":
                      !connectionsContext.visibleDeviceTypes[
                        "Technology Bridge_Siren/Giant Voice"
                      ],
                  });
                }}
              >
                <img
                  src={TECHNOLOGY_BRIDGE_SIREN_MBTN}
                  alt="TECHNOLOGY_BRIDGE_Siren"
                  style={{ width: 25, height: 25 }}
                ></img>
              </button>
            )}
          </div>
        </div>
        {userPosition && nodeList && (
          <div style={{ height: "100%", width: "100%" }}>
            <FullScreenMap
              key={"fullScreenMap"}
              mapCenter={mapCenter}
              zoom={zoom}
              style={{ borderRadius: 10 }}
            >
              <LocationSetter center={mapCenter} zoom={zoom} />
              <BoundsSetter bounds={mapBounds} />
              {jurisdictions &&
                jurisdictions.map((jurisdiction) => (
                  <Polygon
                    positions={JSON.parse(
                      jurisdiction.original_coordinates
                    )[0].map(([longitude, latitude]) => [latitude, longitude])}
                    color="purple"
                    fillOpacity={0}
                  />
                ))}
              <ClosePopups closePopups={closePopups} />

              <Marker position={userPosition}>
                <Popup>Your location</Popup>
              </Marker>
              {nodeList
                .filter((device) =>
                  connectionsContext.canViewDeviceType(device)
                )
                .map((device, index) => {
                  if (device.location && device.system_type === "AWARE")
                    return (
                      <Marker
                        key={device.node_id}
                        position={[device?.location.lat, device?.location.lng]}
                        icon={getDynamicDeviceIcon(
                          customDataOverride || device,
                          "icon"
                        )}
                      >
                        <Popup>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <DeviceMapDataView device={device} />
                            <div
                              style={{
                                padding: "5px 10px",
                                backgroundColor: theme.primary,
                                color: theme.fontColor,
                                borderRadius: 5,
                                textAlign: "center",
                                cursor: "pointer",
                                width: "fit-content",
                                margin: "auto",
                              }}
                              onClick={() => {
                                setMapCenter([
                                  device.location.lat,
                                  device.location.lng,
                                ]);
                                setSelectedDevice(index);
                                setPageIndex(nodeList[index].data.length);
                                setShowCurrentDevice(true);
                              }}
                            >
                              View device
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    );
                })}
              {nodeList
                .filter((device) =>
                  connectionsContext.canViewDeviceType(device)
                )
                .filter(
                  (f) =>
                    f.system_type === "RescAlert Devices" &&
                    f.device_type === "Manual Road Closure"
                )
                .map((device, index) => {
                  const c = device.location;
                  // const c = JSON.parse(device.location);
                  const lines = device.coordinates;
                  if (c)
                    return (
                      <>
                        {lines.map((line, lineIndex) => (
                          <>
                            {line.points.length > 1 && (
                              <Polyline
                                key={lineIndex + "" + Date.now()}
                                positions={line.points}
                                color={
                                  getRescAlertStatus(
                                    customDataOverride || device
                                  ) === "Open"
                                    ? "lime"
                                    : "red"
                                }
                              />
                            )}
                            {line.points.map((point, pointIndex) => {
                              return (
                                <Marker
                                  key={
                                    lineIndex +
                                    "" +
                                    pointIndex +
                                    "" +
                                    Date.now()
                                  }
                                  position={point}
                                  draggable={false}
                                  // popup
                                  // eventHandlers={{
                                  //   popupopen: () => setPointPopupOpen(true),
                                  //   popupclose: () => setPointPopupOpen(false),
                                  //   dragend: (e) => {
                                  //     const newPosition = e.target.getLatLng();

                                  //     if (
                                  //       !newPosition ||
                                  //       typeof newPosition.lat ===
                                  //         "undefined" ||
                                  //       typeof newPosition.lng === "undefined"
                                  //     )
                                  //       return;

                                  //     const newLines = [...lines];
                                  //     newLines[lineIndex].points[pointIndex] =
                                  //       newPosition;
                                  //     updateLines(() => newLines);
                                  //   },
                                  // }}
                                  icon={
                                    pointIndex == 0 ||
                                    pointIndex == line.points.length - 1
                                      ? getDynamicDeviceIcon(
                                          customDataOverride || device,
                                          "icon"
                                        )
                                      : new L.DivIcon({
                                          className: "custom-x-icon",
                                          html: `<div>
                      <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                      <div style="color: ${
                        getRescAlertStatus(customDataOverride || device) ===
                        "Open"
                          ? "lime"
                          : "red"
                      }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                      </div>`,
                                          iconSize: [20, 20],
                                          iconAnchor: [10, 16],
                                        })
                                  }
                                  interactive
                                >
                                  {/* <Popup
                                    closeOnClick
                                    closeButton={false}
                                    autoClose
                                  >
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        removePointFromLine(
                                          lineIndex,
                                          pointIndex
                                        )
                                      }
                                    >
                                      Delete
                                    </div>
                                  </Popup> */}
                                </Marker>
                              );
                            })}
                          </>
                        ))}
                      </>
                    );
                })}
              {nodeList
                .filter((device) =>
                  connectionsContext.canViewDeviceType(device)
                )
                .filter(
                  (f) =>
                    f.system_type === "RescAlert Devices" &&
                    f.device_type === "Technology Bridge_Flashing Lights"
                )
                .map((device, index) => {
                  const c = device.location;
                  // const c = JSON.parse(device.location);
                  const lines = device.coordinates;
                  if (c)
                    return (
                      <>
                        {lines.map((line, lineIndex) => (
                          <>
                            {line.points.length > 1 && (
                              <Polyline
                                key={lineIndex + "" + Date.now()}
                                positions={line.points}
                                color={
                                  getRescAlertStatus(
                                    customDataOverride || device
                                  ) === "Open"
                                    ? "lime"
                                    : "red"
                                }
                              />
                            )}
                            {line.points.map((point, pointIndex) => {
                              return (
                                <Marker
                                  key={
                                    lineIndex +
                                    "" +
                                    pointIndex +
                                    "" +
                                    Date.now()
                                  }
                                  position={point}
                                  draggable={false}
                                  // popup
                                  // eventHandlers={{
                                  //   popupopen: () => setPointPopupOpen(true),
                                  //   popupclose: () => setPointPopupOpen(false),
                                  //   dragend: (e) => {
                                  //     const newPosition = e.target.getLatLng();

                                  //     if (
                                  //       !newPosition ||
                                  //       typeof newPosition.lat ===
                                  //         "undefined" ||
                                  //       typeof newPosition.lng === "undefined"
                                  //     )
                                  //       return;

                                  //     const newLines = [...lines];
                                  //     newLines[lineIndex].points[pointIndex] =
                                  //       newPosition;
                                  //     updateLines(() => newLines);
                                  //   },
                                  // }}
                                  icon={
                                    pointIndex == 0 ||
                                    pointIndex == line.points.length - 1
                                      ? getDynamicDeviceIcon(
                                          customDataOverride || device,
                                          "icon"
                                        )
                                      : new L.DivIcon({
                                          className: "custom-x-icon",
                                          html: `<div>
                        <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                        <div style="color: ${
                          getRescAlertStatus(customDataOverride || device) ===
                          "Open"
                            ? "lime"
                            : "red"
                        }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                        </div>`,
                                          iconSize: [20, 20],
                                          iconAnchor: [10, 16],
                                        })
                                  }
                                  interactive
                                >
                                  {/* <Popup
                                      closeOnClick
                                      closeButton={false}
                                      autoClose
                                    >
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          removePointFromLine(
                                            lineIndex,
                                            pointIndex
                                          )
                                        }
                                      >
                                        Delete
                                      </div>
                                    </Popup> */}
                                </Marker>
                              );
                            })}
                          </>
                        ))}
                      </>
                    );
                })}{nodeList
                  .filter((device) =>
                    connectionsContext.canViewDeviceType(device)
                  )
                  .filter(
                    (f) =>
                      f.system_type === "RescAlert Devices" &&
                      f.device_type === "Technology Bridge_Siren/Giant Voice"
                  )
                  .map((device, index) => {
                    const c = device.location;
                    // const c = JSON.parse(device.location);
                    const lines = device.coordinates;
                    if (c)
                      return (
                        <>
                          {lines.map((line, lineIndex) => (
                            <>
                              {line.points.length > 1 && (
                                <Polyline
                                  key={lineIndex + "" + Date.now()}
                                  positions={line.points}
                                  color={
                                    getRescAlertStatus(
                                      customDataOverride || device
                                    ) === "Idle"
                                      ? "lime"
                                      : "red"
                                  }
                                />
                              )}
                              {line.points.map((point, pointIndex) => {
                                return (
                                  <Marker
                                    key={
                                      lineIndex +
                                      "" +
                                      pointIndex +
                                      "" +
                                      Date.now()
                                    }
                                    position={point}
                                    draggable={false}
                                    // popup
                                    // eventHandlers={{
                                    //   popupopen: () => setPointPopupOpen(true),
                                    //   popupclose: () => setPointPopupOpen(false),
                                    //   dragend: (e) => {
                                    //     const newPosition = e.target.getLatLng();
  
                                    //     if (
                                    //       !newPosition ||
                                    //       typeof newPosition.lat ===
                                    //         "undefined" ||
                                    //       typeof newPosition.lng === "undefined"
                                    //     )
                                    //       return;
  
                                    //     const newLines = [...lines];
                                    //     newLines[lineIndex].points[pointIndex] =
                                    //       newPosition;
                                    //     updateLines(() => newLines);
                                    //   },
                                    // }}
                                    icon={
                                      pointIndex == 0 ||
                                      pointIndex == line.points.length - 1
                                        ? getDynamicDeviceIcon(
                                            customDataOverride || device,
                                            "icon"
                                          )
                                        : new L.DivIcon({
                                            className: "custom-x-icon",
                                            html: `<div>
                          <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                          <div style="color: ${
                            getRescAlertStatus(customDataOverride || device) ===
                            "Idle"
                              ? "lime"
                              : "red"
                          }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                          </div>`,
                                            iconSize: [20, 20],
                                            iconAnchor: [10, 16],
                                          })
                                    }
                                    interactive
                                  >
                                    {/* <Popup
                                        closeOnClick
                                        closeButton={false}
                                        autoClose
                                      >
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            removePointFromLine(
                                              lineIndex,
                                              pointIndex
                                            )
                                          }
                                        >
                                          Delete
                                        </div>
                                      </Popup> */}
                                  </Marker>
                                );
                              })}
                            </>
                          ))}
                        </>
                      );
                  })}
            </FullScreenMap>
          </div>
        )}
      </div>
    );
  }

  if (widget) {
    return (
      <div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              zIndex: 998,
              background: theme.primaryHighlight,
              position: "absolute",
              top: 85,
              left: 70,
              padding: 10,
              borderRadius: 100,
              width: layout.widgetWidth - 160,
            }}
          >
            <select
              style={{
                background: theme.primaryHighlight,
                color: theme.fontColor,
                padding: 0,
                borderRadius: 5,
                border: "none",
                cursor: "pointer",
                width: "100%",
              }}
              onChange={(e) => {
                setClosePopups(!closePopups);
                setSelectedDevice(e.target.value);
                if (nodeList[e.target.value].data)
                  setPageIndex(nodeList[e.target.value].data.length);
                if (nodeList[e.target.value].data) setShowCurrentDevice(true);
                setClosePopups(!closePopups);
              }}
              value={selectedDevice}
            >
              <option value="" disabled>
                Select Device
              </option>
              {nodeList
                .sort((a, b) => {
                  const systemTypeComparison = a.system_type.localeCompare(
                    b.system_type
                  );
                  if (systemTypeComparison !== 0) {
                    return systemTypeComparison;
                  }
                  const deviceTypeComparison = a.device_type.localeCompare(
                    b.device_type
                  );
                  if (deviceTypeComparison !== 0) {
                    return deviceTypeComparison;
                  }
                  return parseInt(a.node_id, 10) - parseInt(b.node_id, 10);
                })
                .map((device, i) => {
                  let type = "";
                  if (deviceTypeToFilter === "flood") {
                    type = "Flood";
                  }
                  if (deviceTypeToFilter === "manualRoadClosure") {
                    type = "Manual Road Closure";
                  }
                  if (deviceTypeToFilter === "technologyBridge") {
                    type = "Technology Bridge_Flashing Lights";
                  }
                  if (deviceTypeToFilter === "technologyBridgeSiren") {
                    type = "Technology Bridge_Siren/Giant Voice";
                  }
                  if (device.device_type === type)
                    return (
                      <option key={device.node_id} value={i}>
                        {device.alias}
                      </option>
                    );
                })}
            </select>
          </div>
        </div>

        {userPosition && nodeList && (
          <div style={{ position: "relative" }}>
            <WidgetMap
              sidebarOpen={sidebarOpen}
              key={"widgetMap"}
              mapCenter={mapCenter}
              zoom={zoom}
              longMap={longMap}
              tallMap={tallMap}
              preview={preview}
            >
              <LocationSetter center={mapCenter} zoom={16} />
              <ClosePopups closePopups={closePopups} />

              <Marker position={userPosition}>
                <Popup>Your location</Popup>
              </Marker>
              <BoundsSetter bounds={mapBounds} />
              {jurisdictions &&
                jurisdictions.map((jurisdiction) => (
                  <Polygon
                    positions={JSON.parse(
                      jurisdiction.original_coordinates
                    )[0].map(([longitude, latitude]) => [latitude, longitude])}
                    color="purple"
                    fillOpacity={0}
                  />
                ))}
              {/* {nodeList
                .filter(
                  (f) => f.system_type === "AWARE" && f.device_type === "Flood"
                )
                .map((device) => {
                  if (device.location && device.system_type === "AWARE")
                    return (
                      <Marker
                        key={device.node_id}
                        position={[device.location.lat, device.location.lng]}
                        icon={getDynamicDeviceIcon(device, "icon")}
                      >
                        <Popup>
                          <div>
                            <h2>Node ID: {device.node_id}</h2>
                            <p>Status: {device.status}</p>
                            <p>
                              Type: {device.system_type} {device.device_type}
                            </p>
                            {device?.data[0].img_link_url && (
                              <img
                                src={`${device?.data[0].img_link_url}`}
                                alt="Device"
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </Popup>
                      </Marker>
                    );
                })} */}
              {nodeList
                .filter((device) =>
                  connectionsContext.canViewDeviceType(device)
                )
                .filter((f) => {
                  let type = "";
                  if (deviceTypeToFilter === "flood") {
                    type = "Flood";
                  }
                  if (deviceTypeToFilter === "manualRoadClosure") {
                    type = "Manual Road Closure";
                  }
                  if (deviceTypeToFilter === "technologyBridge") {
                    type = "Technology Bridge_Flashing Lights";
                  }
                  if (deviceTypeToFilter === "technologyBridgeSiren") {
                    type = "Technology Bridge_Siren/Giant Voice";
                  }
                  return f.device_type === type;
                })
                .map((device, index) => {
                  if (device.location && device.system_type === "AWARE")
                    return (
                      <Marker
                        key={device.node_id}
                        position={[device?.location.lat, device?.location.lng]}
                        icon={getDynamicDeviceIcon(
                          customDataOverride || device,
                          "icon"
                        )}
                      >
                        <Popup>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <DeviceMapDataView device={device} />
                            <div
                              style={{
                                padding: "5px 10px",
                                backgroundColor: theme.primary,
                                color: theme.fontColor,
                                borderRadius: 5,
                                textAlign: "center",
                                cursor: "pointer",
                                width: "fit-content",
                                margin: "auto",
                              }}
                              onClick={() => {
                                setMapCenter([
                                  device.location.lat,
                                  device.location.lng,
                                ]);
                                setSelectedDevice(index);
                                setPageIndex(nodeList[index].data.length);
                                setShowCurrentDevice(true);
                              }}
                            >
                              View device
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    );
                })}
              {nodeList
                .filter((device) =>
                  connectionsContext.canViewDeviceType(device)
                )
                .filter((f) => {
                  let type = "";
                  if (deviceTypeToFilter === "flood") {
                    type = "Flood";
                  }
                  if (deviceTypeToFilter === "manualRoadClosure") {
                    type = "Manual Road Closure";
                  }
                  if (deviceTypeToFilter === "technologyBridge") {
                    type = "Technology Bridge_Flashing Lights";
                  }
                  if (deviceTypeToFilter === "technologyBridgeSiren") {
                    type = "Technology Bridge_Siren/Giant Voice";
                  }
                  return f.device_type === type;
                })

                .filter(
                  (f) =>
                    f.system_type === "RescAlert Devices" &&
                    f.device_type === "Manual Road Closure"
                )
                .map((device, index) => {
                  const c = device.location;
                  // const c = JSON.parse(device.location);
                  const lines = device.coordinates;
                  if (c)
                    return (
                      <>
                        {lines.map((line, lineIndex) => (
                          <>
                            {line.points.length > 1 && (
                              <Polyline
                                key={lineIndex + "" + Date.now()}
                                positions={line.points}
                                color={
                                  getRescAlertStatus(
                                    customDataOverride || device
                                  ) === "Open"
                                    ? "lime"
                                    : "red"
                                }
                              />
                            )}
                            {line.points.map((point, pointIndex) => {
                              return (
                                <Marker
                                  key={
                                    lineIndex +
                                    "" +
                                    pointIndex +
                                    "" +
                                    Date.now()
                                  }
                                  position={point}
                                  draggable={false}
                                  // popup
                                  // eventHandlers={{
                                  //   popupopen: () => setPointPopupOpen(true),
                                  //   popupclose: () => setPointPopupOpen(false),
                                  //   dragend: (e) => {
                                  //     const newPosition = e.target.getLatLng();

                                  //     if (
                                  //       !newPosition ||
                                  //       typeof newPosition.lat ===
                                  //         "undefined" ||
                                  //       typeof newPosition.lng === "undefined"
                                  //     )
                                  //       return;

                                  //     const newLines = [...lines];
                                  //     newLines[lineIndex].points[pointIndex] =
                                  //       newPosition;
                                  //     updateLines(() => newLines);
                                  //   },
                                  // }}
                                  icon={
                                    pointIndex == 0 ||
                                    pointIndex == line.points.length - 1
                                      ? getDynamicDeviceIcon(
                                          customDataOverride || device,
                                          "icon"
                                        )
                                      : new L.DivIcon({
                                          className: "custom-x-icon",
                                          html: `<div>
                      <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                      <div style="color: ${
                        getRescAlertStatus(customDataOverride || device) ===
                        "Open"
                          ? "lime"
                          : "red"
                      }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                      </div>`,
                                          iconSize: [20, 20],
                                          iconAnchor: [10, 16],
                                        })
                                  }
                                  interactive
                                >
                                  {/* <Popup
                                    closeOnClick
                                    closeButton={false}
                                    autoClose
                                  >
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        removePointFromLine(
                                          lineIndex,
                                          pointIndex
                                        )
                                      }
                                    >
                                      Delete
                                    </div>
                                  </Popup> */}
                                </Marker>
                              );
                            })}
                          </>
                        ))}
                      </>
                    );
                })}
              {nodeList
                .filter((device) =>
                  connectionsContext.canViewDeviceType(device)
                )
                .filter((f) => {
                  let type = "";
                  if (deviceTypeToFilter === "flood") {
                    type = "Flood";
                  }
                  if (deviceTypeToFilter === "manualRoadClosure") {
                    type = "Manual Road Closure";
                  }
                  if (deviceTypeToFilter === "technologyBridge") {
                    type = "Technology Bridge_Flashing Lights";
                  }
                  if (deviceTypeToFilter === "technologyBridgeSiren") {
                    type = "Technology Bridge_Siren/Giant Voice";
                  }
                  return f.device_type === type;
                })

                .filter(
                  (f) =>
                    f.system_type === "RescAlert Devices" &&
                    f.device_type === "Technology Bridge_Flashing Lights"
                )
                .map((device, index) => {
                  const c = device.location;
                  // const c = JSON.parse(device.location);
                  const lines = device.coordinates;
                  if (c)
                    return (
                      <>
                        {lines.map((line, lineIndex) => (
                          <>
                            {line.points.length > 1 && (
                              <Polyline
                                key={lineIndex + "" + Date.now()}
                                positions={line.points}
                                color={
                                  getRescAlertStatus(
                                    customDataOverride || device
                                  ) === "Open"
                                    ? "lime"
                                    : "red"
                                }
                              />
                            )}
                            {line.points.map((point, pointIndex) => {
                              return (
                                <Marker
                                  key={
                                    lineIndex +
                                    "" +
                                    pointIndex +
                                    "" +
                                    Date.now()
                                  }
                                  position={point}
                                  draggable={false}
                                  // popup
                                  // eventHandlers={{
                                  //   popupopen: () => setPointPopupOpen(true),
                                  //   popupclose: () => setPointPopupOpen(false),
                                  //   dragend: (e) => {
                                  //     const newPosition = e.target.getLatLng();

                                  //     if (
                                  //       !newPosition ||
                                  //       typeof newPosition.lat ===
                                  //         "undefined" ||
                                  //       typeof newPosition.lng === "undefined"
                                  //     )
                                  //       return;

                                  //     const newLines = [...lines];
                                  //     newLines[lineIndex].points[pointIndex] =
                                  //       newPosition;
                                  //     updateLines(() => newLines);
                                  //   },
                                  // }}
                                  icon={
                                    pointIndex == 0 ||
                                    pointIndex == line.points.length - 1
                                      ? getDynamicDeviceIcon(
                                          customDataOverride || device,
                                          "icon"
                                        )
                                      : new L.DivIcon({
                                          className: "custom-x-icon",
                                          html: `<div>
                        <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                        <div style="color: ${
                          getRescAlertStatus(customDataOverride || device) ===
                          "Open"
                            ? "lime"
                            : "red"
                        }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                        </div>`,
                                          iconSize: [20, 20],
                                          iconAnchor: [10, 16],
                                        })
                                  }
                                  interactive
                                >
                                  {/* <Popup
                                      closeOnClick
                                      closeButton={false}
                                      autoClose
                                    >
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          removePointFromLine(
                                            lineIndex,
                                            pointIndex
                                          )
                                        }
                                      >
                                        Delete
                                      </div>
                                    </Popup> */}
                                </Marker>
                              );
                            })}
                          </>
                        ))}
                      </>
                    );
                })}
              {nodeList
                .filter((device) =>
                  connectionsContext.canViewDeviceType(device)
                )
                .filter((f) => {
                  let type = "";
                  if (deviceTypeToFilter === "flood") {
                    type = "Flood";
                  }
                  if (deviceTypeToFilter === "manualRoadClosure") {
                    type = "Manual Road Closure";
                  }
                  if (deviceTypeToFilter === "technologyBridge") {
                    type = "Technology Bridge_Flashing Lights";
                  }
                  if (deviceTypeToFilter === "technologyBridgeSiren") {
                    type = "Technology Bridge_Siren/Giant Voice";
                  }
                  return f.device_type === type;
                })

                .filter(
                  (f) =>
                    f.system_type === "RescAlert Devices" &&
                    f.device_type === "Technology Bridge_Siren/Giant Voice"
                )
                .map((device, index) => {
                  const c = device.location;
                  // const c = JSON.parse(device.location);
                  const lines = device.coordinates;
                  if (c)
                    return (
                      <>
                        {lines.map((line, lineIndex) => (
                          <>
                            {line.points.length > 1 && (
                              <Polyline
                                key={lineIndex + "" + Date.now()}
                                positions={line.points}
                                color={
                                  getRescAlertStatus(
                                    customDataOverride || device
                                  ) === "Idle"
                                    ? "lime"
                                    : "red"
                                }
                              />
                            )}
                            {line.points.map((point, pointIndex) => {
                              return (
                                <Marker
                                  key={
                                    lineIndex +
                                    "" +
                                    pointIndex +
                                    "" +
                                    Date.now()
                                  }
                                  position={point}
                                  draggable={false}
                                  // popup
                                  // eventHandlers={{
                                  //   popupopen: () => setPointPopupOpen(true),
                                  //   popupclose: () => setPointPopupOpen(false),
                                  //   dragend: (e) => {
                                  //     const newPosition = e.target.getLatLng();

                                  //     if (
                                  //       !newPosition ||
                                  //       typeof newPosition.lat ===
                                  //         "undefined" ||
                                  //       typeof newPosition.lng === "undefined"
                                  //     )
                                  //       return;

                                  //     const newLines = [...lines];
                                  //     newLines[lineIndex].points[pointIndex] =
                                  //       newPosition;
                                  //     updateLines(() => newLines);
                                  //   },
                                  // }}
                                  icon={
                                    pointIndex == 0 ||
                                    pointIndex == line.points.length - 1
                                      ? getDynamicDeviceIcon(
                                          customDataOverride || device,
                                          "icon"
                                        )
                                      : new L.DivIcon({
                                          className: "custom-x-icon",
                                          html: `<div>
                        <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                        <div style="color: ${
                          getRescAlertStatus(customDataOverride || device) ===
                          "Idle"
                            ? "lime"
                            : "red"
                        }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                        </div>`,
                                          iconSize: [20, 20],
                                          iconAnchor: [10, 16],
                                        })
                                  }
                                  interactive
                                >
                                  {/* <Popup
                                      closeOnClick
                                      closeButton={false}
                                      autoClose
                                    >
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          removePointFromLine(
                                            lineIndex,
                                            pointIndex
                                          )
                                        }
                                      >
                                        Delete
                                      </div>
                                    </Popup> */}
                                </Marker>
                              );
                            })}
                          </>
                        ))}
                      </>
                    );
                })}
            </WidgetMap>
          </div>
        )}
      </div>
    );
  }
}
