import { ColorContext } from "App";
import React, { useContext } from "react";

const TextToggle = ({
  options = ["1", "2"],
  value,
  setValue,
  style,
  optionColorOn = "white",
  optionColorOff = "#333",
  optionTextColorOn = "black",
  optionTextColorOff = "white",
  optionBorder1 = "",
  optionBorder2 = "",
  disabled = false
}) => {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        width: "150px",
        height: "40px",
        display: "flex",
        justifyContent: "space-between",
        color: theme.fontColor,
        cursor: "pointer",
        background: theme.primaryShadow,
        alignItems: "center",
        borderRadius: "10px",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1,
        filter: disabled ? "grayscale(100%)" : "none",
        cursor: disabled ? "not-allowed" : "pointer",
        ...style,
      }}
    >
      <div
        style={{
          color: value ? optionTextColorOff : optionTextColorOn,
          backgroundColor: value ? theme.primaryShadow : optionColorOn,
          border: !value && optionBorder1,
          borderRadius: "10px",
          width: "50%",
          textAlign: "center",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}

        onClick={() => setValue(false)}
      >
        {options[0]}
      </div>
      <div
        style={{
          color: !value ? optionTextColorOff : optionTextColorOn,
          backgroundColor: !value ? theme.primaryShadow : optionColorOn,
          border: value && optionBorder2,
          borderRadius: "10px",
          width: "50%",
          textAlign: "center",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        onClick={() => setValue(true)}
      >
        {options[1]}
      </div>
    </div>
  );
};

export default TextToggle;

/*
({
  activeText,
  inactiveText,
  callback,
  value = false,
  size = 20,
  colorDisabled = theme.base,
  colorEnabled = theme.primaryHighlight,
}) => {
  // const [enabled, setEnabled] = useState(value)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: size,
        width: size * 2,
        borderRadius: size,
        cursor: "pointer",
        alignItems: "center",
        background: value ? colorEnabled : colorDisabled,
      }}
      onClick={() => {
        callback(!value);
        // setEnabled(!enabled)
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: size,
          alignItems: "center",
          background: value ? colorEnabled : colorDisabled,
        }}
      >
        <div
          style={{
            height: size * 0.7,
            width: size * 0.7,
            borderRadius: size,
            background: "white",
            marginLeft: value ? size * 1.1 : size * 0.15,
          }}
        >{activeText}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: size,
          alignItems: "center",
          background: !value ? colorEnabled : colorDisabled,
        }}
      >
        <div
          style={{
            height: size * 0.7,
            width: size * 0.7,
            borderRadius: size,
            background: "white",
            marginLeft: !value ? size * 1.1 : size * 0.15,
          }}
        >{inactiveText}</div>
      </div>
    </div>
  );
}
*/
