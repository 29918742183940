import SevereWeatherAlertsMap from "widgets/SevereWeatherAlerts.js";
import WeatherForecastWidget from "../components/Widgets/WeatherForecastWidget";
import MapForecast from "widgets/MapForecast.js";

import Maps from "../pages/Weather/Maps";
import WeatherForecast from "../pages/Weather/WeatherForecast";

import { FaHeadphones } from "react-icons/fa";
import DetailedForecast from "components/Widgets/DetailedForecast";
import DayForecast15Widget from "components/Widgets/DayForecast15Widget";
import AirQualityObservationsWidget from "components/Widgets/AirQualityObservationsWidget";
import AirQualityForecastWidget from "components/Widgets/AirQualityForecastWidget";

import EarthquakeMap from "components/Maps/Earthquake/EarthquakeMap";
import NWSAlertsWidget from "components/Widgets/NWSAlertsWidget";
import MyAdvisoriesWidget from "components/Widgets/MyAdvisoriesWidget";
import HurricanesTropicalStorms from "components/StormCenter/HurricanesTropicalStorms";
import WaveAndWindConditionsWidget from "components/Widgets/WaveAndWindConditionsWidget";
import MarineForecast from "pages/Weather/MarineForecast";
import SurfAlertsWidget from "components/Widgets/SurfAlertsWidget";
import BuoyConditionsWidget from "components/Widgets/BuoyConditionsWidget";
import TideTableWidget from "components/Widgets/TideTableWidget";
import Doppler from "components/Maps/Doppler/Doppler";
import PIAlerts from "pages/Public Info/PIAlerts";
import Alerts from "pages/Weather/Alerts";

import { FireDanger } from "components/SpecialReports/FireDanger";
import { AirportDelay } from "components/SpecialReports/AirportDelay";
import { SkiReports } from "components/SpecialReports/SkiReports";

import CombinedChartsWidget, {
  GetChartTab,
} from "components/Widgets/CombinedChartsWidget";
import Charts from "pages/Weather/Charts";
import Dashboard from "pages/Dashboard";
import Devices from "pages/System/Devices";

//imported images
import overwatchIcon from "../assets/nIcons/Overwatch.png";
import pendingAlarmsIcon from "../assets/nIcons/Pending Alarms.png";
import aiAnalyticsIcon from "../assets/nIcons/AI Analytics.png";
import globalActionsIcon from "../assets/nIcons/Global Actions.png";
import connectedDevicesIcon from "../assets/nIcons/Connected Devices.png";
import responderIcon from "../assets/nIcons/Responder.png";
import fullMapIcon from "../assets/nIcons/Maps.png";
import incidentsIcon from "../assets/nIcons/Weather Alerts.png";
import employeesIcon from "../assets/nIcons/Employees.png";
import apparatusIcon from "../assets/nIcons/Apparatus.png";
import dispatchChatIcon from "../assets/nIcons/Dispatcher Chat.png";
import weatherIcon from "../assets/nIcons/Weather.png";
import weatherAlertsIcon from "../assets/nIcons/Weather Alerts.png";
import forecastIcon from "../assets/nIcons/Forecast.png";
import mapsIcon from "../assets/nIcons/Maps.png";
import stormCenterIcon from "../assets/nIcons/Storm Center.png";
import marineForecastIcon from "../assets/nIcons/Marine Forecast.png";
import specialReportsIcon from "../assets/nIcons/Special Reports.png";
import chartsIcon from "../assets/nIcons/Charts.png";
import publicInfoIcon from "../assets/nIcons/Public Info.png";
import publicAlertsIcon from "../assets/nIcons/Weather Alerts.png";
import systemIcon from "../assets/nIcons/System.png";
import connectionsIcon from "../assets/nIcons/Integrations.png";
import globalActions2Icon from "../assets/nIcons/Global Actions.png";
import manageSocialsIcon from "../assets/nIcons/Manage Socials.png";
import manageRolesIcon from "../assets/nIcons/Roles.png";
// import licenseAdminIcon from "../assets/nIcons/Vector-21.png";
import settingsIcon from "../assets/nIcons/Settings.png";
import helpIcon from "../assets/nIcons/Help.png";
import createAlertIcon from "../assets/nIcons/Create Alert.png";
import usersIcon from "../assets/nIcons/Users.png";
import alarmHistoryIcon from "../assets/nIcons/Alarm History.png";
// import integrationIcon from "../assets/icon/"
import organizationIcon from "../assets/nIcons/Organization.png";
import licenseIcon from "../assets/nIcons/License Admin.png";
import auditLogIcon from "../assets/nIcons/Audit Logs.png";
import { FaExclamation, FaPlus } from "react-icons/fa";
import AuditLogs from "pages/Admin/AuditLogs";
import { Page404 } from "pages/404";
import AlertCreator from "components/AlertCreator/AlertCreator";

// // // WIDGET PREVIEW IMAGES  // // //
// // Weather
// Maps
import CurrentConditions1x1 from "assets/WidgetPNGS/Weather/Maps/CurrentConditions1x1.png";
import CurrentConditions1x2 from "assets/WidgetPNGS/Weather/Maps/CurrentConditions1x2.png";
import CurrentConditions2x1 from "assets/WidgetPNGS/Weather/Maps/CurrentConditions2x1.png";
import Doppler1x1 from "assets/WidgetPNGS/Weather/Maps/Doppler1x1.png";
import Doppler1x2 from "assets/WidgetPNGS/Weather/Maps/Doppler1x2.png";
import Doppler2x1 from "assets/WidgetPNGS/Weather/Maps/Doppler2x1.png";
import Earthquake1x1 from "assets/WidgetPNGS/Weather/Maps/Earthquake1x1.png";
import Earthquake1x2 from "assets/WidgetPNGS/Weather/Maps/Earthquake1x2.png";
import Earthquake2x1 from "assets/WidgetPNGS/Weather/Maps/Earthquake2x1.png";
import SevereWeather1x1 from "assets/WidgetPNGS/Weather/Maps/SevereWeather1x1.png";
import SevereWeather1x2 from "assets/WidgetPNGS/Weather/Maps/SevereWeather1x2.png";
import SevereWeather2x1 from "assets/WidgetPNGS/Weather/Maps/SevereWeather2x1.png";

//Forecast
import WeatherForecast1x1 from "assets/WidgetPNGS/Weather/Forecast/WeatherForecast1x1.png";
import WeatherForecast1x2 from "assets/WidgetPNGS/Weather/Forecast/WeatherForecast1x2.png";
import WeatherForecast2x1 from "assets/WidgetPNGS/Weather/Forecast/WeatherForecast2x1.png";
import DetailedForecast1x1 from "assets/WidgetPNGS/Weather/Forecast/DetailedForecast1x1.png";
import DetailedForecast1x2 from "assets/WidgetPNGS/Weather/Forecast/DetailedForecast1x2.png";
import DetailedForecast2x1 from "assets/WidgetPNGS/Weather/Forecast/DetailedForecast2x1.png";
import Day15Forecast1x1 from "assets/WidgetPNGS/Weather/Forecast/15DayForecast1x1.png";
import Day15Forecast1x2 from "assets/WidgetPNGS/Weather/Forecast/15DayForecast1x2.png";
import Day15Forecast2x1 from "assets/WidgetPNGS/Weather/Forecast/15DayForecast2x1.png";
import AirQualityObservations1x1 from "assets/WidgetPNGS/Weather/Forecast/AirQualityObservations1x1.png";
import AirQualityObservations1x2 from "assets/WidgetPNGS/Weather/Forecast/AirQualityObservations1x2.png";
import AirQualityObservations2x1 from "assets/WidgetPNGS/Weather/Forecast/AirQualityObservations2x1.png";
import AirQualityForecast1x1 from "assets/WidgetPNGS/Weather/Forecast/AirQualityForecast1x1.png";
import AirQualityForecast1x2 from "assets/WidgetPNGS/Weather/Forecast/AirQualityForecast1x2.png";
import AirQualityForecast2x1 from "assets/WidgetPNGS/Weather/Forecast/AirQualityForecast2x1.png";

//StormCenter
import NWSAlerts1x1 from "assets/WidgetPNGS/Weather/StormCenter/NWSAlerts1x1.png";
import NWSAlerts1x2 from "assets/WidgetPNGS/Weather/StormCenter/NWSAlerts1x2.png";
import NWSAlerts2x1 from "assets/WidgetPNGS/Weather/StormCenter/NWSAlerts2x1.png";
import MyAdvisories1x1 from "assets/WidgetPNGS/Weather/StormCenter/MyForecastAdvisories1x1.png";
import MyAdvisories1x2 from "assets/WidgetPNGS/Weather/StormCenter/MyForecastAdvisories1x2.png";
import MyAdvisories2x1 from "assets/WidgetPNGS/Weather/StormCenter/MyForecastAdvisories2x1.png";
import HurricanesTropicalStorms1x1 from "assets/WidgetPNGS/Weather/StormCenter/Hurricanes1x1.png";
import HurricanesTropicalStorms1x2 from "assets/WidgetPNGS/Weather/StormCenter/Hurricanes1x2.png";
import HurricanesTropicalStorms2x1 from "assets/WidgetPNGS/Weather/StormCenter/Hurricanes2x1.png";

//MarineForecast
import WaveAndWindConditions1x1 from "assets/WidgetPNGS/Weather/MarineForecast/WaveAndWind1x1.png";
import WaveAndWindConditions1x2 from "assets/WidgetPNGS/Weather/MarineForecast/WaveAndWind1x2.png";
import WaveAndWindConditions2x1 from "assets/WidgetPNGS/Weather/MarineForecast/WaveAndWind2x1.png";
import SurfAlerts1x1 from "assets/WidgetPNGS/Weather/MarineForecast/SurfAlerts1x1.png";
import SurfAlerts1x2 from "assets/WidgetPNGS/Weather/MarineForecast/SurfAlerts1x2.png";
import SurfAlerts2x1 from "assets/WidgetPNGS/Weather/MarineForecast/SurfAlerts2x1.png";
import BuoyConditions1x1 from "assets/WidgetPNGS/Weather/MarineForecast/BuoyConditions1x1.png";
import BuoyConditions1x2 from "assets/WidgetPNGS/Weather/MarineForecast/BuoyConditions1x2.png";
import BuoyConditions2x1 from "assets/WidgetPNGS/Weather/MarineForecast/BuoyConditions2x1.png";
import TideTable1x1 from "assets/WidgetPNGS/Weather/MarineForecast/TideTable1x1.png";
import TideTable1x2 from "assets/WidgetPNGS/Weather/MarineForecast/TideTable1x2.png";
import TideTable2x1 from "assets/WidgetPNGS/Weather/MarineForecast/TideTable2x1.png";

//Charts
import DetailedForecastChart1x1 from "assets/WidgetPNGS/Weather/Charts/DetailedForecastChart1x1.png";
import DetailedForecastChart2x1 from "assets/WidgetPNGS/Weather/Charts/DetailedForecastChart2x1.png";
import Day7ForecastChart1x1 from "assets/WidgetPNGS/Weather/Charts/7DayForecastChart1x1.png";
import Day7ForecastChart2x1 from "assets/WidgetPNGS/Weather/Charts/7DayForecastChart2x1.png";
import Day15ForecastChart1x1 from "assets/WidgetPNGS/Weather/Charts/15DayForecastChart1x1.png";
import Day15ForecastChart2x1 from "assets/WidgetPNGS/Weather/Charts/15DayForecastChart2x1.png";
import ChanceOfPrecipitationChart1x1 from "assets/WidgetPNGS/Weather/Charts/ChanceOfPrecipitationChart1x1.png";
import ChanceOfPrecipitationChart2x1 from "assets/WidgetPNGS/Weather/Charts/ChanceOfPrecipitationChart2x1.png";
import ComfortLevelChart1x1 from "assets/WidgetPNGS/Weather/Charts/ComfortLevelChart1x1.png";
import ComfortLevelChart2x1 from "assets/WidgetPNGS/Weather/Charts/ComfortLevelChart2x1.png";
import DewPointChart1x1 from "assets/WidgetPNGS/Weather/Charts/DewPointChart1x1.png";
import DewPointChart2x1 from "assets/WidgetPNGS/Weather/Charts/DewPointChart2x1.png";
import HumidityChart1x1 from "assets/WidgetPNGS/Weather/Charts/HumidityChart1x1.png";
import HumidityChart2x1 from "assets/WidgetPNGS/Weather/Charts/HumidityChart2x1.png";
import PrecipitationChart1x1 from "assets/WidgetPNGS/Weather/Charts/PrecipitationChart1x1.png";
import PrecipitationChart2x1 from "assets/WidgetPNGS/Weather/Charts/PrecipitationChart2x1.png";
import PressureChart1x1 from "assets/WidgetPNGS/Weather/Charts/PressureChart1x1.png";
import PressureChart2x1 from "assets/WidgetPNGS/Weather/Charts/PressureChart2x1.png";
import TemperatureChart1x1 from "assets/WidgetPNGS/Weather/Charts/TemperatureChart1x1.png";
import TemperatureChart2x1 from "assets/WidgetPNGS/Weather/Charts/TemperatureChart2x1.png";
import WindSpeedChart1x1 from "assets/WidgetPNGS/Weather/Charts/WindSpeedChart1x1.png";
import WindSpeedChart2x1 from "assets/WidgetPNGS/Weather/Charts/WindSpeedChart2x1.png";

//Special Reports
import FireDanger1x1 from "assets/WidgetPNGS/Weather/SpecialReports/FireDanger1x1.png";
import FireDanger1x2 from "assets/WidgetPNGS/Weather/SpecialReports/FireDanger1x2.png";
import FireDanger2x1 from "assets/WidgetPNGS/Weather/SpecialReports/FireDanger2x1.png";
import AirportDelay1x1 from "assets/WidgetPNGS/Weather/SpecialReports/AirportDelays1x1.png";
import AirportDelay1x2 from "assets/WidgetPNGS/Weather/SpecialReports/AirportDelays1x2.png";
import AirportDelay2x1 from "assets/WidgetPNGS/Weather/SpecialReports/AirportDelays2x1.png";
import SkiReports1x1 from "assets/WidgetPNGS/Weather/SpecialReports/SkiReports1x1.png";
import SkiReports1x2 from "assets/WidgetPNGS/Weather/SpecialReports/SkiReports1x2.png";
import SkiReports2x1 from "assets/WidgetPNGS/Weather/SpecialReports/SkiReports2x1.png";

//WeatherAlerts
import WeatherAlerts1x1 from "assets/WidgetPNGS/Weather/WeatherAlerts/WeatherAlerts1x1.png";
import WeatherAlerts1x2 from "assets/WidgetPNGS/Weather/WeatherAlerts/WeatherAlerts1x2.png";
import WeatherAlerts2x1 from "assets/WidgetPNGS/Weather/WeatherAlerts/WeatherAlerts2x1.png";

// // Overwatch
import ConnectedDevicesGraph2x1 from "assets/WidgetPNGS/Overwatch/ConnectedDevicesGraph2x1.png";
import ConnectedDevicesMap1x1 from "assets/WidgetPNGS/Overwatch/ConnectedDevicesMap1x1.png";
import ConnectedDevicesMap1x2 from "assets/WidgetPNGS/Overwatch/ConnectedDevicesMap1x2.png";
import ConnectedDevicesMap2x1 from "assets/WidgetPNGS/Overwatch/ConnectedDevicesMap2x1.png";
import TechnologyBridgeDevices1x2 from "assets/WidgetPNGS/Overwatch/TechnologyBridgeDevices1x2.png";
import TechnologyBridgeDevices2x1 from "assets/WidgetPNGS/Overwatch/TechnologyBridgeDevices2x1.png";
import ManualRoadClosureDevices1x2 from "assets/WidgetPNGS/Overwatch/ManualRoadClosureDevices1x2.png";
import ManualRoadClosureDevices2x1 from "assets/WidgetPNGS/Overwatch/ManualRoadClosureDevices2x1.png";

// // Public Info
import ActiveAlerts1x1 from "assets/WidgetPNGS/PublicInfo/ActiveAlerts1x1.png";
import ActiveAlerts1x2 from "assets/WidgetPNGS/PublicInfo/ActiveAlerts1x2.png";
import ActiveAlerts2x1 from "assets/WidgetPNGS/PublicInfo/ActiveAlerts2x1.png";
import CreateAlert1x2 from "assets/WidgetPNGS/PublicInfo/CreateAlert1x2.png";
import CreateAlert2x1 from "assets/WidgetPNGS/PublicInfo/CreateAlert2x1.png";
import Connections from "pages/System/Connections";
import RescAlertDevicesWidget from "pages/System/RescAlertDevicesWidget";

// // // WIDGET PREVIEW IMAGES END  // // //

export default function GetWidgetData(
  widgetRefresh,
  setWidgetRefresh,
  sidebarOpen
) {
  return {
    1: {
      id: 1, //ID number of the widget, this should match the key. This is mostly for the back-end
      name: "SevereWeatherAlertsMap", //The name of the widget (not shown to user)
      displayName: "Severe Weather Warnings", //Display name for widget (shown to user)
      isResizable: false, //This is always false, unless there's a reason we want a resizable widget
      //These are the sizes that the widget supports (1x1, 2x1, or 1x2)
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      // These are the preview images for the widget. This is used to show the user what the widget will look like on the dashboard
      previewImages: {
        "1x1": SevereWeather1x1,
        "1x2": SevereWeather1x2,
        "2x1": SevereWeather2x1,
      },
      //This function get's called to render the widget on the dashboard
      component: (w, h, preview) => (
        <SevereWeatherAlertsMap
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          preview={preview}
        />
      ),
      //Not sure if this is actually in use. But to avoid breaking things, include the fullscreen component (if applicable)
      fullscreen: () => <Maps />,
      //This is used to navigate to the widget's corrosponding fullscreen location (copy and change values)
      sidebarData: {
        href: "/weather/maps?tab=0",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      //These are the categories. The first one is usually the sidebar dropdown that the fullscreen page is under.
      //  The second is usually the actual option (or page) that the user must navigate to in order to get to the fullscreen.
      category: ["Weather", "Maps"],

      //This is the icon that is displayed on the 'add widget' component alongside the 'displayName'.
      icon: () => {
        return <img src={fullMapIcon} style={{ width: 30 }} />;
      },

      //This defines which permissions need to be true for the user to have access to this widget
      permissions: ["WeatherEnabled", "MapsEnabled"],
    },
    2: {
      id: 2,
      name: "WeatherForecast",
      displayName: "Weather Forecast",
      w: 1,
      h: 1,
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": WeatherForecast1x1,
        "1x2": WeatherForecast1x2,
        "2x1": WeatherForecast2x1,
      },
      component: (w, h) => (
        <WeatherForecastWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/forecast?tab=0",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Forecast"],
      icon: () => {
        return <img src={forecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ForecastEnabled"],
    },
    3: {
      id: 3,
      name: "MapForecast",
      displayName: "Current Conditions",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": CurrentConditions1x1,
        "1x2": CurrentConditions1x2,
        "2x1": CurrentConditions2x1,
      },
      component: (w, h, preview) => (
        <MapForecast
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          preview={preview}
        />
      ),
      fullscreen: () => <Maps />,
      sidebarData: {
        href: "/weather/maps?tab=1",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Maps"],
      icon: () => {
        return <img src={fullMapIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MapsEnabled"],
    },
    4: {
      id: 4,
      name: "DetailedForecast",
      displayName: "Detailed Forecast",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": DetailedForecast1x1,
        "1x2": DetailedForecast1x2,
        "2x1": DetailedForecast2x1,
      },
      component: (w, h) => (
        <DetailedForecast
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/forecast?tab=2",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Forecast"],
      icon: () => {
        return <img src={forecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ForecastEnabled"],
    },
    5: {
      id: 5,
      name: "Day15Forecast",
      displayName: "15 Day Forecast",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": Day15Forecast1x1,
        "1x2": Day15Forecast1x2,
        "2x1": Day15Forecast2x1,
      },
      component: (w, h) => (
        <DayForecast15Widget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          // 1x1 (square) - IN PROGRESS, bugged selection
          // 1x2 (vertical) - Not started
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/forecast?tab=3",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Forecast"],
      icon: () => {
        return <img src={forecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ForecastEnabled"],
    },
    6: {
      id: 6,
      name: "AirQualityObservations",
      displayName: "Air Quality Observations",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": AirQualityObservations1x1,
        "1x2": AirQualityObservations1x2,
        "2x1": AirQualityObservations2x1,
      },
      component: (w, h) => (
        <AirQualityObservationsWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          // 1x1 (square) - IN PROGRESS, waiting on assets
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/forecast?tab=4&subtab=0",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Forecast"],
      icon: () => {
        return <img src={forecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ForecastEnabled"],
    },
    7: {
      id: 7,
      name: "AirQualityForecast",
      displayName: "Air Quality Forecast",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": AirQualityForecast1x1,
        "1x2": AirQualityForecast1x2,
        "2x1": AirQualityForecast2x1,
      },
      component: (w, h) => (
        <AirQualityForecastWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          // 1x1 (square) - IN PROGRESS, waiting on assets
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/forecast?tab=4&subtab=1",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Forecast"],
      icon: () => {
        return <img src={forecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ForecastEnabled"],
    },
    8: {
      id: 8,
      name: "NWSAlerts",
      displayName: "NWS Alerts",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": NWSAlerts1x1,
        "1x2": NWSAlerts1x2,
        "2x1": NWSAlerts2x1,
      },
      component: (w, h) => (
        <NWSAlertsWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/stormcenter?tab=0",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Storm Center"],
      icon: () => {
        return <img src={stormCenterIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "StormCenterEnabled"],
    },
    9: {
      id: 9,
      name: "MyAdvisories",
      displayName: "My Forecast Advisories",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": MyAdvisories1x1,
        "1x2": MyAdvisories1x2,
        "2x1": MyAdvisories2x1,
      },
      component: (w, h) => (
        <MyAdvisoriesWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/stormcenter?tab=1",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Storm Center"],
      icon: () => {
        return <img src={stormCenterIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "StormCenterEnabled"],
    },
    10: {
      id: 10,
      name: "WaveAndWindConditions",
      displayName: "Wave and Wind Conditions",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": WaveAndWindConditions1x1,
        "1x2": WaveAndWindConditions1x2,
        "2x1": WaveAndWindConditions2x1,
      },
      component: (w, h) => (
        <WaveAndWindConditionsWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <MarineForecast />,
      sidebarData: {
        href: "/weather/marineforecast",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Marine Forecast"],
      icon: () => {
        return <img src={marineForecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MarineForecastEnabled"],
    },
    11: {
      id: 11,
      name: "SurfAlerts",
      displayName: "Surf Alerts",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": SurfAlerts1x1,
        "1x2": SurfAlerts1x2,
        "2x1": SurfAlerts2x1,
      },
      component: (w, h) => (
        <SurfAlertsWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <MarineForecast />,
      sidebarData: {
        href: "/weather/marineforecast?tab=1",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Marine Forecast"],
      icon: () => {
        return <img src={marineForecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MarineForecastEnabled"],
    },
    12: {
      id: 12,
      name: "BuoyConditions",
      displayName: "Buoy Conditions",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": BuoyConditions1x1,
        "1x2": BuoyConditions1x2,
        "2x1": BuoyConditions2x1,
      },
      component: (w, h) => (
        <BuoyConditionsWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <MarineForecast />,
      sidebarData: {
        href: "/weather/marineforecast?tab=2",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Marine Forecast"],
      icon: () => {
        return <img src={marineForecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MarineForecastEnabled"],
    },
    13: {
      id: 13,
      name: "TideTable",
      displayName: "Tide Table",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": TideTable1x1,
        "1x2": TideTable1x2,
        "2x1": TideTable2x1,
      },
      component: (w, h) => (
        <TideTableWidget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <MarineForecast />,
      sidebarData: {
        href: "/weather/marineforecast?tab=3",
        accessLevel: [8, 9, 10, 11, 12, 13],

        // icon: <FaHeadphones />,
      },
      category: ["Weather", "Marine Forecast"],
      icon: () => {
        return <img src={marineForecastIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MarineForecastEnabled"],
    },
    14: {
      id: 14,
      name: "Earthquake",
      displayName: "Earthquake",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": Earthquake1x1,
        "1x2": Earthquake1x2,
        "2x1": Earthquake2x1,
      },
      component: (w, h, preview) => (
        <EarthquakeMap
          widget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          preview={preview}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/maps?tab=3",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Maps"],
      icon: () => {
        return <img src={fullMapIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MapsEnabled"],
    },
    15: {
      id: 15,
      name: "HurricanesTropicalStorms",
      displayName: "Hurricanes & Tropical Storms",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": HurricanesTropicalStorms1x1,
        "1x2": HurricanesTropicalStorms1x2,
        "2x1": HurricanesTropicalStorms2x1,
      },
      component: (w, h, preview) => (
        <HurricanesTropicalStorms
          widget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          preview={preview}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/stormcenter?tab=3",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Storm Center"],
      icon: () => {
        return <img src={stormCenterIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MapsEnabled"],
    },

    //CHARTS
    16: {
      id: 16,
      name: "Charts-DetailedForecast",
      displayName: "Detailed Forecast Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": DetailedForecastChart1x1,
        "2x1": DetailedForecastChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={0}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=0",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    17: {
      id: 17,
      name: "Charts-7DayForecast",
      displayName: "7 Day Forecast Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": Day7ForecastChart1x1,
        "2x1": Day7ForecastChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={1}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=1",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    18: {
      id: 18,
      name: "Charts-15DayForecast",
      displayName: "15 Day Forecast Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": Day15ForecastChart1x1,
        "2x1": Day15ForecastChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={2}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=2",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    19: {
      id: 19,
      name: "Charts-ChanceOfPrecip",
      displayName: "Chance of Precipitation Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": ChanceOfPrecipitationChart1x1,
        "2x1": ChanceOfPrecipitationChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={3}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=3",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    20: {
      id: 20,
      name: "Charts-ComfortLevel",
      displayName: "Comfort Level Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": ComfortLevelChart1x1,
        "2x1": ComfortLevelChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={4}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=4",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    21: {
      id: 21,
      name: "Charts-DewPoint",
      displayName: "Dew Point Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": DewPointChart1x1,
        "2x1": DewPointChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={5}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=5",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    22: {
      id: 22,
      name: "Charts-Humidity",
      displayName: "Humidity Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": HumidityChart1x1,
        "2x1": HumidityChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={6}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=6",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    23: {
      id: 23,
      name: "Charts-Pressure",
      displayName: "Pressure Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": PressureChart1x1,
        "2x1": PressureChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={7}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=7",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    24: {
      id: 24,
      name: "Charts-Precipitation",
      displayName: "Precipitation Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": PrecipitationChart1x1,
        "2x1": PrecipitationChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={8}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=8",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    25: {
      id: 25,
      name: "Charts-Temperature",
      displayName: "Temperature Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": TemperatureChart1x1,
        "2x1": TemperatureChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={9}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=9",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },
    26: {
      id: 26,
      name: "Charts-WindSpeed",
      displayName: "Wind Speed Chart",
      isResizable: false,
      sizes: {
        "1x1": false,
        "1x2": false,
        "2x1": true,
      },
      previewImages: {
        "1x1": WindSpeedChart1x1,
        "2x1": WindSpeedChart2x1,
      },
      component: (w, h) => (
        <CombinedChartsWidget
          defaultTab={10}
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <Charts />,
      sidebarData: {
        href: "/weather/charts?tab=10",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Charts"],
      icon: () => {
        return <img src={chartsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "ChartsEnabled"],
    },

    //Special reports
    27: {
      id: 27,
      name: "FireDanger",
      displayName: "Fire Danger",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": FireDanger1x1,
        "1x2": FireDanger1x2,
        "2x1": FireDanger2x1,
      },
      component: (w, h) => (
        <FireDanger
          widget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/specialreports?tab=0",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Special Reports"],
      icon: () => {
        return <img src={specialReportsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "SpecialReportsEnabled"],
    },
    28: {
      id: 28,
      name: "AirportDelay",
      displayName: "Airport Delays",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": AirportDelay1x1,
        "1x2": AirportDelay1x2,
        "2x1": AirportDelay2x1,
      },
      component: (w, h) => (
        <AirportDelay
          widget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/specialreports?tab=3",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Special Reports"],
      icon: () => {
        return <img src={specialReportsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "SpecialReportsEnabled"],
    },
    29: {
      id: 29,
      name: "SkiReport",
      displayName: "Ski Reports",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": SkiReports1x1,
        "1x2": SkiReports1x2,
        "2x1": SkiReports2x1,
      },
      component: (w, h) => (
        <SkiReports
          widget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
        />
      ),
      fullscreen: () => <WeatherForecast />,
      sidebarData: {
        href: "/weather/specialreports?tab=2",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Special Reports"],
      icon: () => {
        return <img src={specialReportsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "SpecialReportsEnabled"],
    },
    30: {
      id: 30,
      name: "AWAREFloodGraph",
      displayName: "AWARE Flood Graph",
      isResizable: false,
      sizes: {
        "1x1": false,
        "2x1": true,
      },
      previewImages: {
        "2x1": ConnectedDevicesGraph2x1,
      },
      component: (w, h, preview) => (
        <Connections
          widget
          widgetParams={{
            sidebarOpen: sidebarOpen,
            widgetType: "graph",
            dimensions: [w, h],
            preview: preview,
          }}
        />
      ),
      fullscreen: () => <Connections />,
      sidebarData: {
        href: "/system/devices",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Overwatch", "Connected Devices"],
      icon: () => {
        return <img src={connectedDevicesIcon} style={{ width: 30 }} />;
      },
      permissions: ["SystemEnabled", "ConnectionsEnabled", "ViewAWAREFloodConnections"],
    },
    31: {
      id: 31,
      name: "AWAREFloodMap",
      displayName: "AWARE Flood Map",
      isResizable: false,
      sizes: {
        "1x1": true,
        "2x1": true,
        "1x2": true,
      },
      previewImages: {
        "1x1": ConnectedDevicesMap1x1,
        "2x1": ConnectedDevicesMap2x1,
        "1x2": ConnectedDevicesMap1x2,
      },
      component: (w, h, preview) => (
        <Connections
          widget
          widgetParams={{
            sidebarOpen: sidebarOpen,
            widgetType: "map",
            dimensions: [w, h],
            preview: preview,
          }}
        />
      ),
      fullscreen: () => <Connections />,
      sidebarData: {
        href: "/system/devices",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Overwatch", "Connected Devices"],
      icon: () => {
        return <img src={connectedDevicesIcon} style={{ width: 30 }} />;
      },
      permissions: ["SystemEnabled", "ConnectionsEnabled", "ViewAWAREFloodConnections"],
    },
    32: {
      id: 32,
      name: "DopplerMap",
      displayName: "Doppler Map",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": Doppler1x1,
        "1x2": Doppler1x2,
        "2x1": Doppler2x1,
      },
      component: (w, h, preview) => (
        <Doppler
          widget
          widgetRefresh={widgetRefresh}
          setWidgetRefresh={setWidgetRefresh}
          sidebarOpen={sidebarOpen}
          dimensions={[w, h]}
          preview={preview}
        />
      ),
      fullscreen: () => <Maps />,
      sidebarData: {
        href: "/weather/maps?tab=2",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Maps"],
      icon: () => {
        return <img src={fullMapIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "MapsEnabled"],
    },
    33: {
      id: 33,
      name: "WeatherAlerts",
      displayName: "Weather Alerts",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": WeatherAlerts1x1,
        "1x2": WeatherAlerts1x2,
        "2x1": WeatherAlerts2x1,
      },
      component: (w, h, preview) => {
        return (
          <Alerts
            widgetRefresh={widgetRefresh}
            setWidgetRefresh={setWidgetRefresh}
            sidebarOpen={sidebarOpen}
            dimensions={[w, h]}
            preview={preview}
          />
        );
      },
      fullscreen: () => <Alerts />,
      sidebarData: {
        href: "/weather/alerts",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Weather", "Weather Alerts"],
      icon: () => {
        return <img src={weatherAlertsIcon} style={{ width: 30 }} />;
      },
      permissions: ["WeatherEnabled", "WeatherAlerts"],
    },
    34: {
      id: 34,
      name: "TechnologyBridgeDevices",
      displayName: "Technology Bridge (Flashing Lights) Map",
      isResizable: false,
      sizes: {
        "1x1": false,
        "2x1": true,
        "1x2": true,
      },
      previewImages: {
        "1x2": TechnologyBridgeDevices1x2,
        "2x1": TechnologyBridgeDevices2x1,
      },
      component: (w, h, preview) => (
        <Connections
          widget
          widgetParams={{
            sidebarOpen: sidebarOpen,
            widgetType: "technologyBridge",
            dimensions: [w, h],
            preview: preview,
          }}
        />
      ),
      fullscreen: () => <Connections />,
      sidebarData: {
        href: "/system/devices",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Overwatch", "Connected Devices"],
      icon: () => {
        return <img src={connectedDevicesIcon} style={{ width: 30 }} />;
      },
      permissions: ["SystemEnabled", "ConnectionsEnabled", "ViewRescAlertDevicesTechnologyBridge_FlashingLightsConnections"],
    },
    35: {
      id: 35,
      name: "ManualRoadClosureDevices",
      displayName: "RescAlert Devices Manual Road Closure Map",
      isResizable: false,
      sizes: {
        "1x1": false,
        "2x1": true,
        "1x2": true,
      },
      previewImages: {
        "1x2": ManualRoadClosureDevices1x2,
        "2x1": ManualRoadClosureDevices2x1,
      },
      component: (w, h, preview) => (
        
        <Connections
          widget
          widgetParams={{
            sidebarOpen: sidebarOpen,
            widgetType: "manualRoadClosure",
            dimensions: [w, h],
            preview: preview,
          }}
        />
      ),
      fullscreen: () => <Connections />,
      sidebarData: {
        href: "/system/devices",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Overwatch", "Connected Devices"],
      icon: () => {
        return <img src={connectedDevicesIcon} style={{ width: 30 }} />;
      },
      permissions: ["SystemEnabled", "ConnectionsEnabled", "ViewRescAlertDevicesManualRoadClosureConnections"],
    },
    36: {
      id: 36,
      name: "TechnologyBridgeSirenDevices",
      displayName: "Technology Bridge (Siren/Giant Voice) Map",
      isResizable: false,
      sizes: {
        "1x1": false,
        "2x1": true,
        "1x2": true,
      },
      previewImages: {
        "1x2": TechnologyBridgeDevices1x2,
        "2x1": TechnologyBridgeDevices2x1,
      },
      component: (w, h, preview) => (
        <Connections
          widget
          widgetParams={{
            sidebarOpen: sidebarOpen,
            widgetType: "technologyBridgeSiren",
            dimensions: [w, h],
            preview: preview,
          }}
        />
      ),
      fullscreen: () => <Connections />,
      sidebarData: {
        href: "/system/devices",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Overwatch", "Connected Devices"],
      icon: () => {
        return <img src={connectedDevicesIcon} style={{ width: 30 }} />;
      },
      permissions: ["SystemEnabled", "ConnectionsEnabled", "ViewRescAlertDevicesTechnologyBridge_Siren-GiantVoiceConnections"],
    },
    //fusion_plus widgets are 1xx
    101: {
      id: 101,
      name: "ActiveAlerts",
      displayName: "Active Alerts",
      isResizable: false,
      sizes: {
        "1x1": true,
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x1": ActiveAlerts1x1,
        "1x2": ActiveAlerts1x2,
        "2x1": ActiveAlerts2x1,
      },
      component: (w, h, preview) => {
        return (
          <PIAlerts
            widgetRefresh={widgetRefresh}
            setWidgetRefresh={setWidgetRefresh}
            sidebarOpen={sidebarOpen}
            dimensions={[w, h]}
            preview={preview}
          />
        );
      },
      fullscreen: () => <PIAlerts />,
      sidebarData: {
        href: "/publicuser/alerts",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Public Info", "Active Alerts"],
      icon: () => {
        return <img src={publicAlertsIcon} style={{ width: 30 }} />;
      },
      permissions: ["PublicInfoEnabled", "ViewAlerts"],
    },
    102: {
      id: 102,
      name: "CreateAlert",
      displayName: "Create Alert",
      isResizable: false,
      sizes: {
        "1x2": true,
        "2x1": true,
      },
      previewImages: {
        "1x2": CreateAlert1x2,
        "2x1": CreateAlert2x1,
      },
      component: (w, h, preview) => (
        <AlertCreator
          sidebarOpen={sidebarOpen}
          widget
          dimensions={[w, h]}
          preview={preview}
        />
      ),
      fullscreen: () => <AlertCreator />,
      sidebarData: {
        href: "/publicuser/createalert",
        accessLevel: [8, 9, 10, 11, 12, 13],
      },
      category: ["Public Info", "Create Alert"],
      icon: () => {
        return <img src={createAlertIcon} style={{ width: 30 }} />;
      },
      permissions: ["PublicInfoEnabled", "Create-EditAlerts"],
    },
    //responder widgets are 2xx
  };
}
