import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileLoadingMessage from "./MobileLoadingMessage";
import { GetRouteData } from "data/routeData";
import { usePerms } from "hooks/usePerms";

const MobileDashboard = ({ routes }) => {
  const navigate = useNavigate();
  const perms = usePerms();

  const [routeGroups, setRouteGroups] = useState([]);

  useEffect(() => {
    // setTimeout(() => {
    const routeGroups = [...GetRouteData(perms)];
    routeGroups.forEach((group) => {
      group.enabled = false;
      if (group.permission()) {
        // console.warn(group.name + "- Group allowed by permission");
        group.options.forEach((page) => {
          //set enabled by perm
          if (!page.mobilePage) {
            page.enabled = false;
          } else {
            if (!page.hideSidebar) {
              if (page.permission()) {
                page.enabled = true;
                group.enabled = true;
              } else {
              }
            } else {
              page.enabled = false;
            }
          }

          //set active based on location
          // if (window.location.pathname === "/mobile" + option.href) item.opened = true;
        });
      } else {
        // console.warn(group.name + "- Group not allowed by permission");
        group.enabled = false;
        group.options.forEach((page) => {
          page.enabled = false;
        });
      }
    });
    setRouteGroups(routeGroups.filter((group) => group.enabled));
    // }, 1000);
  }, [routes]);

  const RouteGroup = ({ group, index }) => {
    const [open, setOpen] = useState(true);

    if (group.enabled)
      return (
        <div key={index}>
          <div
            className="listItem title border-bottom bg-primaryShadow"
            onClick={() => {
              // setOpen(!open);
            }}
          >
            {group.icon()}
            {group.name}
          </div>
          {open &&
            group.options.map((option, index) => {
              if (option.enabled)
                return (
                  <div
                    className="listItem border-bottom bg-primaryShadow"
                    key={index}
                    style={{
                      paddingLeft: 40,
                    }}
                    onClick={() => {
                      if (option.mobilePage) navigate("/mobile" + option.href);
                    }}
                  >
                    {option.icon()}
                    {option.name}
                  </div>
                );
            })}
        </div>
      );
  };

  if (routes.length === 0) {
    return (
      <div>
        <MobileLoadingMessage message="Missing routes..." />
      </div>
    );
  }

  return (
    <div>
      {routeGroups.length > 0 ? (
        routeGroups.map((group, index) => {
          return <RouteGroup group={group} index={index} key={index} />;
        })
      ) : (
        <MobileLoadingMessage
          message={JSON.stringify(routeGroups) + routeGroups.length}
        />
      )}
      <div className="flex column">
        <button className="button bg-primaryShadow">Test Button</button>
        <button className="button bg-primary">Test Button 2</button>
        <button className="button bg-primaryHighlight">Test Button 3</button>
      </div>
    </div>
  );
};

export default MobileDashboard;
